import axios from "axios"
import Button from "components/Buttons/Button"
import CheckboxField from "components/InputField/CheckboxField"
import InputField from "components/InputField/InputField"
import SelectField from "components/InputField/SelectField"
import Loader from "components/Loader/Loader"
import PopUp from "components/PopUp/PopUp"
import { Box } from "components/Shared/Box.styled"
import Row from "components/Table/Rows/Row"
import {
  StyledColumn,
  StyledTableRow,
  TableContainer,
  TableHead,
  TableStyled,
} from "components/Table/Table.styled"
import { useUserContext } from "helpers/UserContextProvider"
import React, { useContext, useEffect, useRef, useState } from "react"
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce"

interface Gateway {
  id: string
  name: string
}

interface AddUserPopupProps {
  onSubmit: () => void
  setShow: (show: boolean) => void
  gateways: Gateway[]
  setEmail?: string
  setRole?: string
  setUserGateways?: string[]
  userId?: string
  type: "add" | "edit" | "approve"
  groupId: string
  setError: (error: string | null) => void // null to close
}

interface ErrorPopupProps {
  setError: (error: string | null) => void // null to close
  error?: string
}

const ErrorPopup: React.FC<ErrorPopupProps> = ({ setError, error }) => {
  return (
    <PopUp title="Error" noConfig setShow={() => setError(null)}>
      <Box direction="column" justify="center" padding="0 0 10px 0">
        <p>{error !== "" ? error : "An error has occured"}</p>
      </Box>
      <Box direction="column" justify="center" padding="0 0 20px 0">
        <Button text="Dismiss" onClick={() => setError(null)} />
      </Box>
    </PopUp>
  )
}

const AddUserPopup: React.FC<AddUserPopupProps> = ({
  onSubmit,
  setShow,
  gateways,
  userId,
  setEmail,
  setRole,
  setUserGateways,
  type,
  groupId,
  setError,
}) => {
  const email = useRef<HTMLInputElement>(null)
  const role = useRef<HTMLSelectElement>(null)
  const approved = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState(false)
  const [choosenRole, setChoosenRole] = useState<string>(setRole || "user")
  const { token } = useContext<IAuthContext>(AuthContext)
  const [selectedGateways, setSelectedGateways] = useState<string[]>(
    setUserGateways || []
  )

  const addUser = async () => {
    setLoading(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_ADDRESS}/userGroups/${groupId}/users`,
        {
          email: email.current?.value,
          role: role.current?.value,
          userGateways: selectedGateways,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setLoading(false)
        onSubmit()
        setShow(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        setShow(false)
        setError(err.response.data || "")
      })
  }

  const updateUser = async () => {
    setLoading(true)
    await axios
      .put(
        `${process.env.REACT_APP_API_ADDRESS}/userGroups/${groupId}/users/${userId}`,
        {
          role: role.current?.value,
          userGateways: selectedGateways,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setLoading(false)
        onSubmit()
        setShow(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        setShow(false)
        setError(err.response.data || "")
      })
  }

  const approveUser = async () => {
    setLoading(true)
    await axios
      .put(
        `${process.env.REACT_APP_API_ADDRESS}/userGroups/${groupId}/users/approve`,
        {
          email: email.current?.value,
          role: role.current?.value,
          userGateways: selectedGateways,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setLoading(false)
        onSubmit()
        setShow(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        setShow(false)
        setError(err.response.data || "")
      })
  }

  const getRoleDescription = (role: string) => {
    switch (role) {
      case "admin":
        return "Can administrate the users, has access to all infrastructures."
      case "user":
        return "Has access to all infrastructures."
      case "managed":
        return "Has access only to choosen infrastructures."
    }
  }

  const getTitle = (type: string) => {
    switch (type) {
      case "add":
        return "Add new user"
      case "edit":
        return "Edit user"
      case "approve":
        return "Approve existing user"
    }
  }

  const getGeneralInfo = (type: string) => {
    switch (type) {
      case "add":
        return "Add a new user not yet registered in the platform."
      case "edit":
        return "Edit an existing user."
      case "approve":
        return "Approve a user that has self-registered in the platform."
    }
  }

  const getCallback = (type: string) => {
    switch (type) {
      case "add":
        return addUser
      case "edit":
        return updateUser
      case "approve":
        return approveUser
      default:
        return () => {
          return null
        }
    }
  }

  if (loading)
    return (
      <PopUp title={getTitle(type)} noConfig setShow={() => setShow(false)}>
        <Loader />
      </PopUp>
    )
  return (
    <PopUp title={getTitle(type)} noConfig setShow={() => setShow(false)}>
      <Box direction="column" justify="center" padding="0 0 10px 0">
        <Box direction="column" justify="center" padding="0 0 20px 0">
          <p>{getGeneralInfo(type)}</p>
        </Box>
        <Box direction="column" justify="center" padding="0 0 20px 0">
          <InputField
            type="email"
            innerRef={email}
            value={type !== "add" ? setEmail : undefined}
            disabled={type === "edit"}
            label="Email"
            width={100}
          />
        </Box>
        <Box direction="column" justify="center" padding="0 0 10px 0">
          <SelectField
            innerRef={role}
            label="Role"
            selected={choosenRole}
            options={["admin", "user", "managed"]}
            width={100}
            onChange={(value) => {
              setChoosenRole(value)
            }}
          />
          <p>{getRoleDescription(choosenRole)}</p>
        </Box>
        {choosenRole === "managed" && (
          <Box direction="column" justify="center" padding="0 0 10px 0">
            <p>Gateways accessible for managed user:</p>
            <div
              style={{
                overflowY: "scroll",
                overflowX: "hidden",
                display: "flex",
                flexWrap: "wrap", // Allow content to wrap into columns
                justifyContent: "flex-start", // Start from the left
                maxHeight: "300px", // Set the height of the container
              }}
            >
              {gateways.length > 0 &&
                gateways.map((gateway) => (
                  <div
                    style={{
                      width: "50%", // Adjust the width for the number of columns you want
                      padding: "0 0 0 0",
                      margin: "0",
                      boxSizing: "border-box",
                      display: "flex",
                      alignItems: "center", // Center content vertically
                      borderBottom: "1px solid #ccc", // Add a border between items
                    }}
                    key={gateway.id}
                  >
                    <CheckboxField
                      checked={selectedGateways.includes(gateway.id)}
                      onClick={() => {
                        if (selectedGateways.includes(gateway.id)) {
                          setSelectedGateways(
                            selectedGateways.filter((id) => id !== gateway.id)
                          )
                        } else {
                          setSelectedGateways([...selectedGateways, gateway.id])
                        }
                      }}
                    />
                    <p style={{ margin: "0" }}>
                      {gateway.name && gateway.name !== ""
                        ? gateway.name
                        : gateway.id}
                    </p>
                  </div>
                ))}
            </div>
          </Box>
        )}
      </Box>
      <Box direction="column" justify="center" padding="0 0 20px 0">
        <Button
          text="Submit"
          onClick={() => {
            getCallback(type)()
          }}
        />
        <Button text="Dismiss" onClick={() => setShow(false)} />
      </Box>
    </PopUp>
  )
}
interface User {
  cognitoUser: string
  email: string
  role: string
  approved: boolean
  groups: { id: string; role: string }[]
  userGateways: string[]
  addedAt: string
}

const UsersTable = () => {
  const [editingUserId, setEditingUserId] = useState<string | null>(null)
  const [userPopup, setUserPopup] = useState<"add" | "edit" | "approve" | null>(
    null
  )
  const [loading, setLoading] = useState(true)
  const [gateways, setGateways] = useState<Gateway[]>([] as Gateway[])
  const [users, setUsers] = useState<User[]>([] as User[])
  const { token } = useContext<IAuthContext>(AuthContext)
  const { userInfo } = useUserContext()
  const [error, setError] = useState<string | null>(null)

  const getUsers = async () => {
    setLoading(true)
    await axios
      .get(
        `${process.env.REACT_APP_API_ADDRESS}/userGroups/${userInfo.groups[0].id}/users`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setUsers(res.data)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        setError(err.response.data || "")
      })
  }

  const getGatewayNames = async () => {
    setLoading(true)
    await axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/gateways/gatewaysIdsNames`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setGateways(res.data)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        setError(err.response.data || "")
      })
  }

  const deleteUser = async (userId: string) => {
    setLoading(true)
    await axios
      .delete(
        `${process.env.REACT_APP_API_ADDRESS}/userGroups/${userInfo.groups[0].id}/users/${userId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setLoading(false)
        getUsers()
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        setError(err.response.data || "")
      })
  }

  useEffect(() => {
    getUsers()
    getGatewayNames()
  }, [token])
  const user = users.find((user) => user.cognitoUser === editingUserId)
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <Box
        direction="column"
        align="center"
        padding="20px 20px 20px 20px"
        justify="center"
      >
        <Box padding="0 0 20px 0" direction="row-reverse" width={100}>
          <Button
            width="200px"
            onClick={() => {
              setUserPopup("add")
            }}
            text="Create and add new user"
          />
          <Button
            width="200px"
            onClick={() => {
              setUserPopup("approve")
            }}
            text="Add existing user"
            color="#00a3ff"
          />
        </Box>
        {!loading && gateways.length > 0 ? (
          <TableContainer>
            <TableStyled drag={false} padding={false}>
              <thead>
                <TableHead>
                  <StyledColumn width={100}>ID</StyledColumn>
                  <StyledColumn width={200}>Email</StyledColumn>
                  <StyledColumn width={100}>Registered at</StyledColumn>
                  <StyledColumn width={100}>Role</StyledColumn>
                  <StyledColumn width={100}>Actions</StyledColumn>
                </TableHead>
              </thead>
              <tbody>
                {users
                  .filter((u) => !u.approved)
                  .map((user) => (
                    <StyledTableRow key={user.cognitoUser} disabled={true}>
                      <Row text={user.cognitoUser.toString()} width={100} />
                      <Row text={user.email} width={200} />
                      <Row text={user.addedAt.toString()} width={100} />
                      <Row text={""} width={100} />
                      <StyledColumn width={100}>
                        <Button
                          onClick={() => {
                            setEditingUserId(user.cognitoUser)
                            setUserPopup("edit")
                          }}
                          text="Approve"
                        />
                        <Button
                          onClick={() => deleteUser(user.cognitoUser)}
                          color="#ff0707cf"
                          text="Delete"
                        />
                      </StyledColumn>
                    </StyledTableRow>
                  ))}
                {users
                  .filter((u) => u.approved)
                  .map((user) => (
                    <StyledTableRow key={user.cognitoUser} disabled={false}>
                      <Row text={user.cognitoUser.toString()} width={100} />
                      <Row text={user.email} width={200} />
                      <Row text={user.addedAt.toString()} width={100} />
                      <Row text={user.groups[0].role} width={100} />
                      <StyledColumn width={100}>
                        <Button
                          onClick={() => {
                            setEditingUserId(user.cognitoUser)
                            setUserPopup("edit")
                          }}
                          text="Edit"
                        />
                        <Button
                          onClick={() => deleteUser(user.cognitoUser)}
                          color="#ff0707cf"
                          text="Delete"
                        />
                      </StyledColumn>
                    </StyledTableRow>
                  ))}
              </tbody>
            </TableStyled>
          </TableContainer>
        ) : (
          <Loader />
        )}
      </Box>
      {userPopup && gateways.length > 0 && !loading && (
        <AddUserPopup
          setShow={(show: boolean) => setUserPopup(show ? "add" : null)}
          onSubmit={() => {
            getUsers()
          }}
          gateways={gateways}
          setEmail={
            editingUserId && userPopup === "edit" ? user?.email : undefined
          }
          setRole={
            editingUserId && userPopup === "edit"
              ? user?.groups[0].role
              : undefined
          }
          setUserGateways={
            editingUserId && userPopup === "edit"
              ? user?.userGateways
              : undefined
          }
          userId={
            editingUserId && userPopup === "edit" ? editingUserId : undefined
          }
          type={userPopup}
          groupId={userInfo.groups[0].id}
          setError={(error: string | null) => setError(error)}
        />
      )}
      {error !== null && (
        <ErrorPopup
          setError={(error: string | null) => setError(error)}
          error={error}
        />
      )}
    </div>
  )
}

export default UsersTable
