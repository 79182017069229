import React from 'react'

export const Cross = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_106_497)">
            <path d="M6.83594 6.83594C7.20312 6.47266 7.79688 6.47266 8.12891 6.83594L9.96484 8.67578L11.8359 6.83594C12.2031 6.47266 12.7969 6.47266 13.1289 6.83594C13.5273 7.20312 13.5273 7.79688 13.1289 8.12891L11.3242 9.96484L13.1289 11.8359C13.5273 12.2031 13.5273 12.7969 13.1289 13.1289C12.7969 13.5273 12.2031 13.5273 11.8359 13.1289L9.96484 11.3242L8.12891 13.1289C7.79688 13.5273 7.20312 13.5273 6.83594 13.1289C6.47266 12.7969 6.47266 12.2031 6.83594 11.8359L8.67578 9.96484L6.83594 8.12891C6.47266 7.79688 6.47266 7.20312 6.83594 6.83594ZM20 10C20 15.5234 15.5234 20 10 20C4.47656 20 0 15.5234 0 10C0 4.47656 4.47656 0 10 0C15.5234 0 20 4.47656 20 10ZM10 1.875C5.51172 1.875 1.875 5.51172 1.875 10C1.875 14.4883 5.51172 18.125 10 18.125C14.4883 18.125 18.125 14.4883 18.125 10C18.125 5.51172 14.4883 1.875 10 1.875Z" fill="#FF0707"/>
        </g>
        <defs>
            <clipPath id="clip0_106_497">
                <rect width="20" height="20" fill="white"/>
            </clipPath>
        </defs>
    </svg>
)