import { combineReducers } from "redux"

import dimensionsReducer from "./dimensionsReducer"
const reducers = combineReducers({
  dimensions: dimensionsReducer,
})

export default reducers

export type RootState = ReturnType<typeof reducers>
