import { Chargepoint, TableLayout, TableRows } from "components/types/types"
import { TableCellFormmater } from "helpers/helpers"
import { useTableContext } from "helpers/TableProvider"
import React from "react"
import { useSelector } from "react-redux"
import { RootState } from "state/reducers"

import RowEntryAction from "./RowEntryAction"
import { StyledTableRow } from "./Table.styled"
import TableActions from "./TableActions"

interface Props {
  layout: TableLayout[]
  index: number
  entry: TableRows
  selectAll: boolean
  selectCell?: boolean
  colorGetter?: (entry: TableRows) => string
}

export const TableRow = ({
  layout,
  index,
  entry,
  selectAll,
  selectCell,
  colorGetter,
}: Props) => {
  const state = useSelector((state: RootState) => state.dimensions)
  const { noAction, usedDevices, devices, oldDevices, disabledCheck } =
    useTableContext()

  const old = oldDevices ? oldDevices : []
  const isOld = old.some((element) => {
    if (element === entry["id"]) {
      return true
    }
    return false
  })

  const used = usedDevices ? usedDevices : []
  const isUsed = used.some((element) => {
    if (element === entry["id"]) {
      return true
    }
    return false
  })

  const arr = devices ? devices : []
  const isFound = arr.some((element) => {
    if (element === entry["id"]) {
      return true
    }
    return false
  })

  const checkDisabled = disabledCheck ? isUsed && !isFound && !isOld : false
  return (
    <StyledTableRow
      disabled={checkDisabled}
      state={
        (entry["connection"] && entry["connection"] === "disconnected") ||
        entry["connection"] === "init"
          ? "Error"
          : entry["cpState"] && entry["cpState"][0]
      }
      connection={entry["connection"] && entry["connection"]}
      key={`row${index}`}
      style={{ background: colorGetter ? colorGetter(entry) : undefined }}
    >
      <>
        {selectCell && (
          <RowEntryAction
            disabled={checkDisabled}
            className={checkDisabled ? "" : "selectColumn"}
            id={entry["id"]}
            selectAll={selectAll}
          />
        )}
        {layout.map((item, i) =>
          TableCellFormmater(
            item.type,
            item.data,
            entry,
            i + "" + index,
            item.measure,
            state[0].widths[i] || item.width,
            entry["connection"] && entry["connection"] === "disconnected"
          )
        )}
        {!noAction && <TableActions entry={entry as Chargepoint} />}
      </>
    </StyledTableRow>
  )
}
