import { Toasts } from "components/types/types"
import React, { useEffect, useState } from "react"

import Toast from "./Toast"
import { StyledToastContainer } from "./Toast.styled"

interface Props {
  toasts: Toasts[]
}
const ToastContainer = ({ toasts }: Props) => {
  const [arr, setArr] = useState(toasts)

  useEffect(() => {
    setArr(toasts)
  }, [toasts])

  return (
    <StyledToastContainer>
      {arr.map((item, i) =>
        item.type === "success" ? (
          <Toast
            date={item.date}
            index={i}
            key={i}
            type={item.type}
            customMessage={item.customMessage}
          />
        ) : (
          <Toast
            date={item.date}
            index={i}
            key={i}
            type={item.type}
            customMessage={item.customMessage}
          />
        )
      )}
    </StyledToastContainer>
  )
}

export default ToastContainer
