import axios from "axios"
import Button from "components/Buttons/Button"
import InputField from "components/InputField/InputField"
import SelectField from "components/InputField/SelectField"
import Loader from "components/Loader/Loader"
import { Box } from "components/Shared/Box.styled"
import { ModelsType, PowerMetersType } from "components/types/types"
import { useAppContext } from "helpers/AppProvider"
import { toastHandler } from "helpers/helpers"
import { usePopUpContext } from "helpers/PopupProvider"
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react"
import { useTranslation } from "react-i18next"

interface Props {
  entry?: PowerMetersType
  id: string
  setChanged: Dispatch<SetStateAction<boolean>>
  post?: boolean
}

const PowerMeterConfigurationPopUp = ({
  entry,
  id,
  setChanged,
  post,
}: Props) => {
  const [loading, setLoading] = useState(false)
  const [models, setModels] = useState<ModelsType[]>([])

  const { setShow } = usePopUpContext()
  const { t } = useTranslation()
  const { toasts, setToasts, token } = useAppContext()

  const model = useRef<HTMLSelectElement | null>(null)
  const description = useRef<HTMLInputElement | null>(null)
  const port = useRef<HTMLInputElement | null>(null)
  const localIp = useRef<HTMLInputElement | null>(null)
  const slaveId = useRef<HTMLInputElement | null>(null)
  // const solar = useRef<HTMLInputElement | null>(null)
  // const building = useRef<HTMLInputElement | null>(null)
  // const demand = useRef<HTMLInputElement | null>(null)
  // const monitoring = useRef<HTMLInputElement | null>(null)

  const getModels = () => {
    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/powerMeterModels`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setModels(res.data)
      })
  }

  const updatePowermeter = () => {
    setLoading(true)
    const i = models.findIndex(
      (e: ModelsType) => e.name === model.current?.value
    )

    axios
      .put(
        `${process.env.REACT_APP_API_ADDRESS}/powerMeters/${id}/${
          entry && entry.id
        }`,
        {
          // solar: solar.current?.checked,
          // building: building.current?.checked,
          // demand: demand.current?.checked,
          // monitoring: monitoring.current?.checked,
          modelName: model.current?.value,
          modelId: models[i].id,
          description: description.current?.value,
          port: Number(port.current?.value),
          localIp: localIp.current?.value,
          slaveId: Number(slaveId.current?.value),
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        setShow(false)
        setChanged(true)
        setLoading(false)
        toastHandler(toasts, setToasts, true)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        toastHandler(toasts, setToasts, false)
      })
  }

  const addPowermeter = () => {
    setLoading(true)
    const i = models.findIndex(
      (e: ModelsType) => e.name === model.current?.value
    )

    axios
      .post(
        `${process.env.REACT_APP_API_ADDRESS}/powerMeters/${id}`,
        {
          // solar: solar.current?.checked,
          // building: building.current?.checked,
          // demand: demand.current?.checked,
          // monitoring: monitoring.current?.checked,
          modelId: models[i].id,
          modelName: model.current?.value,
          description: description.current?.value,
          port: Number(port.current?.value),
          localIp: localIp.current?.value,
          slaveId: Number(slaveId.current?.value),
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        setShow(false)
        setChanged(true)
        setLoading(false)
        toastHandler(toasts, setToasts, true)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        toastHandler(toasts, setToasts, false)
      })
  }

  useEffect(() => {
    getModels()
  }, [])

  const names: string[] = []
  models.map((item: ModelsType) => {
    names.push(item.name)
  })

  return (
    <>
      {!loading ? (
        <>
          <Box
            padding="14px 0 0 0"
            direction="row"
            align="self-end"
            justify="space-between"
          >
            <InputField
              type="text"
              label={t("ID")}
              value={entry && entry.id}
              disabled={true}
              width={100}
            />
          </Box>
          <Box
            padding="14px 0 0 0"
            direction="row"
            align="self-end"
            justify="space-between"
            width={100}
          >
            <SelectField
              label={t("Model")}
              placeholder={t("Select Powermeter Model")}
              options={names}
              selected={entry && entry.modelName}
              innerRef={model}
              width={100}
            />
          </Box>
          <Box padding="14px 0 0 0" width={100}>
            <InputField
              width={100}
              type="text"
              label={t("Description")}
              value={entry && entry.description}
              innerRef={description}
              placeholder={t("Grid Connection Point 1")}
            />
          </Box>

          <Box padding="14px 0 0 0">
            <InputField
              type="text"
              label={t("Local IP")}
              value={entry && entry.localIp}
              innerRef={localIp}
              placeholder="192.168.1.2"
              width={100}
            />
          </Box>
          <Box padding="14px 0 0 0">
            <InputField
              type="number"
              label={t("Port")}
              value={entry && entry.port}
              innerRef={port}
              placeholder="502"
              width={100}
            />
          </Box>
          <Box padding="14px 0 0 0">
            <InputField
              type="number"
              label={t("Slave ID")}
              innerRef={slaveId}
              placeholder="502"
              value={entry && entry.slaveId}
              width={100}
            />
          </Box>
          <Box padding="14px 0 0 0"></Box>
          <hr />
          <Box justify="flex-end">
            <Button
              secondary={true}
              onClick={() => setShow(false)}
              text={t("Cancel")}
            />
            <Button
              margin="0 0 0 20px"
              onClick={() => (post ? addPowermeter() : updatePowermeter())}
              text={t("Save")}
            />
          </Box>
        </>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default PowerMeterConfigurationPopUp
