import ButtonIcon from "components/Buttons/ButtonIcon"
import { ArrowDown } from "components/Icons/ArrowDown"
import { List, ListItem } from "components/SideMenu/SideMenu.styled"
import React, { useContext, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce"
import { Link } from "wouter"

import {
  StyledDropDown,
  StyledUserMenu,
  UserCircle,
  UserName,
} from "./UserMenu.styled"

const UserMenu = () => {
  const [open, setOpen] = useState(false)
  const menuWidth = useRef<HTMLInputElement | null>(null)
  const { t } = useTranslation()
  const { logOut, idTokenData } = useContext<IAuthContext>(AuthContext)

  const logoutUser = () => {
    localStorage.removeItem("ROCP_loginInProgress")
    localStorage.removeItem("ROCP_token")
    localStorage.removeItem("ROCP_refreshToken")
    localStorage.removeItem("PKCE_code_verifier")
    logOut()
  }

  return (
    <>
      <ButtonIcon
        onClick={() => setOpen(!open)}
        text={
          <>
            <StyledUserMenu ref={menuWidth}>
              <UserCircle>UR</UserCircle>
              <UserName>{idTokenData?.email}</UserName>
            </StyledUserMenu>
          </>
        }
        icon={<ArrowDown />}
        rotate={open}
        reverse={true}
      />

      {open && (
        <StyledDropDown style={{ width: menuWidth.current?.offsetWidth }}>
          <List>
            <Link href={"/userSettings"}>
              <ListItem>
                <p>{t("Settings")}</p>
              </ListItem>
            </Link>
            <ListItem onClick={() => logoutUser()}>
              <p>{t("Log Out")}</p>
            </ListItem>
          </List>
        </StyledDropDown>
      )}
    </>
  )
}

export default UserMenu
