import React from "react"

export const CloudSlash = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7531 21.9781H6.45939C3.29064 21.9781 0.731262 19.4187 0.731262 16.2906C0.731262 13.4062 2.96564 11.0094 5.80939 10.6437C6.01251 6.94685 9.18126 4.02185 13 4.02185C13.8531 4.02185 14.6656 4.14373 15.3563 4.4281C16.8594 4.95623 18.1594 5.97185 19.0531 7.3531C20.6781 7.63748 22.1813 8.40935 23.2375 9.58748C24.5375 10.9687 25.2281 12.7562 25.2281 14.625C25.3094 18.6875 21.8969 21.9781 17.7531 21.9781ZM6.50001 20.15H17.7531C20.9219 20.15 23.4813 17.6719 23.4813 14.5844C23.4813 13.2031 22.9531 11.8625 21.9781 10.8062C21.0844 9.83123 19.7844 9.22185 18.4438 9.05935C18.1594 9.01873 17.875 8.85623 17.7531 8.61248C17.1031 7.43435 16.0469 6.5406 14.7875 6.13435C14.2594 5.93123 13.6906 5.84998 13.0406 5.84998C10.075 5.84998 7.63751 8.20623 7.63751 11.1312V11.5375C7.63751 12.025 7.23126 12.4719 6.70314 12.4719H6.50001C4.26564 12.4719 2.55939 14.1781 2.55939 16.3312C2.55939 18.4844 4.26564 20.15 6.50001 20.15Z"
      fill="#33B905"
      fill-opacity="0.75"
    />
    <path
      d="M17.7531 21.9781H6.45939C3.29064 21.9781 0.731262 19.4187 0.731262 16.2906C0.731262 13.4062 2.96564 11.0094 5.80939 10.6437C6.01251 6.94685 9.18126 4.02185 13 4.02185C13.8531 4.02185 14.6656 4.14373 15.3563 4.4281C16.8594 4.95623 18.1594 5.97185 19.0531 7.3531C20.6781 7.63748 22.1813 8.40935 23.2375 9.58748C24.5375 10.9687 25.2281 12.7562 25.2281 14.625C25.3094 18.6875 21.8969 21.9781 17.7531 21.9781ZM6.50001 20.15H17.7531C20.9219 20.15 23.4813 17.6719 23.4813 14.5844C23.4813 13.2031 22.9531 11.8625 21.9781 10.8062C21.0844 9.83123 19.7844 9.22185 18.4438 9.05935C18.1594 9.01873 17.875 8.85623 17.7531 8.61248C17.1031 7.43435 16.0469 6.5406 14.7875 6.13435C14.2594 5.93123 13.6906 5.84998 13.0406 5.84998C10.075 5.84998 7.63751 8.20623 7.63751 11.1312V11.5375C7.63751 12.025 7.23126 12.4719 6.70314 12.4719H6.50001C4.26564 12.4719 2.55939 14.1781 2.55939 16.3312C2.55939 18.4844 4.26564 20.15 6.50001 20.15Z"
      fill="#FF7575"
    />
  </svg>
)
