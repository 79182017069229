import styled from "styled-components"

export const StyledLoader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 200px;
  align-items: center;
  img {
    width: 100px;
    height: 100px;
  }
`
