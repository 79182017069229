import React from "react"

export const Error = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7531 21.9781H6.45937C3.29062 21.9781 0.731247 19.4187 0.731247 16.2906C0.731247 13.4062 2.96562 11.0094 5.80937 10.6437C6.0125 6.94685 9.18125 4.02185 13 4.02185C13.8531 4.02185 14.6656 4.14373 15.3562 4.4281C16.8594 4.95623 18.1594 5.97185 19.0531 7.3531C20.6781 7.63748 22.1812 8.40935 23.2375 9.58748C24.5375 10.9687 25.2281 12.7562 25.2281 14.625C25.3094 18.6875 21.8969 21.9781 17.7531 21.9781ZM6.5 20.15H17.7531C20.9219 20.15 23.4812 17.6719 23.4812 14.5844C23.4812 13.2031 22.9531 11.8625 21.9781 10.8062C21.0844 9.83123 19.7844 9.22185 18.4437 9.05935C18.1594 9.01873 17.875 8.85623 17.7531 8.61248C17.1031 7.43435 16.0469 6.5406 14.7875 6.13435C14.2594 5.93123 13.6906 5.84998 13.0406 5.84998C10.075 5.84998 7.6375 8.20623 7.6375 11.1312V11.5375C7.6375 12.025 7.23125 12.4719 6.70312 12.4719H6.5C4.26562 12.4719 2.55937 14.1781 2.55937 16.3312C2.55937 18.4844 4.26562 20.15 6.5 20.15Z"
      fill="#33B905"
      fill-opacity="0.75"
    />
    <path
      d="M17.7531 21.9781H6.45937C3.29062 21.9781 0.731247 19.4187 0.731247 16.2906C0.731247 13.4062 2.96562 11.0094 5.80937 10.6437C6.0125 6.94685 9.18125 4.02185 13 4.02185C13.8531 4.02185 14.6656 4.14373 15.3562 4.4281C16.8594 4.95623 18.1594 5.97185 19.0531 7.3531C20.6781 7.63748 22.1812 8.40935 23.2375 9.58748C24.5375 10.9687 25.2281 12.7562 25.2281 14.625C25.3094 18.6875 21.8969 21.9781 17.7531 21.9781ZM6.5 20.15H17.7531C20.9219 20.15 23.4812 17.6719 23.4812 14.5844C23.4812 13.2031 22.9531 11.8625 21.9781 10.8062C21.0844 9.83123 19.7844 9.22185 18.4437 9.05935C18.1594 9.01873 17.875 8.85623 17.7531 8.61248C17.1031 7.43435 16.0469 6.5406 14.7875 6.13435C14.2594 5.93123 13.6906 5.84998 13.0406 5.84998C10.075 5.84998 7.6375 8.20623 7.6375 11.1312V11.5375C7.6375 12.025 7.23125 12.4719 6.70312 12.4719H6.5C4.26562 12.4719 2.55937 14.1781 2.55937 16.3312C2.55937 18.4844 4.26562 20.15 6.5 20.15Z"
      fill="#33B905"
    />
    <circle cx="20" cy="6" r="4.5" fill="#EED202" stroke="#33B905" />
  </svg>
)
