import { Box } from "components/Shared/Box.styled"
import React from "react"
import { primary } from "styles/colors"
import { regular, standard } from "styles/fonts"

interface Props {
  text: string
  l1: string
  l2: string
  l3: string
  total?: string
  measure?: string
  showTotal?: boolean
}
const PhysicalLimit = ({
  l1,
  l2,
  l3,
  total,
  text,
  measure,
  showTotal,
}: Props) => {
  return (
    <Box direction="column">
      <Box direction="row" justify="space-between" align="center">
        <span>
          <span style={{ color: primary }}>L1</span>
          <span
            style={{ fontSize: regular, marginLeft: "5px", marginRight: "5px" }}
          >
            {l1}
          </span>
          <span>{measure ? measure : "A"}</span>
        </span>
        <span>
          <span style={{ color: primary }}>L2</span>
          <span
            style={{ fontSize: regular, marginLeft: "5px", marginRight: "5px" }}
          >
            {l2}
          </span>
          <span>{measure ? measure : "A"}</span>
        </span>
        <span>
          <span style={{ color: primary }}>L3</span>
          <span
            style={{ fontSize: regular, marginLeft: "5px", marginRight: "5px" }}
          >
            {l3}
          </span>
          <span>{measure ? measure : "A"}</span>
        </span>
        {total && showTotal && (
          <span>
            <span style={{ color: primary }}>≈</span>
            <span
              style={{
                fontSize: regular,
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              {total}
            </span>
            <span>KW</span>
          </span>
        )}
      </Box>
      <span style={{ fontSize: "10px", fontWeight: standard }}>{text}</span>
    </Box>
  )
}

export default PhysicalLimit
