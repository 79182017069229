import axios from "axios"
import Button from "components/Buttons/Button"
import InputField from "components/InputField/InputField"
import SelectField from "components/InputField/SelectField"
import Loader from "components/Loader/Loader"
import { Box } from "components/Shared/Box.styled"
import { FlexCol } from "components/Shared/FlexCol"
import { Label } from "components/Shared/Label.styled"
import { SubPaths } from "components/Shared/SubPaths"
import { Warning } from "components/Shared/Warning"
import SubMenu from "components/SubMenu/SubMenu"
import { Gateway } from "components/types/types"
import { useAppContext } from "helpers/AppProvider"
import { toastHandler } from "helpers/helpers"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

interface Props {
  id: string
}

const europeanTimezones = [
  Intl.DateTimeFormat().resolvedOptions().timeZone,
  "UTC",
  "Europe/Amsterdam", // Capital of the Netherlands
  "Europe/Athens", // Capital of Greece
  "Europe/Berlin", // Capital of Germany
  "Europe/Brussels", // Capital of Belgium
  "Europe/Copenhagen", // Capital of Denmark
  "Europe/Dublin", // Capital of Ireland
  "Europe/Helsinki", // Capital of Finland
  "Europe/Lisbon", // Capital of Portugal
  "Europe/London", // Capital of the United Kingdom
  "Europe/Luxembourg", // Capital of Luxembourg
  "Europe/Madrid", // Capital of Spain
  "Europe/Oslo", // Capital of Norway
  "Europe/Paris", // Capital of France
  "Europe/Rome", // Capital of Italy
  "Europe/Stockholm", // Capital of Sweden
  "Europe/Tallinn", // Capital of Estonia
  "Europe/Vienna", // Capital of Austria
  "Europe/Warsaw", // Capital of Poland
  // You can add more if needed
]

const General = ({ id }: Props) => {
  const [loading, setLoading] = useState(true)
  const [changed, setChanged] = useState(false)
  const [data, setData] = useState<Gateway>()
  const [schedule, setSchedule] = useState<{
    timezone?: string
    schedule: any
  } | null>()
  const [timezoneLoading, setTimezoneLoading] = useState(true)
  const [timezoneChanged, setTimezoneChanged] = useState(false)
  const { toasts, setToasts, token } = useAppContext()
  const schedulingTimezone = useRef<HTMLSelectElement | null>(null)
  const unbalancedLoadPrevention = useRef<HTMLSelectElement | null>(null)
  const maximumPhase = useRef<HTMLInputElement | null>(null)

  const adaptiveCharging = useRef<HTMLSelectElement | null>(null)
  const adaptiveDuration = useRef<HTMLInputElement | null>(null)
  const adaptivePeriod = useRef<HTMLInputElement | null>(null)

  const gateway = () => {
    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/gateways/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setData(res.data)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  const getSchedule = () => {
    setTimezoneLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/clusterConfigs/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setSchedule(res.data.staticSchedule)
        setTimezoneLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const updateGateway = async () => {
    if (changed) {
      await axios
        .put(
          `${process.env.REACT_APP_API_ADDRESS}/gateways/${id}`,
          {
            unbalancedLoadPrevention: {
              enabled:
                unbalancedLoadPrevention.current?.value === t("Enabled")
                  ? true
                  : false,
              maximumPhaseCurrentDifference: maximumPhase.current?.value,
            },
            adaptiveCharging: {
              enabled:
                adaptiveCharging.current?.value === t("Enabled") ? true : false,
              reofferPeriod: adaptivePeriod.current?.value,
              reofferDuration: adaptiveDuration.current?.value,
            },
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then(() => {
          toastHandler(toasts, setToasts, true)
          setChanged(false)
        })
        .catch((err) => {
          console.log(err)
          toastHandler(toasts, setToasts, false)
        })
    }
    if (timezoneChanged) {
      axios
        .put(
          `${process.env.REACT_APP_API_ADDRESS}/clusterConfigs/${id}`,
          {
            staticSchedule: {
              timezone: schedulingTimezone.current?.value,
              schedule: schedule?.schedule,
            },
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then(() => {
          toastHandler(toasts, setToasts, true)
          setTimezoneChanged(false)
        })
        .catch((err) => {
          console.log(err)
          toastHandler(toasts, setToasts, false)
        })
    }
  }

  useEffect(() => {
    gateway()
    getSchedule()
  }, [changed, token])

  const { t } = useTranslation()

  return (
    <FlexCol>
      <SubMenu items={SubPaths()} id={id} />
      {!loading && !timezoneLoading ? (
        <div>
          <Box padding="14px 0 0 0">
            <Label blue={true} size="regular">
              {t("Unbalanced Load Preventation")}
            </Label>
          </Box>
          <Box padding="14px 0 0 0">
            <SelectField
              label={t("Unbalanced Load Preventation")}
              options={[t("Enabled"), t("Disabled")]}
              onChange={() => setChanged(true)}
              selected={
                data?.unbalancedLoadPrevention?.enabled
                  ? t("Enabled")
                  : t("Disabled")
              }
              innerRef={unbalancedLoadPrevention}
              width={50}
            />
          </Box>
          <Box padding="14px 0 0 0" width={50}>
            <InputField
              width={100}
              type="number"
              onChange={() => setChanged(true)}
              label={t("Maximum Phase Current Difference (A)")}
              value={
                data?.unbalancedLoadPrevention?.maximumPhaseCurrentDifference
              }
              innerRef={maximumPhase}
              min={0}
            />
          </Box>
          <Box padding="28px 0 0 0">
            <Label blue={true} size="regular">
              {t("Adaptive Charging")}
            </Label>
          </Box>
          <Box padding="14px 0 0 0">
            <SelectField
              label={t("Adaptive Charging")}
              options={[t("Enabled"), t("Disabled")]}
              onChange={() => setChanged(true)}
              selected={
                data?.adaptiveCharging?.enabled ? t("Enabled") : t("Disabled")
              }
              innerRef={adaptiveCharging}
              width={50}
            />
          </Box>
          <Box padding="14px 0 0 0" width={50}>
            <InputField
              width={100}
              type="number"
              onChange={() => setChanged(true)}
              label={t("Adaptive Charging Reoffer Period (s)")}
              value={data?.adaptiveCharging?.reofferPeriod}
              innerRef={adaptivePeriod}
              min={0}
            />
          </Box>
          <Box padding="14px 0 0 0" width={50}>
            <InputField
              width={100}
              type="number"
              onChange={() => setChanged(true)}
              label={t("Adaptive Charging Reoffer Duration (s)")}
              value={data?.adaptiveCharging?.reofferDuration}
              innerRef={adaptiveDuration}
              min={0}
            />
          </Box>
          <Box padding="14px 0 0 0">
            <Label blue={true} size="regular">
              {t("Scheduling")}
            </Label>
          </Box>
          <Box padding="14px 0 0 0">
            <SelectField
              label={t("Timezone")}
              options={europeanTimezones}
              onChange={() => setTimezoneChanged(true)}
              selected={schedule?.timezone || "UTC"}
              innerRef={schedulingTimezone}
              width={50}
            />
          </Box>
          {(changed || timezoneChanged) && (
            <Box padding="20px 0 0 0" width={50} justify="space-between">
              <Warning>{t("Unsaved changes.")}</Warning>
              <Button
                margin="0 0 0 20px"
                onClick={() => updateGateway()}
                text={t("Save Changes")}
              />
            </Box>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </FlexCol>
  )
}

export default General
