import axios from "axios"
import ButtonIcon from "components/Buttons/ButtonIcon"
import { Adjust } from "components/Icons/Adjust"
import { ArrowDown } from "components/Icons/ArrowDown"
import Loader from "components/Loader/Loader"
import AddPowermeterPopUp from "components/PopUp/AddPowermeterPopUp"
import PopUp from "components/PopUp/PopUp"
import { Label } from "components/Shared/Label.styled"
import Table from "components/Table/Table"
import { useAppContext } from "helpers/AppProvider"
import { TableProvider } from "helpers/TableProvider"
import { PowerMeterTableTree } from "helpers/TableStructures"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { TreeSubDropdown } from "./Tree.styled"

interface Props {
  gwId: string
}

export const TreeDropdownPowermeters = ({ gwId }: Props) => {
  const [show, setShow] = useState(false)
  const [showConfig, setShowConfig] = useState(false)
  const [searchString, setSearchString] = useState("")
  const [search, setSearch] = useState("")

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  const { t } = useTranslation()
  const { token } = useAppContext()

  const powerMeters = () => {
    const filterById = `"id":"${searchString}"`
    const filterstring = `?filter={${searchString !== "" ? filterById : ""}}`

    axios
      .get(
        `${process.env.REACT_APP_API_ADDRESS}/powerMeters/${gwId}${filterstring}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setData(res.data)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    powerMeters()
    const interval = setInterval(() => {
      powerMeters()
    }, 5000)
    return () => clearInterval(interval)
  }, [loading, search, token])

  return (
    <>
      <TreeSubDropdown>
        <ButtonIcon
          onClick={() => setShow(!show)}
          rotate={show}
          icon={<ArrowDown small={true} />}
        />
        <Label size={"small"} style={{ marginLeft: "8px" }}>
          {t("Powermeters") + ` (${data.length})`}
        </Label>
      </TreeSubDropdown>

      {show && (
        <div style={{ padding: "20px" }}>
          {!loading ? (
            <TableProvider
              noTitle={true}
              searchString={setSearchString}
              search={setSearch}
              searchValue={searchString}
              popUpData={() => (
                <AddPowermeterPopUp gwId={gwId} setLoadingParent={setLoading} />
              )}
              noAction={true}
            >
              <Table
                data={data}
                layout={PowerMeterTableTree}
                title={t("Powermeters")}
                toolbar={true}
                addText={"Add/Remove"}
                addIcon={<Adjust />}
                addRow={() => setShowConfig(true)}
              />
            </TableProvider>
          ) : (
            <Loader />
          )}
        </div>
      )}
      {showConfig && (
        <PopUp double={true} title={t("Powermeters")} setShow={setShowConfig}>
          <AddPowermeterPopUp setLoadingParent={() => setLoading} gwId={gwId} />
        </PopUp>
      )}
    </>
  )
}
