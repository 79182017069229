import axios from "axios"
import Button from "components/Buttons/Button"
import Loader from "components/Loader/Loader"
import { Box } from "components/Shared/Box.styled"
import Table from "components/Table/Table"
import { TableRows } from "components/types/types"
import { useAppContext } from "helpers/AppProvider"
import { toastHandler } from "helpers/helpers"
import { usePopUpContext } from "helpers/PopupProvider"
import { TableProvider } from "helpers/TableProvider"
import { AddPowermeterPopup } from "helpers/TableStructures"
import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

interface Props {
  gwId: string
  setLoadingParent: Dispatch<SetStateAction<boolean>>
  clusterId?: string
}

const AddPowermeterPopUp = ({ gwId, setLoadingParent, clusterId }: Props) => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const { toasts, setToasts, token } = useAppContext()

  const [data, setData] = useState([])
  const [used, setUsed] = useState([])
  const [devices, setDevices] = useState<string[]>([])

  const clusters = () => {
    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/clusterConfigs/${gwId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setUsed(res.data.assignedDevices)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  const { setShow } = usePopUpContext()
  const url = clusterId
    ? `${process.env.REACT_APP_API_ADDRESS}/powerMeters/${gwId}}`
    : `${process.env.REACT_APP_API_ADDRESS}/powerMeters/${gwId}`
  const updateLevel = () => {
    axios
      .put(
        url,
        {
          devices: devices,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        toastHandler(toasts, setToasts, true)
      })
      .catch((err) => {
        console.log(err)
        toastHandler(toasts, setToasts, false)
      })
    setLoadingParent(true)
    setShow(false)
  }

  const getDevices = () => {
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setDevices(res.data.devices)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const gateways = () => {
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setData(res.data)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    clusters()
    gateways()
    const interval = setInterval(() => {
      gateways()
    }, 5000)
    return () => clearInterval(interval)
  }, [loading, token])

  useEffect(() => {
    getDevices()
  }, [loading])

  return (
    <>
      {!loading ? (
        <TableProvider
          noAction={true}
          devices={devices}
          setLoading={setLoading}
          usedDevices={used}
          disabledCheck={true}
        >
          <div style={{ maxHeight: "600px", overflowY: "auto" }}>
            {!loading ? (
              <Table
                layout={AddPowermeterPopup}
                title={t("Powermeters")}
                data={data as TableRows[]}
                updatePath={`${process.env.REACT_APP_API_ADDRESS}/clusterConfigs/${gwId}`}
                selectCell={true}
              />
            ) : (
              <Loader />
            )}
          </div>
        </TableProvider>
      ) : (
        <Loader />
      )}
      <Box justify="flex-end" padding="20px 0 0 0">
        <Button
          secondary={true}
          onClick={() => setShow(false)}
          text={t("Cancel")}
        />
        <Button
          margin="0 0 0 20px"
          onClick={() => updateLevel()}
          text={t("Save")}
        />
      </Box>
    </>
  )
}

export default AddPowermeterPopUp
