import { FlexRow } from "components/Shared/FlexRow"
import { States, Status } from "components/types/types"
import styled, { css } from "styled-components"
import {
  available,
  black,
  charging,
  disabled,
  disconnected,
  faulted,
  finishing,
  preparing,
  primary,
  secondary,
  shadow,
  suspendedEV,
  suspendedEVSE,
  unavailable,
  white,
} from "styles/colors"
import { standard, xsmall } from "styles/fonts"
import { radius } from "styles/sizes"
interface Props {
  width: number
}

interface Table {
  padding?: boolean
  state?: States
  disabled?: boolean
  connection?: Status
  drag?: boolean
}

export const TableContainer = styled.div<Table>`
  overflow-y: scroll;
  ${(props) =>
    !props.drag &&
    css`
      width: 100%;
    `};
`

export const TableStyled = styled.table<Table>`
  ${(props) =>
    !props.drag &&
    css`
      width: 100%;
    `};
  border-spacing: 0 !important;
  border-color: none;
  background: ${white};
  border-collapse: collapse;
  border-radius: ${radius};
  box-shadow: ${shadow};

  ${(props) =>
    props.padding &&
    css`
      th {
        border-left: 1px solid ${primary};
        padding-left: 5px;
        font-size: ${xsmall};
      }
      td {
        text-align: center;
        padding: 20px;
        border-left: 1px solid ${primary};
        font-size: ${xsmall};
      }
    `};
  td {
    height: 50px;
  }
`

export const TableHead = styled.tr`
  background: ${secondary};
  color: ${primary};
  border-top: 1px solid ${primary};
  border-bottom: 1px solid ${primary};
`

export const StyledTableRow = styled.tr<Table>`
  border-bottom: 1px solid ${primary};

  ${(props) =>
    props.state === "Available" &&
    css`
      background: ${available};
    `};
  ${(props) =>
    props.state === "Error" &&
    css`
      background: ${white} !important;
      p {
        color: ${disabled};
      }
    `};
  ${(props) =>
    props.state === "Charging" &&
    css`
      background: ${charging};
    `};
  ${(props) =>
    props.state === "Finishing" &&
    css`
      background: ${finishing};
    `};
  ${(props) =>
    props.state === "Preparing" &&
    css`
      background: ${preparing};
    `};
  ${(props) =>
    props.state === "SuspendedEV" &&
    css`
      background: ${suspendedEV};
    `};
  ${(props) =>
    props.state === "SuspendedEVSE" &&
    css`
      background: ${suspendedEVSE};
    `};
  ${(props) =>
    props.state === "Faulted" &&
    css`
      background: ${faulted};
    `};
  ${(props) =>
    props.state === "Unavailable" &&
    css`
      background: ${unavailable};
    `};

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.3;
    `};

  ${(props) =>
    props.connection === "disconnected" &&
    css`
      background: ${disconnected};
    `};
`

export const StyledColumn = styled.th<Props>`
  min-width: ${(props) => props.width + "px"};
  max-width: ${(props) => props.width + "px"};
  width: ${(props) => props.width + "px"};

  p {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 5px;
    white-space: nowrap;
    font-size: ${xsmall};

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 1px;
      height: 1px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
`

export const ColumnFilters = styled.div`
  position: absolute;
  background: ${white};
  padding: 10px;
  border: 1px solid ${primary};
  border-radius: ${radius};
  max-height: 50vh;
  overflow-y: scroll;

  p {
    font-weight: ${standard};
    color: ${black};
  }
`

export const StyledSelectColumn = styled.th`
  min-width: 40px;
  max-width: 40px;
  width: 40px;
`

export const StyledScrollRow = styled.div`
  min-width: 100px;
  max-width: 100px;
  width: 100px;

  p {
    white-space: nowrap;
    font-size: ${xsmall};
    padding: 0;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 1px;
    height: 1px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`

export const StyledActions = styled(FlexRow)`
  justify-content: space-around;
  align-items: center;
  svg {
    cursor: pointer;
  }
`
export const StyledAddRow = styled.div`
  min-width: 10px;
  width: 100%;
`
export const NoData = styled.tr`
  white-space: nowrap;
  font-size: ${xsmall};
  margin: 3px;
`
