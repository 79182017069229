import { FlexRow } from "components/Shared/FlexRow"
import { Alerts } from "components/types/types"
import styled, { css } from "styled-components"
import { failure, success } from "styles/colors"
import { xsmall } from "styles/fonts"
import { radius } from "styles/sizes"

interface Props {
  type: Alerts
  customMessage?: boolean
}

export const StyledToast = styled(FlexRow)<Props>`
  width: 300px;
  height: 40px;
  ${(props) =>
    props.customMessage &&
    css`
      height: 60px;
    `};

  border: 1px solid ${(props) => (props.type === "success" ? success : failure)};
  margin-right: 10px;
  background: white;
  border-radius: ${radius};
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  span {
    margin-left: 10px;
    font-size: ${xsmall};
  }
`
export const ToastIcon = styled.div<Props>`
  display: flex;
  align-items: center;
  background: ${(props) => (props.type === "success" ? success : failure)};
  height: 100%;
  min-width: 55px;
  justify-content: center;
`
export const StyledToastContainer = styled.div`
  position: fixed;
  right: 0;
  bottom: 50px;
`
