import { ActionType } from "../action-types/index"
import { Action } from "../actions"
import { TableDimensions } from "../models"

const initialState = [{ title: "chargingStations", widths: [] }]
const reducer = (
  state: Array<TableDimensions> = initialState,
  action: Action
): Array<TableDimensions> => {
  switch (action.type) {
    case ActionType.EDIT:
      state[0].title = action.payload[0].title
      state[0].widths = action.payload[0].widths
      return (state = [...state])

    default:
      return state
  }
}

export default reducer
