import React from "react"

import { StyledButton } from "./Button.styled"

interface Props {
  text: string
  onClick: () => void
  width?: string
  margin?: string
  secondary?: boolean
  active?: boolean
  height?: string
  disabled?: boolean
  color?: string
}

const Button = ({
  text,
  onClick,
  width,
  margin,
  secondary,
  active,
  disabled,
  color,
}: Props) => (
  <StyledButton
    width={width}
    margin={margin}
    type="button"
    onClick={onClick}
    value={text}
    secondary={secondary}
    active={active}
    disabled={disabled}
    color={color}
  />
)

export default Button
