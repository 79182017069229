import { StyledCheckboxField } from "components/InputField/InputField.styled"
import { useTableContext } from "helpers/TableProvider"
import React, { useEffect, useState } from "react"

import { StyledSelectColumn } from "./Table.styled"

interface Props {
  selectAll?: boolean
  id: string
  disabled: boolean
  className?: string
}

const RowEntryAction = ({ selectAll, id, disabled, className }: Props) => {
  const [selected, setSelected] = useState(false)
  const [prevSelected, setPrevSelected] = useState(false)
  const {
    devices,
    setDevices,
    oldDevices,
    setOldDevices,
    selectCellClick,
    usedDevices,
  } = useTableContext()

  useEffect(() => {
    selectAll && setPrevSelected(true)
    const arr = devices ? devices : []
    const isFound = arr.some((element) => {
      if (element === id) {
        return true
      }

      return false
    })
    /* eslint-disable indent */
    const isUsed = usedDevices
      ? usedDevices.some((element) => {
          if (element === id) {
            return true
          }
          return false
        })
      : []

    selectAll ? setSelected(true) : setSelected(false)
    isUsed && setSelected(true)
    isFound && setSelected(true)
    prevSelected && !selectAll && setSelected(false)
  }, [selectAll])

  const clicked = () => {
    const arr = devices ? devices : []

    const isFound = arr.some((element) => {
      if (element === id) {
        return true
      }
      return false
    })
    if (isFound && selected) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] === id) {
          const oldArr = oldDevices ? [...oldDevices] : []
          oldArr.push(id)
          setOldDevices && setOldDevices(oldArr)
          arr.splice(i, 1)
          setDevices && setDevices([...arr])
        }
      }
      selectCellClick && selectCellClick(arr)
    }

    if (!isFound && !selected) {
      arr.push(id)
      setDevices && setDevices([...arr])
      selectCellClick && selectCellClick(arr)
    }
    setSelected(!selected)
  }

  return (
    <StyledSelectColumn>
      <StyledCheckboxField
        onChange={clicked}
        type="checkbox"
        className={className}
        id={id}
        checked={disabled ? disabled : selected}
      />
    </StyledSelectColumn>
  )
}

export default RowEntryAction
