import PropTypes from "prop-types"
import { Component } from "react"

const canUseDOM = () => {
  return (
    typeof window !== "undefined" &&
    !!window.document &&
    !!window.document.createElement
  )
}

const ZendeskAPI = (...args) => {
  // eslint-disable-next-line max-len
  // DOCS: https://developer.zendesk.com/api-reference/widget-messaging/web/core/#custom-launcher
  if (canUseDOM() && window.zE) {
    window.zE.apply(null, args)
  } else {
    console.warn("Zendesk is not initialized yet")
  }
}

class Zendesk extends Component {
  constructor(props) {
    super(props)
    this.insertScript = this.insertScript.bind(this)
    this.onScriptLoaded = this.onScriptLoaded.bind(this)
  }

  onScriptLoaded() {
    const { onLoaded } = this.props
    if (typeof onLoaded === "function") {
      onLoaded()
    }
  }

  insertScript(zendeskKey, defer) {
    const script = document.createElement("script")
    if (defer) {
      script.defer = true
    } else {
      script.async = true
    }
    script.id = "ze-snippet"
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`
    script.addEventListener("load", this.onScriptLoaded)
    document.body.appendChild(script)
    ZendeskAPI("messenger", "show")
  }

  componentDidMount() {
    const { defer, zendeskKey, ...other } = this.props
    if (canUseDOM() && !window.zE) {
      this.insertScript(zendeskKey, defer)
      window.zESettings = other
    }
  }

  componentWillUnmount() {
    if (canUseDOM() && window.zE) {
      ZendeskAPI("messenger", "hide")
      delete window.zE
      delete window.zESettings
    }
  }

  render() {
    return null
  }
}

Zendesk.propTypes = {
  zendeskKey: PropTypes.string.isRequired,
  defer: PropTypes.bool,
  onLoaded: PropTypes.func,
}

export { ZendeskAPI }
export default Zendesk
