import axios from "axios"
import Button from "components/Buttons/Button"
import { CardTable, StyledCardContainer } from "components/Card/Card.styled"
import TextField from "components/InfoPopup/TextField"
import ChargingStationConfigurationPopUp from "components/PopUp/ChargingStationConfigurationPopUp"
import PopUp from "components/PopUp/PopUp"
import { Box } from "components/Shared/Box.styled"
import { MainPaths } from "components/Shared/MainPaths"
import SubMenu from "components/SubMenu/SubMenu"
import Table from "components/Table/Table"
import { Chargepoint, TableRows } from "components/types/types"
import { useAppContext } from "helpers/AppProvider"
import { TableProvider } from "helpers/TableProvider"
import { GatewayTable } from "helpers/TableStructures"
import { useUserContext } from "helpers/UserContextProvider"
import { updateConnectionStatusBasedOnDuration } from "helpers/utils"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

interface Props {
  id: string
}

const ChargingStationsMain = ({ id }: Props) => {
  const [searchString, setSearchString] = useState("")
  const [search, setSearch] = useState("")
  const [pageSizeChargePoints, setPageSizeChargePoints] = useState(10)
  const [pageNumberChargePoints, setPageNumberChargePoints] = useState(1)
  const [totalSizeChargePoints, setTotalSizeChargePoints] = useState(1)
  const [totalPageChargePoints, setTotalPageChargePoints] = useState(1)
  const [chargepointData, setChargepointData] = useState<Chargepoint[]>([])
  const [changed, setChanged] = useState(false)
  const { userInfo } = useUserContext()
  const isAdmin = userInfo.role === "admin"

  const [loadingChargePoints, setLoadingChargingStations] = useState(true)
  const { t } = useTranslation()
  const { token } = useAppContext()
  const [showDetailsId, setShowDetailsId] = useState("")
  const detailsCps =
    showDetailsId !== "" &&
    chargepointData.filter((cp) => cp.id === showDetailsId)[0]
  const hasDetails =
    detailsCps &&
    detailsCps?.vendorInfo &&
    Object.values(detailsCps.vendorInfo).filter((v) => v != "").length > 0

  const chargingStations = () => {
    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/chargingPoints/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setTotalSizeChargePoints(Number(res.headers["pagination-total"]))
        setTotalPageChargePoints(Number(res.headers["pagination-totalpages"]))
        const mapped = isAdmin
          ? res.data
          : res.data.map(updateConnectionStatusBasedOnDuration)
        setChargepointData(mapped)
        setLoadingChargingStations(false)
      })
      .catch((err) => {
        console.log(err)
        setLoadingChargingStations(false)
      })
  }

  useEffect(() => {
    setChanged(false)
    chargingStations()
    const interval = setInterval(() => {
      chargingStations()
    }, 20000)
    return () => clearInterval(interval)
  }, [token, changed])

  return (
    <>
      <SubMenu items={MainPaths()} id={id} main={true} />
      <StyledCardContainer>
        <CardTable>
          <TableProvider
            searchString={setSearchString}
            search={setSearch}
            searchValue={searchString}
            priority={false}
            showAdjust={true}
            double={true}
            power={true}
            gwId={true}
            notification={true}
            popUpData={(entry: TableRows) => (
              <ChargingStationConfigurationPopUp
                setChanged={setChanged}
                entry={entry as Chargepoint}
              />
            )}
            detailsAction
            showDetails={setShowDetailsId}
          >
            <Box margin="0 0 40px 0">
              <Table
                layout={GatewayTable}
                data={
                  chargepointData
                    .filter((cp) => cp.id.includes(searchString))
                    .slice(
                      (pageNumberChargePoints - 1) * pageSizeChargePoints,
                      pageNumberChargePoints * pageSizeChargePoints
                    ) as TableRows[] as TableRows[]
                }
                loading={loadingChargePoints}
                toolbar={true}
                title={t("Charging Stations")}
                pageSize={pageSizeChargePoints}
                setPageSize={setPageSizeChargePoints}
                pageNumber={pageNumberChargePoints}
                setPageNumber={setPageNumberChargePoints}
                totalSize={chargepointData.length}
                totalPage={Math.ceil(
                  chargepointData.length / pageSizeChargePoints
                )}
              />
            </Box>
          </TableProvider>
        </CardTable>
      </StyledCardContainer>
      {showDetailsId !== "" && detailsCps && (
        <PopUp
          setShow={(val) => !val && setShowDetailsId("")}
          title={"Charging station details"}
          noConfig
        >
          {hasDetails ? (
            <Box direction="column">
              <TextField
                label={t("Box Serial number")}
                data={detailsCps?.vendorInfo?.chargeBoxSerialNumber}
              />
              <TextField
                label={t("Model")}
                data={detailsCps?.vendorInfo?.chargePointModel}
              />
              <TextField
                label={t("Firmware Version")}
                data={detailsCps?.vendorInfo?.firmwareVersion}
              />
              <TextField
                label={t("Charging Point Serial Number")}
                data={detailsCps?.vendorInfo?.chargePointSerialNumber}
              />
              <TextField
                label={t("Vendor")}
                data={detailsCps?.vendorInfo?.chargePointVendor}
              />
              <TextField
                label={t("ICCID")}
                data={detailsCps?.vendorInfo?.iccid}
              />
              <TextField
                label={t("IMSI")}
                data={detailsCps?.vendorInfo?.imsi}
              />
              <TextField
                label={t("Meter Serial Number")}
                data={detailsCps?.vendorInfo?.meterSerialNumber}
              />
              <TextField
                label={t("Meter Type")}
                data={detailsCps?.vendorInfo?.meterType}
              />
            </Box>
          ) : (
            <Box justify="center" align="center">
              <p>No details</p>
            </Box>
          )}
          <hr></hr>
          <Box justify="flex-end">
            <Button
              secondary={true}
              onClick={() => setShowDetailsId("")}
              text={t("Dismiss")}
            />
          </Box>
        </PopUp>
      )}
    </>
  )
}

export default ChargingStationsMain
