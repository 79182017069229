import styled, { css } from "styled-components"
import { black, primary, red } from "styles/colors"
import { big, medium, semibold, xsmall } from "styles/fonts"

type Size = "small" | "regular" | "big"

interface Props {
  size?: Size
  padding?: string
  blue?: boolean
  error?: boolean
}
/* eslint-disable indent */
export const Label = styled.label<Props>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(props) =>
    props.size === "small"
      ? css`
          font-size: ${xsmall};
        `
      : props.size === "big"
      ? css`
          font-size: ${big};
        `
      : css`
          font-size: ${medium};
        `};

  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `};

  font-weight: ${semibold};

  ${(props) =>
    props.blue
      ? css`
          color: ${primary};
        `
      : css`
          color: ${black};
        `};

  ${(props) =>
    props.error
      ? css`
          color: ${red};
        `
      : css`
          color: ${black};
        `};
`
