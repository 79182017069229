/* eslint-disable indent */
import { CardTable, StyledCardContainer } from "components/Card/Card.styled"
import { FeCBrainIcon } from "components/Icons/FeCBrain"
import { Box } from "components/Shared/Box.styled"
import { SubMenuItem } from "components/SubMenu/SubMenu.styled"
import Table from "components/Table/Table"
import { TableRows } from "components/types/types"
import { TableProvider } from "helpers/TableProvider"
import { ChargepointTable } from "helpers/TableStructures"
import { useUserContext } from "helpers/UserContextProvider"
import { updateConnectionStatusBasedOnDuration } from "helpers/utils"
import _ from "lodash"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { primaryDarker } from "styles/colors"

interface Props {
  offline?: boolean
  cps: any[]
  loadingChargePoints: boolean
  subclusters: {
    id: string
    name?: string
    subclusters?: string[]
    devices: string[]
  }[]
}

const separateConnectors = (cp: any) => {
  const connectors = cp.config.connectorNum
  return _.range(connectors).map((i) => ({
    ...cp,
    id: `${cp.id}`,
    connector: i + 1,
    connection:
      typeof cp.connection === "object" ? cp.connection[0] : cp.connection,
    cpState: [cp.cpState ? cp.cpState[i] : "init"],
    allocated: [cp.allocated ? cp.allocated[i] : { l1: 0, l2: 0, l3: 0 }],
    actual: [cp.actual ? cp.actual[i] : { l1: 0, l2: 0, l3: 0 }],
    reason: [cp.reason ? cp.reason[i] : ""],
    adaptiveChargingStatus:
      cp.adaptiveChargingStatus && cp.adaptiveChargingStatus[i]
        ? cp.adaptiveChargingStatus[i]
        : "disabled",
    advancedAdaptiveChargingStatus:
      cp.advancedAdaptiveChargingStatus && cp.advancedAdaptiveChargingStatus[i]
        ? cp.advancedAdaptiveChargingStatus[i]
        : "disabled",
    config: {
      ...cp.config,
      connectorId: i,
      priority: [cp.config.priority ? cp.config.priority[i] : 0],
      physicalLimit: [cp.config.physicalLimit ? cp.config.physicalLimit[i] : 0],
      descriptions: [cp.config.descriptions ? cp.config.descriptions[i] : ""],
    },
  }))
}

const Chargepoints = ({
  offline,
  cps,
  loadingChargePoints,
  subclusters,
}: Props) => {
  const [searchString, setSearchString] = useState("")
  const [search, setSearch] = useState("")
  const [pageSizeChargePoints, setPageSizeChargePoints] = useState(10)
  const [pageNumberChargePoints, setPageNumberChargePoints] = useState(1)
  const [showSubcluster, setShowSubcluster] = useState<string>("main")
  const { userInfo } = useUserContext()
  const isAdmin = userInfo.role === "admin"
  const chargepointData = loadingChargePoints
    ? []
    : cps
        .flatMap((cp) => separateConnectors(cp))
        .map(isAdmin ? (i) => i : updateConnectionStatusBasedOnDuration)
  const showAcStatus = chargepointData.some(
    (cp) =>
      cp.advancedAdaptiveChargingStatus !== "disabled" ||
      cp.adaptiveChargingStatus !== "disabled"
  )
  const subclusterConnectors = Object.fromEntries(
    subclusters.map((s) => [
      s.id,
      chargepointData.filter((cp) => s.devices.includes(cp.id)),
    ])
  )
  subclusterConnectors["main"] = chargepointData
  const { t } = useTranslation()

  return (
    <>
      <StyledCardContainer offline={offline}>
        <Box style={{ flexDirection: "row", justifyContent: "flex-start" }}>
          <SubMenuItem
            key={"cps-subcluster-main-tab"}
            active={showSubcluster === "main"}
            onClick={() => setShowSubcluster("main")}
          >
            {`Main Cluster (${chargepointData.length})`}
          </SubMenuItem>
          {subclusters.map((s) => (
            <SubMenuItem
              key={`cps-subcluster-${s.id}-tab`}
              active={showSubcluster === s.id}
              onClick={() => setShowSubcluster(s.id)}
            >
              {`${s.name || s.name} (${subclusterConnectors[s.id].length})`}
            </SubMenuItem>
          ))}
        </Box>
        <CardTable>
          <TableProvider
            searchString={setSearchString}
            search={setSearch}
            searchValue={searchString}
            priority={false}
            noAction
          >
            <Box margin="0 0 40px 0">
              <Table
                layout={ChargepointTable(showAcStatus)}
                data={
                  subclusterConnectors[showSubcluster]
                    .filter((cp) => cp.id.includes(searchString))
                    .slice(
                      (pageNumberChargePoints - 1) * pageSizeChargePoints,
                      pageNumberChargePoints * pageSizeChargePoints
                    ) as TableRows[]
                }
                loading={loadingChargePoints}
                toolbar={true}
                title={t("Chargepoints")}
                pageSize={pageSizeChargePoints}
                setPageSize={setPageSizeChargePoints}
                pageNumber={pageNumberChargePoints}
                setPageNumber={setPageNumberChargePoints}
                totalSize={subclusterConnectors[showSubcluster].length}
                totalPage={Math.ceil(
                  chargepointData.length / pageSizeChargePoints
                )}
                legend={
                  showAcStatus
                    ? [
                        {
                          icon: <FeCBrainIcon background="#33B905" />,
                          description: "Adaptive Charging Active",
                        },
                        {
                          icon: <FeCBrainIcon background={primaryDarker} />,
                          description: "Adaptive Charging Inactive",
                        },
                      ]
                    : undefined
                }
              />
            </Box>
          </TableProvider>
        </CardTable>
      </StyledCardContainer>
    </>
  )
}

export default Chargepoints
