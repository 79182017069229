import ButtonIcon from "components/Buttons/ButtonIcon"
import { Plus } from "components/Icons/Plus"
import PopUp from "components/PopUp/PopUp"
import SubLevelConfigurationPopup from "components/PopUp/SubLevelConfigurationPopup"
import { Box } from "components/Shared/Box.styled"
import { ClusterConfigsType } from "components/types/types"
import { useTreeContext } from "helpers/TreeProvider"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import {
  StyledTree,
  SubContainer,
  TreeBody,
  TreeFooter,
  TreeHeader,
} from "./Tree.styled"
import { TreeMain } from "./TreeMain"
import { TreeSub } from "./TreeSub"

interface Props {
  payload: ClusterConfigsType
  id: string
}

const Tree = ({ payload, id }: Props) => {
  const [show, setShow] = useState(false)
  const { openClusters } = useTreeContext()
  const { t } = useTranslation()

  return (
    <StyledTree>
      <TreeHeader>
        <Box direction="row" width={20}>
          {t("Supply System Levels")}
        </Box>
        <Box direction="column" align="center" width={30}>
          <p>{t("Maximum Current Limit (A)")}</p>
        </Box>
        <Box direction="row" width={40}>
          {/* {" "}
          <p>{t("Distribution Scheme")}</p> */}
        </Box>
        <Box direction="row" width={10}></Box>
      </TreeHeader>
      <TreeBody>
        <TreeMain
          open={openClusters?.indexOf(100) !== -1}
          id={id}
          payload={payload}
        />
        <SubContainer>
          {payload?.subclusters?.map((cluster, i) => (
            <TreeSub
              id={id}
              mainClusterId={payload.id}
              cluster={cluster}
              timezone={payload.staticSchedule?.timezone}
              priority={true}
              index={i}
              open={openClusters.indexOf(i) !== -1}
              key={i}
            />
          ))}
        </SubContainer>
      </TreeBody>
      <TreeFooter>
        <ButtonIcon
          onClick={() => setShow(!show)}
          icon={<Plus />}
          text={<p>{t("Sub-Level")}</p>}
        />
      </TreeFooter>
      {show && (
        <PopUp title={t("Sub Level")} setShow={setShow}>
          <SubLevelConfigurationPopup
            mainClusterId={payload.id}
            post={true}
            id={id}
          />
        </PopUp>
      )}
    </StyledTree>
  )
}

export default Tree
