import axios from "axios"
import ButtonIcon from "components/Buttons/ButtonIcon"
import { Adjust } from "components/Icons/Adjust"
import { ArrowDown } from "components/Icons/ArrowDown"
import { Cross } from "components/Icons/Cross"
import PopUp from "components/PopUp/PopUp"
import SubLevelConfigurationPopup from "components/PopUp/SubLevelConfigurationPopup"
import { Box } from "components/Shared/Box.styled"
import { SubClusterType } from "components/types/types"
import { useAppContext } from "helpers/AppProvider"
import { toastHandler } from "helpers/helpers"
import { useTreeContext } from "helpers/TreeProvider"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { secondaryLight } from "styles/colors"
import { xsmall } from "styles/fonts"

import { SubFirst, SubPoint } from "./Tree.styled"
import { TreeDropdownChargepoints } from "./TreeDropdownChargepoints"
import { TreeDropdownStaticSchedule } from "./TreeDropdownStaticSchedule"

interface Props {
  cluster: SubClusterType
  id: string
  mainClusterId: string
  timezone?: string
  priority: boolean
  open?: boolean
  index: number
}

export const TreeSub = ({
  cluster,
  id,
  mainClusterId,
  timezone,
  open,
  index,
}: Props) => {
  const { setOpenClusters, openClusters } = useTreeContext()

  const [show, setShow] = useState(open || false)
  const [showConfig, setShowConfig] = useState(false)

  const { t } = useTranslation()
  const { setChanged } = useTreeContext()
  const { toasts, setToasts, token } = useAppContext()

  const deleteTreeSub = () => {
    axios
      .delete(
        `${process.env.REACT_APP_API_ADDRESS}/clusterConfigs/${id}/${cluster.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        setChanged(true)
        toastHandler(toasts, setToasts, true)
      })
      .catch((err) => {
        console.log(err)
        toastHandler(toasts, setToasts, false)
      })
  }

  const setShowState = () => {
    const arr = openClusters

    if (show) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] === index) {
          arr.splice(i, 1)
        }
      }
    } else {
      arr.push(index)
    }

    setShow(!show)
    setOpenClusters(arr)
  }

  return (
    <>
      <SubPoint>
        <Box direction="row" align="center" width={20}>
          <ButtonIcon
            rotate={show}
            onClick={() => setShowState()}
            icon={<ArrowDown />}
          />
          <SubFirst>&#8594; {cluster?.name}</SubFirst>
        </Box>
        <Box direction="row" justify="space-around" width={30}>
          <p style={{ fontSize: xsmall }}>{cluster?.maximumCurrent}</p>
        </Box>
        <Box direction="row" width={40}></Box>
        <Box direction="row" width={10}>
          <ButtonIcon
            onClick={() => setShowConfig(!showConfig)}
            icon={<Adjust />}
          />
          <ButtonIcon onClick={() => deleteTreeSub()} icon={<Cross />} />
        </Box>
      </SubPoint>
      {show && (
        <span style={{ background: secondaryLight }}>
          <TreeDropdownChargepoints clusterId={cluster.id} gwId={id} />
          <TreeDropdownStaticSchedule
            gwId={id}
            clusterId={cluster.id}
            schedule={cluster.staticSchedule?.schedule}
            timezone={timezone}
          />
        </span>
      )}
      {showConfig && (
        <PopUp title={t("Supply Level")} setShow={setShowConfig}>
          <SubLevelConfigurationPopup
            id={id}
            mainClusterId={mainClusterId}
            entry={cluster}
          />
        </PopUp>
      )}
    </>
  )
}
