import ButtonIcon from "components/Buttons/ButtonIcon"
import { Clear } from "components/Icons/Clear"
import { Failure } from "components/Icons/Failure"
import { Success } from "components/Icons/Success"
import { FlexCol } from "components/Shared/FlexCol"
import { Alerts } from "components/types/types"
import { useAppContext } from "helpers/AppProvider"
import React, { useEffect, useState } from "react"
import { xxsmall } from "styles/fonts"

import { StyledToast, ToastIcon } from "./Toast.styled"

interface Props {
  type: Alerts
  date: string
  index: number
  customMessage?: string
}

const Toast = ({ type, index, date, customMessage }: Props) => {
  const { toasts, setToasts } = useAppContext()

  const [counter, setCounter] = useState(0)
  const [counterPosition, setCounterPosition] = useState(0)

  let number = 0
  useEffect(() => {
    const interval = setInterval(() => {
      if (number < 100) {
        number++
        number++
        setCounter(number)
      }

      if (number < 50) {
        number++
        number++
        setCounterPosition(number)
      }
      if (number === 100) {
        clearInterval(interval)
      }
    }, 10)
    return () => clearInterval(interval)
  }, [])

  const deleteToast = () => {
    const arr = toasts
    arr.splice(index, 1)
    setToasts([...arr])
  }

  return (
    <StyledToast
      customMessage={customMessage !== undefined}
      style={{
        opacity: counter / 100,
        marginBottom: -40 + counterPosition + "px",
        marginTop: 48 - counterPosition + "px",
      }}
      type={type}
    >
      <ToastIcon type={type}>
        {type === "failure" ? <Success /> : <Failure />}
      </ToastIcon>
      <FlexCol>
        {!customMessage ? (
          <span>
            {type === "success"
              ? "Action completed successfully"
              : "Action failed"}
          </span>
        ) : (
          <span>{"Action failed: " + customMessage}</span>
        )}
        <span style={{ fontSize: xxsmall }}>{date}</span>
      </FlexCol>

      <ButtonIcon onClick={() => deleteToast()} icon={<Clear />} />
    </StyledToast>
  )
}

export default Toast
