import axios from "axios"
import ButtonIcon from "components/Buttons/ButtonIcon"
import { Adjust } from "components/Icons/Adjust"
import { Bell } from "components/Icons/Bell"
import { Cross } from "components/Icons/Cross"
import { Eye } from "components/Icons/Eye"
import { Graph } from "components/Icons/Graph"
import { Open } from "components/Icons/Open"
import { Power } from "components/Icons/Power"
import PopUp from "components/PopUp/PopUp"
import { Chargepoint } from "components/types/types"
import { useAppContext } from "helpers/AppProvider"
import { toastHandler } from "helpers/helpers"
import { useTableContext } from "helpers/TableProvider"
import React, { useState } from "react"

import { StyledActions } from "./Table.styled"

interface Props {
  entry: Chargepoint
}

const TableActions = ({ entry }: Props) => {
  const [show, setShow] = useState(false)
  const [restarted, setRestarted] = useState(false)
  const [booted, setBooted] = useState(false)
  const {
    graphAction,
    deleteAction,
    gwId,
    showAdjust,
    openAction,
    popUpData,
    setChanged,
    double,
    notification,
    power,
    detailsAction,
    showDetails,
  } = useTableContext()

  const { token, toasts, setToasts } = useAppContext()

  const deletechargingStation = () => {
    axios
      .delete(
        `${process.env.REACT_APP_API_ADDRESS}/chargingPoints/${entry.gwId}/${entry.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        toastHandler(toasts, setToasts, true)
      })
      .catch((err) => {
        toastHandler(toasts, setToasts, false, err.response.data)
        console.log(err)
      })
      .finally(() => {
        setChanged && setChanged(true)
      })
  }
  const sendNotification = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_ADDRESS}/chargingPoints/${entry.gwId}/${entry.id}/bootNotification`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        toastHandler(toasts, setToasts, true)
        setBooted(true)
      })
      .catch((err) => {
        toastHandler(toasts, setToasts, false, err.message)
        console.log(err)
      })

    setTimeout(() => {
      setBooted(false)
    }, 30000)
  }

  const restart = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_ADDRESS}/chargingPoints/${entry.gwId}/${entry.id}/restart`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        setRestarted(true)
        toastHandler(toasts, setToasts, true)
      })
      .catch((err) => {
        toastHandler(toasts, setToasts, false, err.message)
        console.log(err)
      })
    setTimeout(() => {
      setRestarted(false)
    }, 30000)
  }

  const graph = () => {
    if (graphAction) {
      return <ButtonIcon onClick={() => graphAction()} icon={<Graph />} />
    }
  }

  const open = () => {
    if (openAction) {
      return <ButtonIcon onClick={() => openAction(entry.id)} icon={<Open />} />
    }
  }

  const deleteButton = () => {
    if (deleteAction) {
      return (
        <ButtonIcon
          onClick={() => deleteAction(entry.id)}
          icon={<Cross />}
          hoverText={"Remove / Delete"}
        />
      )
    }
    if (gwId) {
      return (
        <ButtonIcon
          onClick={() => deletechargingStation()}
          icon={<Cross />}
          hoverText={"Remove / Delete the charging station"}
        />
      )
    }
  }

  const notificationButton = () => {
    if (notification) {
      return (
        <ButtonIcon
          onClick={() => sendNotification()}
          icon={<Bell blinking={booted} />}
          disabled={booted}
          hoverText={"Request a Boot Notification from the charging station"}
        />
      )
    }
  }

  const detailsButton = () => {
    if (detailsAction) {
      return (
        <ButtonIcon
          onClick={() => showDetails && showDetails(entry.id)}
          icon={<Eye />}
          disabled={booted}
          hoverText={"Show the charging station details"}
        />
      )
    }
  }

  const powerButton = () => {
    if (power) {
      return (
        <ButtonIcon
          onClick={() => restart()}
          icon={<Power blinking={restarted} />}
          disabled={restarted}
          hoverText={"Request a restart (Soft-Reset)"}
        />
      )
    }
  }

  return (
    <td>
      <StyledActions>
        {graph()}
        {showAdjust && (
          <ButtonIcon
            onClick={() => setShow(!show)}
            icon={<Adjust />}
            hoverText={"Configure"}
          />
        )}
        {open()}
        {detailsButton()}
        {notificationButton()}
        {powerButton()}
        {deleteButton()}
      </StyledActions>
      {show && (
        <PopUp double={double} title={""} setShow={setShow}>
          {popUpData && popUpData(entry)}
        </PopUp>
      )}
    </td>
  )
}

export default TableActions
