import { Chart } from "components/Icons/Chart"
import { User } from "components/Icons/User"
import { useUserContext } from "helpers/UserContextProvider"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link, Path, useLocation } from "wouter"

import { List, SideMenuListItem, StyledSideMenu } from "./SideMenu.styled"

type SideMenuItem = {
  href: string
  active: (
    location: [Path, (to: Path, options?: { replace?: boolean }) => void]
  ) => boolean
  icon: React.ReactNode
  text: string
}

const allUsersSideMenuItems: SideMenuItem[] = [
  {
    href: "/userSettings",
    active: (location) => location[0] === "/userSettings",
    icon: <User />,
    text: "User Settings",
  },
]

const groupAdminMenuItems: SideMenuItem[] = [
  {
    href: "/userManagement",
    active: (location) => location[0] === "/userManagement",
    icon: <User />,
    text: "User Management",
  },
]

const signedUserMenuItems: SideMenuItem[] = [
  {
    href: "/infrastructure",
    active: (location) => location[0].indexOf("infrastructure") === 1,
    icon: <Chart />,
    text: "Infrastructures",
  },
]

const SideMenu = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { userInfo } = useUserContext()
  const items: SideMenuItem[] = [
    ...(userInfo.approved ? signedUserMenuItems : []),
    ...(userInfo.groups[0]?.role === "admin" ? groupAdminMenuItems : []),
    ...allUsersSideMenuItems,
  ]
  return (
    <StyledSideMenu>
      <List>
        {items.map((item, i) => (
          <Link href={item.href} key={i}>
            <SideMenuListItem active={item.active(location)}>
              <div>
                {item.icon}
                <p>{t(item.text)}</p>
              </div>
            </SideMenuListItem>
          </Link>
        ))}
      </List>
    </StyledSideMenu>
  )
}

export default SideMenu
