import { Box } from "components/Shared/Box.styled"
import { Label } from "components/Shared/Label.styled"
import React, { KeyboardEvent, useState } from "react"

import { StyledInputField } from "./InputField.styled"

interface Props {
  type: string
  label?: string
  placeholder?: string
  width?: number
  innerRef?: React.MutableRefObject<HTMLInputElement | HTMLInputElement | null>
  value?: string | number
  disabled?: boolean
  onChange?: () => void
  className?: string
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
  min?: number
  id?: string
}
const InputField = ({
  type,
  label,
  placeholder,
  width,
  innerRef,
  value,
  disabled,
  onChange,
  className,
  onKeyDown,
  min,
  id,
}: Props) => {
  const [inputValue, setInputValue] = useState(value)

  const onChangeEvent = (value: string | number) => {
    onChange && onChange()
    setInputValue(value)
  }

  return (
    <Box direction="column" width={width}>
      {label && (
        <Label size="small" style={{ textAlign: "left" }}>{`${label}`}</Label>
      )}
      <StyledInputField
        min={min}
        className={className}
        disabledStyle={disabled}
        ref={innerRef}
        placeholder={placeholder}
        type={type}
        value={inputValue}
        onKeyDown={onKeyDown}
        onChange={(event) => onChangeEvent(event.target.value)}
        id={id}
      />
    </Box>
  )
}

export default InputField
