import { Label } from "components/Shared/Label.styled"
import { PopUpProvider } from "helpers/PopupProvider"
import React, { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"

import { StyledOverlay, StyledPopUp } from "./PopUp.styled"

interface Props {
  title?: string
  children: React.ReactNode
  setShow: Dispatch<SetStateAction<boolean>>
  double?: boolean
  noConfig?: boolean
}

const PopUp = ({ children, title, setShow, double, noConfig }: Props) => {
  const { t } = useTranslation()
  return (
    <StyledOverlay>
      <StyledPopUp double={double}>
        <PopUpProvider setShow={setShow}>
          <Label size="big" blue={true}>{`${title} ${
            !noConfig ? t("Configuration") : ""
          }`}</Label>
          <hr />
          {children}
        </PopUpProvider>
      </StyledPopUp>
    </StyledOverlay>
  )
}

export default PopUp
