import { ButtonStyle } from "components/Buttons/Button.styled"
import { ArrowDown } from "components/Icons/ArrowDown"
import { useState } from "react"
import React from "react"
import styled, { css } from "styled-components"
import { lightGray, primary, white } from "styles/colors"
import { bold, regular } from "styles/fonts"
import { radius } from "styles/sizes"

interface StyledProps {
  width?: string
  margin?: string
  rotate?: boolean
  active?: boolean
  wide?: boolean
}

const StyledDropdown = styled(ButtonStyle)<StyledProps>`
  background: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover svg path {
    fill: ${primary};
  }

  ${(props) =>
    props.rotate &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `};

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  padding: 10px;
  border-top-left-radius: ${radius};
  border-top-right-radius: ${radius};
  font-size: ${regular};
  font-weight: ${bold};
  background-color: ${lightGray};
`
export const StyledDropDownContent = styled.div`
  background-color: ${white};
  border: 1px solid ${lightGray};
  padding: 10px;
  display: flex;
  flex-direction: column;
`
interface Props {
  title: string
  children?: React.ReactNode
}
const Dropdown = ({ title, children }: Props) => {
  const [show, setShow] = useState(false)

  return (
    <>
      <StyledDropdown onClick={() => setShow(!show)} rotate={show}>
        {title}
        <ArrowDown small={true} />
      </StyledDropdown>
      {show && <StyledDropDownContent>{children}</StyledDropDownContent>}
    </>
  )
}

export default Dropdown
