import axios from "axios"
import Button from "components/Buttons/Button"
import Dropdown from "components/InfoPopup/Dropdown"
import TextField from "components/InfoPopup/TextField"
import PopUp from "components/PopUp/PopUp"
import { Box } from "components/Shared/Box.styled"
import Table from "components/Table/Table"
import { TableLayout } from "components/types/types"
import { useAppContext } from "helpers/AppProvider"
import { toastHandler } from "helpers/helpers"
import { TableProvider } from "helpers/TableProvider"
import { useUserContext } from "helpers/UserContextProvider"
import React, { Dispatch, SetStateAction, useState } from "react"

const leasesTableLayout: TableLayout[] = [
  {
    title: "IP",
    data: "ip",
    width: 100,
    type: "",
  },
  {
    title: "MAC",
    data: "mac",
    width: 100,
    type: "",
  },
  {
    title: "Host Name",
    data: "hostName",
    width: 150,
    type: "",
  },
  {
    title: "Client ID",
    data: "clientId",
    width: 100,
    type: "",
  },
  {
    title: "Lease Expiry",
    data: "leaseExpiry",
    width: 100,
    type: "",
  },
]

export const IpScannerModal = ({
  setShow,
  ipInfo,
  gwId,
}: {
  setShow: Dispatch<SetStateAction<boolean>>
  ipInfo: any
  gwId: string
}) => {
  const [loading, setLoading] = useState(false)
  const { toasts, setToasts, token } = useAppContext()
  const { userInfo } = useUserContext()
  const isAdmin = userInfo.role === "admin"

  const scanRequest = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_ADDRESS}/gateways/${gwId}/sendMessage/networkScan`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        toastHandler(toasts, setToasts, true)
      })
      .catch((err) => {
        console.log(err)
        toastHandler(toasts, setToasts, false)
      })
  }

  const performScan = async () => {
    setLoading(true)
    await scanRequest()
    setTimeout(() => {
      setLoading(false)
    }, 30000)
  }

  const osRelease = ipInfo.osRelease
  return (
    <PopUp setShow={setShow} title="Network Scan Information" noConfig double>
      <Box
        direction="column"
        margin="10px 10px 10px 10px"
        style={{ maxHeight: "500px", overflowY: "scroll" }}
      >
        <TableProvider noTitle={true} noAction={true}>
          <Table
            data={ipInfo.leases}
            layout={leasesTableLayout}
            title={"Leases"}
            toolbar={false}
            loading={loading}
          />
        </TableProvider>
        {loading ? (
          <p style={{ margin: "0" }}> This can take up to 30 seconds</p>
        ) : (
          !ipInfo && (
            <p style={{ margin: "10 10 10 10" }}>
              No IP information available. Please perform a scan.
            </p>
          )
        )}
      </Box>
      {isAdmin ? (
        <>
          <br />
          <Box
            direction="column"
            margin="10px 10px 10px 10px"
            style={{
              maxHeight: "500px",
              overflowY: "scroll",
              marginTop: "20px",
            }}
          >
            <TextField label="ipEth" data={ipInfo.ipEth} />
            <TextField label="IP Tunel" data={ipInfo.ipTun} />
            <TextField label="uName" data={ipInfo.uName} />{" "}
            <Dropdown title="OS Release">
              <TextField label="Name" data={osRelease.Name} />
              <TextField label="Version" data={osRelease.Version} />
              <TextField label="ID" data={osRelease.ID} />
              <TextField label="Version_Id" data={osRelease.Version_Id} />
              <TextField label="Pretty_Name" data={osRelease.Pretty_Name} />
              <TextField
                label="PtxDist_Version"
                data={osRelease.PtxDist_Version}
              />
              <TextField
                label="PtxDist_Bsp_Vendor"
                data={osRelease.PtxDist_Bsp_Vendor}
              />
              <TextField
                label="PtxDist_Bsp_Name"
                data={osRelease.PtxDist_Bsp_Name}
              />
              <TextField
                label="PtxDist_Bsp_Version"
                data={osRelease.PtxDist_Bsp_Version}
              />
              <TextField
                label="PtxDist_Platform_Name"
                data={osRelease.PtxDist_Platform_Name}
              />
              <TextField
                label="PtxDist_Platform_Version"
                data={osRelease.PtxDist_Platform_Version}
              />
              <TextField
                label="PtxDist_Build_Date"
                data={osRelease.PtxDist_Build_Date}
              />
            </Dropdown>
            <Dropdown title="Modems Info">
              {ipInfo.modemsInfo.map((networkDetails: any) => (
                <>
                  <TextField label="Path" data={networkDetails.path} />
                  <TextField
                    label="Signal Quality (Recent)"
                    data={networkDetails.signalQuality.recent}
                    renderCondition={(val: string) => val !== "no"}
                  />
                  <TextField
                    label="Signal Quality (Value)"
                    data={networkDetails.signalQuality.value}
                  />
                  <TextField label="State" data={networkDetails.state} />
                  <TextField
                    label="State Failed Reason"
                    data={networkDetails.stateFailedReason}
                  />

                  <br />
                </>
              ))}
            </Dropdown>
          </Box>
        </>
      ) : (
        <></>
      )}
      <Box justify="flex-end" padding="20px 0 0 0 ">
        <Button
          secondary={true}
          onClick={() => setShow(false)}
          text={"Cancel"}
        />
        <Button
          margin="0 0 0 20px"
          onClick={() => performScan()}
          text={"Perform Scan"}
          disabled={loading}
        />
      </Box>
    </PopUp>
  )
}

export default IpScannerModal
