import { useUserContext } from "helpers/UserContextProvider"
import { useTranslation } from "react-i18next"

export const MainPaths = () => {
  const { t } = useTranslation()
  const { userInfo } = useUserContext()
  const isManaged = userInfo.groups[0]?.role === "managed"

  return [
    {
      name: t("Infrastructure Dashboard"),
      value: "dashboard",
    },
    !isManaged && {
      name: t("Charging Stations"),
      value: "chargingStations",
    },
    {
      name: t("Powermeters"),
      value: "powermeters",
    },
    {
      name: t("Monitoring"),
      value: "monitoring",
    },
    !isManaged && {
      name: t("Configuration"),
      value: "configuration",
    },
    !isManaged && {
      name: t("Notifications and Statuses"),
      value: "notifications",
    },
  ].filter((v) => !!v) as { name: string; value: string }[]
}
