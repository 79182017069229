import { TableEntryType } from "components/types/types"
import React from "react"

import { StyledBoldRow } from "./Row.styled"

interface Props extends TableEntryType {
  text: string
}

const BoldRow = ({ text }: Props) => (
  <StyledBoldRow>
    <p>{text}</p>
  </StyledBoldRow>
)

export default BoldRow
