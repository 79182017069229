import React from "react";

export const Mfa = () => (
  <svg
  viewBox="0 0 512 512"
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  fill="#9A9AB0"
>
  <g>
    <path
      d="M256,0C166.3,0,96,70.3,96,160v112h-16c-26.5,0-48,21.5-48,48v128c0,26.5,21.5,48,48,48h320 c26.5,0,48-21.5,48-48V348c0-26.5-21.5-48-48-48h-16V160C416,70.3,345.7,0,256,0z M400,352H112V160c0-70.7,57.3-128,128-128 s128,57.3,128,128V352z M336,320H176v-48h48v-48h-48v-48h48V160c0-53,43-96,96-96s96,43,96,96V320z"
    />
  </g>
</svg>
)