import ButtonIcon from "components/Buttons/ButtonIcon"
import { Plus } from "components/Icons/Plus"
import React, { ReactNode } from "react"

import { StyledAddRow } from "./Table.styled"

interface Props {
  text: string
  addIcon: ReactNode
  onClick: () => void
}

const AddRow = ({ onClick, addIcon, text }: Props) => (
  <StyledAddRow>
    <ButtonIcon
      onClick={onClick}
      icon={addIcon ? addIcon : <Plus />}
      text={<p>{text}</p>}
    />
  </StyledAddRow>
)

export default AddRow
