import styled, { css } from "styled-components"
import { inputShadow, primary, white } from "styles/colors"

interface Props {
  double?: boolean
}

export const StyledOverlay = styled.div<Props>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
`

export const StyledPopUp = styled.div<Props>`
  position: fixed;
  left: 50%;
  top: 0;
  margin: 100px 0 0 -232px;
  ${(props) =>
    props.double &&
    css`
      margin: 100px 0 0 -464px;
    `};
  width: 464px;
  ${(props) =>
    props.double &&
    css`
      width: 928px;
    `};
  background: ${white};
  border: 1px solid ${primary};
  padding: 32px;
  box-shadow: ${inputShadow};
  border-radius: 20px;
  max-height: 650px;
  overflow-y: scroll;
`
