import axios from "axios"
import Button from "components/Buttons/Button"
import ButtonIcon from "components/Buttons/ButtonIcon"
import { CardTable, StyledCardContainer } from "components/Card/Card.styled"
import { DateInput } from "components/DateRangePicker/DateRangePicker"
import { Cross } from "components/Icons/Cross"
import Loader from "components/Loader/Loader"
import { Box } from "components/Shared/Box.styled"
import { FlexCol } from "components/Shared/FlexCol"
import { FlexRow } from "components/Shared/FlexRow"
import { Label } from "components/Shared/Label.styled"
import { MainPaths } from "components/Shared/MainPaths"
import SubMenu from "components/SubMenu/SubMenu"
import { SubMenuItem } from "components/SubMenu/SubMenu.styled"
import Row from "components/Table/Rows/Row"
import { StyledDateTimeRow, StyledRow } from "components/Table/Rows/Row.styled"
import Table from "components/Table/Table"
import {
  StyledColumn,
  StyledTableRow,
  TableContainer,
  TableHead,
  TableStyled,
} from "components/Table/Table.styled"
import {
  Gateway,
  TableFilters,
  TableLayout,
  TableRows,
} from "components/types/types"
import { useAppContext } from "helpers/AppProvider"
import { mapServiceName, toastHandler } from "helpers/helpers"
import { TableProvider } from "helpers/TableProvider"
import { useUserContext } from "helpers/UserContextProvider"
import { t } from "i18next"
import _ from "lodash"
import moment from "moment"
import React, { useEffect, useState } from "react"
import {
  available,
  charging,
  faulted,
  finishing,
  preparing,
  red,
  suspendedEV,
  suspendedEVSE,
  unavailable,
} from "styles/colors"
import { semibold, xsmall } from "styles/fonts"

interface Props {
  id: string
}

type Status = {
  timestamp: Date
  status: string
  chargingPointId: string
  connectorId: number
}

const today = () => {
  const d = new Date()
  let month = "" + (d.getMonth() + 1) // Months are zero indexed
  let day = "" + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) {
    month = "0" + month
  }
  if (day.length < 2) {
    day = "0" + day
  }

  return [year, month, day].join("-")
}

const stateColorFomatter = (text: string) => {
  if (text === "Available") {
    return available
  } else if (text === "Preparing") {
    return preparing
  } else if (text === "Charging") {
    return charging
  } else if (text === "Finishing") {
    return finishing
  } else if (text === "SuspendedEV") {
    return suspendedEV
  } else if (text === "SuspendedEVSE") {
    return suspendedEVSE
  } else if (text === "Faulted") {
    return faulted
  } else {
    return unavailable
  }
}

export const StatusesTableLayout: TableLayout[] = [
  {
    title: "Charging Point",
    data: "chargingPointId",
    width: 200,
    type: "string",
  },
  {
    title: "Connector",
    data: "connectorId",
    width: 100,
    type: "",
  },
  {
    title: "State",
    data: "status",
    width: 150,
    type: "string",
    filtering: true,
    options: [
      "Available",
      "Error",
      "Charging",
      "Finishing",
      "Faulted",
      "SuspendedEV",
      "SuspendedEVSE",
      "Preparing",
    ],
  },
  {
    title: "Timestamp",
    data: "timestamp",
    width: 100,
    type: "dateTime",
  },
]

const Statuses = ({ id }: { id: string }) => {
  const [statuses, setStatuses] = useState<{ [day: string]: Status[] }>({
    [today()]: [],
  })
  const [statusesDay, setStatusesDay] = useState<string>(today())
  const [statusesLoading, setStatusesLoading] = useState<boolean>(true)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [search, setSearch] = useState<string>("")
  const [statusFilters, setStatusFilters] = useState<TableFilters[]>([])
  const { token } = useAppContext()
  const [data, setData] = useState<TableRows[]>([])

  const getStatuses = () => {
    setStatusesLoading(true)
    const [year, month, day] = statusesDay.split("-")
    axios
      .get(
        `${process.env.REACT_APP_API_ADDRESS}/gatewayLogs/${id}/${Number(
          year
        )}/${Number(month)}/${Number(day)}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        const data = res.data
        setStatuses((statuses) => ({
          ...statuses,
          [`${data.year}-${data.month < 10 ? `0${data.month}` : data.month}-${
            data.day < 10 ? `0${data.day}` : data.day
          }`]: res.data.logs,
        }))
        setStatusesLoading(false)
      })
      .catch((err) => {
        setStatusesLoading(false)
        console.log(err)
      })
  }

  useEffect(() => {
    getStatuses()
  }, [token, id])

  useEffect(() => {
    const data = (statuses[statusesDay] || []).filter(
      (status) =>
        status.chargingPointId.includes(search) &&
        (statusFilters.length === 0 ||
          statusFilters.map(({ value }) => value).includes(status.status))
    )
    setData(data)
  }, [token, id, search, statusFilters, statuses])

  useEffect(() => {
    if (!statuses[statusesDay]) {
      setStatusesLoading(true)
      getStatuses()
    } else {
      const data = (statuses[statusesDay] || []).filter(
        (status) =>
          status.chargingPointId.includes(search) &&
          (statusFilters.length === 0 ||
            statusFilters.map(({ value }) => value).includes(status.status))
      )
      setData(data)
    }
  }, [token, id, statusesDay])

  return (
    <StyledCardContainer>
      <Box direction={"column"} align={"start"} style={{}}>
        <Label size="small" style={{ textAlign: "left", marginRight: 0 }}>
          Day
        </Label>
        <DateInput
          type="date"
          value={statusesDay}
          onChange={(e) => setStatusesDay(e.target.value)}
        />
      </Box>
      <CardTable>
        <TableProvider
          searchString={setSearch}
          searchValue={search}
          priority={false}
          showAdjust={true}
          double={true}
          noAction
          setFilters={setStatusFilters}
          filters={statusFilters}
        >
          <Box margin="0 0 40px 0">
            <Table
              layout={StatusesTableLayout}
              data={
                data.slice(
                  (page - 1) * pageSize,
                  page * pageSize
                ) as TableRows[]
              }
              loading={statusesLoading}
              toolbar={true}
              title={t("Status Logs")}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageNumber={page}
              setPageNumber={setPage}
              totalSize={data.length}
              totalPage={Math.ceil(data.length / pageSize)}
              colorGetter={(e) => stateColorFomatter(e["status"])}
            />
          </Box>
        </TableProvider>
      </CardTable>
    </StyledCardContainer>
  )
}

const Notifications = ({ id }: Props) => {
  const [notifications, setNotifications] = useState<Gateway["alerts"]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const { userInfo } = useUserContext()
  const { toasts, setToasts, token } = useAppContext()
  const isAdmin = userInfo.role === "admin"

  const getNotifications = () => {
    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/gateways/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setNotifications(res.data?.alerts || [])
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }
  useEffect(() => {
    getNotifications()
  }, [token, id])
  const visibleNotifications = notifications.filter(
    (notif) => !notif.adminOnly || isAdmin
  )
  const notificationsMap = _.groupBy(
    visibleNotifications,
    (notif) => notif.service
  )
  const services = Object.keys(notificationsMap)
  const [showService, setShowService] = useState(services[0])
  useEffect(() => {
    setShowService(services[0])
  }, [id, notifications])
  const getGlobalIndex = (serviceName: string, localIndex: number): number => {
    let globalIndex = 0
    let serviceFound = false

    for (const currentServiceName in notificationsMap) {
      if (notificationsMap[currentServiceName]) {
        if (currentServiceName === serviceName) {
          serviceFound = true
          break
        }
        globalIndex += notificationsMap[currentServiceName].length
      }
    }

    if (!serviceFound) {
      throw new Error(`Service name ${serviceName} not found.`)
    }

    // Check if localIndex is within the bounds of the array for the service
    if (localIndex >= 0 && localIndex < notificationsMap[serviceName].length) {
      return globalIndex + localIndex
    } else {
      throw new Error(
        `Local index ${localIndex} is out of bounds for service ${serviceName}.`
      )
    }
  }

  const deleteNotification = (i: number) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_ADDRESS}/notifications/${id}/ONE/${i}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        toastHandler(toasts, setToasts, true)
        setNotifications(visibleNotifications.filter((_, index) => index !== i))
      })
      .catch((err) => {
        console.log(err)
        toastHandler(toasts, setToasts, false)
      })
  }

  const deleteAllNotificationForCategory = (category?: string) => {
    if (!category) return
    axios
      .delete(
        `${process.env.REACT_APP_API_ADDRESS}/notifications/${id}/ALL/${category}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        toastHandler(toasts, setToasts, true)
        setNotifications(
          visibleNotifications.filter(({ service }) => service !== category)
        )
      })
      .catch((err) => {
        console.log(err)
        toastHandler(toasts, setToasts, false)
      })
  }

  return (
    <FlexCol>
      <SubMenu items={MainPaths()} id={id} main={true} />
      <Statuses id={id} />
      <StyledCardContainer>
        {loading ? (
          <Loader />
        ) : (
          <>
            <p
              style={{
                fontSize: xsmall,
                fontWeight: semibold,
              }}
            >
              {`Last Notifications ( ${visibleNotifications?.length} )`}
            </p>
            <Box style={{ flexDirection: "row", justifyContent: "flex-start" }}>
              {services.map((s) => (
                <SubMenuItem
                  key={`notif-${s}-tab`}
                  active={showService === s}
                  onClick={() => setShowService(s)}
                >
                  {`${mapServiceName(s)} (${notificationsMap[s].length})`}
                </SubMenuItem>
              ))}
            </Box>
            <TableContainer>
              <div
                style={{
                  width: "100%",
                }}
              >
                <Box
                  direction="row"
                  align="center"
                  padding="0 10px 10px 0 "
                  justify="space-between"
                ></Box>
              </div>
              <TableStyled drag={false} padding={false}>
                <thead>
                  <TableHead>
                    <StyledColumn width={100} ref={null}>
                      <FlexRow style={{ justifyContent: "space-between" }}>
                        <FlexRow style={{ alignItems: "left" }}>
                          <p>When</p>
                        </FlexRow>
                      </FlexRow>
                    </StyledColumn>
                    <StyledColumn width={100} ref={null}>
                      <FlexRow style={{ justifyContent: "space-between" }}>
                        <FlexRow style={{ alignItems: "left" }}>
                          <p>Time</p>
                        </FlexRow>
                      </FlexRow>
                    </StyledColumn>
                    <StyledColumn width={100} ref={null}>
                      <FlexRow style={{ justifyContent: "space-between" }}>
                        <FlexRow style={{ alignItems: "left" }}>
                          <p>Component</p>
                        </FlexRow>
                      </FlexRow>
                    </StyledColumn>
                    <StyledColumn width={100} ref={null}>
                      <FlexRow style={{ justifyContent: "space-between" }}>
                        <FlexRow style={{ alignItems: "left" }}>
                          <p>Message</p>
                        </FlexRow>
                      </FlexRow>
                    </StyledColumn>
                    <StyledColumn width={30} ref={null} />
                  </TableHead>
                </thead>
                <tbody>
                  {notificationsMap[showService]?.map((entry, i) => {
                    return (
                      <StyledTableRow
                        key={`notification-${showService}-${i}`}
                        disabled={false}
                      >
                        <Row
                          text={moment(entry.timestamp).fromNow()}
                          width={100}
                        />
                        <StyledDateTimeRow>
                          <p>{moment(entry.timestamp).format("HH:mm:ss")}</p>
                          <p>{moment(entry.timestamp).format("DD-MM-YYYY")}</p>
                        </StyledDateTimeRow>
                        <Row
                          text={
                            entry.service
                              ? mapServiceName(entry.service)
                              : "LMD"
                          }
                          width={10}
                        />
                        <Row text={entry.message} width={10} />
                        <StyledRow width={30}>
                          <ButtonIcon
                            icon={<Cross />}
                            hoverText="Delete notification"
                            onClick={() =>
                              deleteNotification(getGlobalIndex(showService, i))
                            }
                          />
                        </StyledRow>
                      </StyledTableRow>
                    )
                  })}
                </tbody>
              </TableStyled>
            </TableContainer>
            <hr />
            <Box direction="row-reverse">
              <Button
                width="100px"
                color={`${red} !important`}
                active={notificationsMap[showService]?.length > 0}
                text="Delete all"
                onClick={() => {
                  deleteAllNotificationForCategory(showService)
                }}
              />
            </Box>
          </>
        )}
      </StyledCardContainer>
    </FlexCol>
  )
}

export default Notifications
