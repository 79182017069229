import styled from "styled-components"

interface Props {
  direction?: string
  justify?: string
  padding?: string
  align?: string
  width?: number
  margin?: string
}

export const Grid = styled.div<Props>`
  align-items: center;
  display: grid;
  grid-template-columns: 65% 50%;
`

export const Grid3 = styled.div<Props>`
  align-items: center;
  display: grid;
  grid-template-columns: 50% 30% 20%;
  padding-bottom: 10px;
`
