import { FlexRow } from "components/Shared/FlexRow"
import React from "react"
import { Link, useLocation } from "wouter"

import { SubMenuItem } from "./SubMenu.styled"

type Item = {
  name: string
  value: string
}

type Props = {
  items: Array<Item>
  id?: string
  main?: boolean
}

const SubMenu = ({ items, id, main }: Props) => {
  const location = useLocation()

  const paths: string[] = location[0].split("/")

  return (
    <FlexRow>
      {items.map((item: Item) => (
        <Link
          href={`/infrastructure/${id}/${!main ? "configuration/" : ""}${
            item.value
          }`}
          key={item.value}
        >
          <SubMenuItem
            active={!main ? paths[4] === item.value : paths[3] === item.value}
          >
            {item.name}
          </SubMenuItem>
        </Link>
      ))}
    </FlexRow>
  )
}

export default SubMenu
