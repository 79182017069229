import React from "react";
import FeCBrain from "./FeCBrain.svg";
import { primaryDarker } from "styles/colors";

export const FeCBrainIcon = ({background = primaryDarker}) => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="25" height="25" rx="12.5" fill={background}/>
<g clip-path="url(#clip0_415_18)">
<path d="M14.8986 19.4049V17.3435L12.5625 14.8857" stroke="#3B98D4" stroke-width="0.359195" stroke-miterlimit="10"/>
<path d="M5.14258 9.25244V11.9948L7.18308 14.145H11.0233" stroke="#3B98D4" stroke-width="0.359195" stroke-miterlimit="10"/>
<path d="M6.6665 10.7295L8.35342 12.5051H11.1832L12.7497 10.8539" stroke="#3B98D4" stroke-width="0.359195" stroke-miterlimit="10"/>
<path d="M8.37061 7.51709L9.89442 9.12566V10.8564" stroke="#3B98D4" stroke-width="0.359195" stroke-miterlimit="10"/>
<path d="M9.75879 6.14453L12.097 8.60577H14.6083" stroke="#3B98D4" stroke-width="0.359195" stroke-miterlimit="10"/>
<path d="M6.2417 7.51692L8.93792 4.67773H17.5838L21.3714 8.66458V11.3309L20.4114 12.3438" stroke="#3B98D4" stroke-width="0.359195" stroke-miterlimit="10"/>
<path d="M12.396 6.14453H16.8974L19.0101 8.37071" stroke="#3B98D4" stroke-width="0.359195" stroke-miterlimit="10"/>
<path d="M16.397 7.75342V9.92429L13.5913 12.8764" stroke="#3B98D4" stroke-width="0.359195" stroke-miterlimit="10"/>
<path d="M15.3726 12.6434L17.7984 10.0889H19.8214" stroke="#3B98D4" stroke-width="0.359195" stroke-miterlimit="10"/>
<path d="M18.8744 13.4443H17.3144L16.0939 14.7314H14.396" stroke="#3B98D4" stroke-width="0.359195" stroke-miterlimit="10"/>
<path d="M20.3969 14.4019V15.349L19.2223 16.5854H17.5989L16.5918 17.6432V20.8211" stroke="#3B98D4" stroke-width="0.359195" stroke-miterlimit="10"/>
<path d="M7.00667 7.51713C7.00775 7.6769 6.96373 7.83341 6.8802 7.96681C6.79666 8.10021 6.67736 8.20449 6.53744 8.26642C6.39752 8.32835 6.24328 8.34515 6.09427 8.31468C5.94527 8.28421 5.80822 8.20785 5.70051 8.09528C5.59279 7.98271 5.51926 7.839 5.48925 7.68238C5.45924 7.52577 5.47409 7.36329 5.53192 7.21557C5.58976 7.06785 5.68797 6.94154 5.8141 6.85265C5.94023 6.76377 6.0886 6.71631 6.24039 6.71631C6.44267 6.7163 6.63675 6.80049 6.7803 6.95051C6.92385 7.10053 7.00522 7.30421 7.00667 7.51713Z" fill="#F1F8FC"/>
<path d="M5.14239 10.0555C5.56379 10.0555 5.90539 9.69591 5.90539 9.25235C5.90539 8.80879 5.56379 8.44922 5.14239 8.44922C4.721 8.44922 4.37939 8.80879 4.37939 9.25235C4.37939 9.69591 4.721 10.0555 5.14239 10.0555Z" fill="#F1F8FC"/>
<path d="M7.43149 10.7294C7.43149 10.889 7.38655 11.0449 7.30235 11.1776C7.21815 11.3102 7.09847 11.4136 6.95845 11.4746C6.81843 11.5357 6.66436 11.5517 6.51572 11.5205C6.36707 11.4894 6.23053 11.4126 6.12337 11.2998C6.0162 11.187 5.94322 11.0433 5.91365 10.8868C5.88408 10.7303 5.89926 10.5682 5.95726 10.4208C6.01525 10.2734 6.11347 10.1474 6.23949 10.0588C6.3655 9.97016 6.51365 9.92285 6.66521 9.92285C6.86844 9.92285 7.06335 10.0078 7.20705 10.1591C7.35076 10.3104 7.43149 10.5155 7.43149 10.7294V10.7294Z" fill="#F1F8FC"/>
<path d="M9.13362 7.51713C9.13471 7.6769 9.09069 7.83341 9.00715 7.96681C8.92361 8.10021 8.80432 8.20449 8.6644 8.26642C8.52447 8.32835 8.37023 8.34515 8.22123 8.31468C8.07222 8.28421 7.93517 8.20785 7.82746 8.09528C7.71974 7.98271 7.64622 7.839 7.6162 7.68238C7.58619 7.52577 7.60104 7.36329 7.65888 7.21557C7.71671 7.06785 7.81492 6.94154 7.94105 6.85265C8.06718 6.76377 8.21555 6.71631 8.36734 6.71631C8.56962 6.7163 8.76371 6.80049 8.90726 6.95051C9.05081 7.10053 9.13218 7.30421 9.13362 7.51713Z" fill="#F1F8FC"/>
<path d="M10.662 10.8564C10.662 11.0703 10.5812 11.2755 10.4375 11.4267C10.2938 11.578 10.0989 11.663 9.89568 11.663C9.69245 11.663 9.49754 11.578 9.35383 11.4267C9.21013 11.2755 9.12939 11.0703 9.12939 10.8564C9.12939 10.6425 9.21013 10.4373 9.35383 10.286C9.49754 10.1348 9.69245 10.0498 9.89568 10.0498C10.0989 10.0498 10.2938 10.1348 10.4375 10.286C10.5812 10.4373 10.662 10.6425 10.662 10.8564V10.8564Z" fill="#F1F8FC"/>
<path d="M9.75861 6.94806C10.18 6.94806 10.5216 6.58848 10.5216 6.14493C10.5216 5.70137 10.18 5.3418 9.75861 5.3418C9.33721 5.3418 8.99561 5.70137 8.99561 6.14493C8.99561 6.58848 9.33721 6.94806 9.75861 6.94806Z" fill="#F1F8FC"/>
<path d="M12.395 6.9489C12.817 6.9489 13.159 6.58881 13.159 6.14461C13.159 5.70042 12.817 5.34033 12.395 5.34033C11.973 5.34033 11.6309 5.70042 11.6309 6.14461C11.6309 6.58881 11.973 6.9489 12.395 6.9489Z" fill="#F1F8FC"/>
<path d="M15.1907 8.65905C15.192 8.81886 15.1482 8.97548 15.0648 9.10903C14.9814 9.24258 14.8622 9.34704 14.7223 9.40915C14.5824 9.47126 14.4281 9.48822 14.279 9.45788C14.13 9.42755 13.9928 9.35128 13.885 9.23875C13.7772 9.12623 13.7035 8.98253 13.6734 8.82588C13.6433 8.66923 13.6581 8.5067 13.7159 8.35891C13.7737 8.21112 13.8719 8.08474 13.9981 7.9958C14.1242 7.90687 14.2726 7.85938 14.4245 7.85938C14.6266 7.85937 14.8205 7.9434 14.964 8.09317C15.1075 8.24294 15.189 8.44633 15.1907 8.65905V8.65905Z" fill="#F1F8FC"/>
<path d="M13.5145 10.8564C13.5145 11.0159 13.4696 11.1719 13.3854 11.3045C13.3012 11.4372 13.1815 11.5405 13.0415 11.6016C12.9014 11.6626 12.7474 11.6786 12.5987 11.6475C12.4501 11.6164 12.3135 11.5395 12.2064 11.4267C12.0992 11.3139 12.0262 11.1702 11.9967 11.0137C11.9671 10.8573 11.9823 10.6951 12.0403 10.5477C12.0983 10.4003 12.1965 10.2744 12.3225 10.1857C12.4485 10.0971 12.5967 10.0498 12.7482 10.0498C12.9514 10.0498 13.1464 10.1348 13.2901 10.286C13.4338 10.4373 13.5145 10.6425 13.5145 10.8564Z" fill="#F1F8FC"/>
<path d="M11.784 14.0991C11.784 14.2586 11.7391 14.4145 11.6549 14.5472C11.5707 14.6798 11.451 14.7832 11.311 14.8443C11.171 14.9053 11.0169 14.9213 10.8683 14.8902C10.7196 14.859 10.5831 14.7822 10.4759 14.6694C10.3687 14.5566 10.2958 14.4129 10.2662 14.2564C10.2366 14.1 10.2518 13.9378 10.3098 13.7904C10.3678 13.643 10.466 13.517 10.592 13.4284C10.718 13.3398 10.8662 13.2925 11.0177 13.2925C11.221 13.2925 11.4159 13.3775 11.5596 13.5287C11.7033 13.68 11.784 13.8851 11.784 14.0991V14.0991Z" fill="#F1F8FC"/>
<path d="M13.3124 14.9018C13.3124 15.0613 13.2674 15.2173 13.1832 15.3499C13.099 15.4826 12.9793 15.5859 12.8393 15.647C12.6993 15.708 12.5452 15.724 12.3966 15.6929C12.2479 15.6618 12.1114 15.5849 12.0042 15.4721C11.8971 15.3593 11.8241 15.2156 11.7945 15.0592C11.7649 14.9027 11.7801 14.7405 11.8381 14.5931C11.8961 14.4457 11.9943 14.3198 12.1203 14.2311C12.2464 14.1425 12.3945 14.0952 12.5461 14.0952C12.7493 14.0952 12.9442 14.1802 13.0879 14.3315C13.2316 14.4827 13.3124 14.6879 13.3124 14.9018V14.9018Z" fill="#F1F8FC"/>
<path d="M14.1366 13.0214C14.1366 13.181 14.0916 13.3369 14.0074 13.4695C13.9232 13.6022 13.8036 13.7056 13.6635 13.7666C13.5235 13.8277 13.3694 13.8436 13.2208 13.8125C13.0721 13.7814 12.9356 13.7046 12.8284 13.5918C12.7213 13.479 12.6483 13.3353 12.6187 13.1788C12.5892 13.0223 12.6043 12.8601 12.6623 12.7128C12.7203 12.5654 12.8185 12.4394 12.9446 12.3508C13.0706 12.2621 13.2187 12.2148 13.3703 12.2148C13.5735 12.2148 13.7684 12.2998 13.9121 12.4511C14.0558 12.6024 14.1366 12.8075 14.1366 13.0214V13.0214Z" fill="#F1F8FC"/>
<path d="M17.1566 7.75339C17.1566 7.91322 17.1115 8.06943 17.027 8.20222C16.9425 8.33501 16.8224 8.43838 16.682 8.49922C16.5416 8.56006 16.3872 8.57563 16.2384 8.54394C16.0896 8.51225 15.953 8.43474 15.8461 8.32125C15.7392 8.20775 15.6668 8.06339 15.638 7.90647C15.6091 7.74956 15.6252 7.58716 15.6843 7.43991C15.7433 7.29265 15.8425 7.16716 15.9694 7.07937C16.0963 6.99158 16.245 6.94544 16.3969 6.94681C16.599 6.94863 16.7922 7.03441 16.9345 7.18548C17.0768 7.33655 17.1566 7.54067 17.1566 7.75339Z" fill="#F1F8FC"/>
<path d="M19.0584 9.12433C19.4798 9.12433 19.8214 8.76475 19.8214 8.3212C19.8214 7.87764 19.4798 7.51807 19.0584 7.51807C18.637 7.51807 18.2954 7.87764 18.2954 8.3212C18.2954 8.76475 18.637 9.12433 19.0584 9.12433Z" fill="#F1F8FC"/>
<path d="M19.8212 11.0729C20.2432 11.0729 20.5853 10.7128 20.5853 10.2686C20.5853 9.82445 20.2432 9.46436 19.8212 9.46436C19.3992 9.46436 19.0571 9.82445 19.0571 10.2686C19.0571 10.7128 19.3992 11.0729 19.8212 11.0729Z" fill="#F1F8FC"/>
<path d="M15.3714 13.5999C15.7928 13.5999 16.1344 13.2403 16.1344 12.7968C16.1344 12.3532 15.7928 11.9937 15.3714 11.9937C14.95 11.9937 14.6084 12.3532 14.6084 12.7968C14.6084 13.2403 14.95 13.5999 15.3714 13.5999Z" fill="#F1F8FC"/>
<path d="M14.3958 15.5784C14.8172 15.5784 15.1588 15.2189 15.1588 14.7753C15.1588 14.3317 14.8172 13.9722 14.3958 13.9722C13.9744 13.9722 13.6328 14.3317 13.6328 14.7753C13.6328 15.2189 13.9744 15.5784 14.3958 15.5784Z" fill="#F1F8FC"/>
<path d="M18.873 14.2487C19.295 14.2487 19.6371 13.8886 19.6371 13.4444C19.6371 13.0002 19.295 12.6401 18.873 12.6401C18.451 12.6401 18.1089 13.0002 18.1089 13.4444C18.1089 13.8886 18.451 14.2487 18.873 14.2487Z" fill="#F1F8FC"/>
<path d="M21.1634 12.33C21.1634 12.4896 21.1185 12.6455 21.0343 12.7781C20.9501 12.9108 20.8304 13.0142 20.6904 13.0752C20.5504 13.1363 20.3963 13.1522 20.2476 13.1211C20.099 13.09 19.9625 13.0132 19.8553 12.9004C19.7481 12.7876 19.6752 12.6438 19.6456 12.4874C19.616 12.3309 19.6312 12.1687 19.6892 12.0214C19.7472 11.874 19.8454 11.748 19.9714 11.6594C20.0974 11.5707 20.2456 11.5234 20.3971 11.5234C20.6004 11.5234 20.7953 11.6084 20.939 11.7597C21.0827 11.9109 21.1634 12.1161 21.1634 12.33Z" fill="#F1F8FC"/>
<path d="M21.1634 14.4042C21.1634 14.5638 21.1185 14.7197 21.0343 14.8524C20.9501 14.985 20.8304 15.0884 20.6904 15.1494C20.5504 15.2105 20.3963 15.2265 20.2476 15.1953C20.099 15.1642 19.9625 15.0874 19.8553 14.9746C19.7481 14.8618 19.6752 14.7181 19.6456 14.5616C19.616 14.4051 19.6312 14.243 19.6892 14.0956C19.7472 13.9482 19.8454 13.8222 19.9714 13.7336C20.0974 13.645 20.2456 13.5977 20.3971 13.5977C20.6004 13.5977 20.7953 13.6826 20.939 13.8339C21.0827 13.9852 21.1634 14.1903 21.1634 14.4042Z" fill="#F1F8FC"/>
<path d="M16.5533 21.6222C16.9735 21.6222 17.3141 21.2636 17.3141 20.8213C17.3141 20.379 16.9735 20.0205 16.5533 20.0205C16.1331 20.0205 15.7925 20.379 15.7925 20.8213C15.7925 21.2636 16.1331 21.6222 16.5533 21.6222Z" fill="#F1F8FC"/>
<path d="M14.8981 20.206C15.3189 20.206 15.66 19.8469 15.66 19.404C15.66 18.9611 15.3189 18.6021 14.8981 18.6021C14.4773 18.6021 14.1362 18.9611 14.1362 19.404C14.1362 19.8469 14.4773 20.206 14.8981 20.206Z" fill="#F1F8FC"/>
<path d="M14.9288 19.3371V17.2757L12.5928 14.8179" stroke="#F1F8FC" stroke-width="0.478927" stroke-miterlimit="10"/>
<path d="M5.17334 9.18457V11.927L7.21384 14.0771H11.054" stroke="#F1F8FC" stroke-width="0.478927" stroke-miterlimit="10"/>
<path d="M6.69678 10.6616L8.3837 12.4373H11.2135L12.78 10.7861" stroke="#F1F8FC" stroke-width="0.478927" stroke-miterlimit="10"/>
<path d="M8.40137 7.44922L9.92518 9.05778V10.7885" stroke="#F1F8FC" stroke-width="0.478927" stroke-miterlimit="10"/>
<path d="M9.78955 6.07666L12.1278 8.5379H14.639" stroke="#F1F8FC" stroke-width="0.478927" stroke-miterlimit="10"/>
<path d="M6.27197 7.44905L8.9682 4.60986H17.6141L21.4017 8.59671V11.2631L20.4416 12.2759" stroke="#F1F8FC" stroke-width="0.478927" stroke-miterlimit="10"/>
<path d="M12.4277 6.07666H16.928L19.0408 8.30284" stroke="#F1F8FC" stroke-width="0.478927" stroke-miterlimit="10"/>
<path d="M16.4278 7.68555V9.85642L13.6221 12.8085" stroke="#F1F8FC" stroke-width="0.478927" stroke-miterlimit="10"/>
<path d="M15.4028 12.5754L17.8298 10.022H19.8517" stroke="#F1F8FC" stroke-width="0.478927" stroke-miterlimit="10"/>
<path d="M18.905 13.3765H17.3451L16.1256 14.6635H14.4277" stroke="#F1F8FC" stroke-width="0.478927" stroke-miterlimit="10"/>
<path d="M20.4288 14.334V15.2811L19.2542 16.5175H17.6297L16.6226 17.5753V20.7521" stroke="#F1F8FC" stroke-width="0.478927" stroke-miterlimit="10"/>
</g>
<defs>
<linearGradient id="paint0_linear_415_18" x1="1.87628" y1="-1.98297" x2="25.5" y2="25.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#009EE3"/>
<stop offset="1" stop-color="#2541B2"/>
</linearGradient>
<clipPath id="clip0_415_18">
<rect width="17.2414" height="17.2414" fill="white" transform="translate(4.37939 4.37939)"/>
</clipPath>
</defs>
</svg>

);