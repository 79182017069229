import styled from "styled-components"
import { red } from "styles/colors"
import { xsmall } from "styles/fonts"

export const Warning = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${red};
  font-size: ${xsmall};
  padding: 0;
  margin: 0;
`
