import ButtonPaginate from "components/Buttons/ButtonPaginate"
import SelectPaginate from "components/InputField/SelectPaginate"
import Loader from "components/Loader/Loader"
import { Box } from "components/Shared/Box.styled"
import { TableLayout, TableRows } from "components/types/types"
import { useTableContext } from "helpers/TableProvider"
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { actionCreators } from "state"
import { TableDimensions } from "state/models"
import { RootState } from "state/reducers"
import { primary, white } from "styles/colors"

import AddRow from "./AddRow"
import Column from "./Column"
import SelectColumn from "./SelectColumn"
import { NoData, TableContainer, TableHead, TableStyled } from "./Table.styled"
import { TableRow } from "./TableRow"
import TableToolbar from "./TableToolbar"

interface Props {
  title: string
  toolbar?: boolean
  addRow?: Dispatch<SetStateAction<boolean>>
  addText?: string
  addIcon?: ReactNode
  padding?: boolean
  data?: TableRows[]
  layout: TableLayout[]
  selectCell?: boolean
  updatePath?: string
  pageSize?: number
  setPageSize?: Dispatch<SetStateAction<number>>
  pageNumber?: number
  setPageNumber?: Dispatch<SetStateAction<number>>
  totalSize?: number
  totalPage?: number
  loading?: boolean
  legend?: {
    icon: ReactNode
    description: string
  }[]
  colorGetter?: (entry: TableRows) => string
}

const Table = ({
  toolbar,
  addRow,
  addText,
  addIcon,
  title,
  padding,
  data,
  layout,
  selectCell,
  pageSize,
  setPageSize,
  pageNumber,
  setPageNumber,
  totalSize,
  totalPage,
  loading,
  legend,
  colorGetter,
}: Props) => {
  const [selectAll, setSelectAll] = useState(false)
  const [containerWidth, setContainerWidth] = useState<number | undefined>(1000)
  const [changed, setChanged] = useState(false)
  const { noAction, drag } = useTableContext()

  const state = useSelector((state: RootState) => state.dimensions)

  const dispatch = useDispatch()
  const { editTableDimensions } = bindActionCreators(actionCreators, dispatch)

  const table = useRef<HTMLTableElement>(null)
  const { t } = useTranslation()
  const renderPagination = () => {
    if (pageNumber && totalPage && totalPage > 1) {
      const arr = []

      for (let i = 0; i < totalPage; i++) {
        if (i >= pageNumber - 3 && i <= pageNumber + 3) arr.push(i)
      }
      arr.length = 5
      return arr
    } else return []
  }

  const childChanged = () => {
    setChanged(true)
  }

  const loadState = () => {
    try {
      const serializedState = localStorage.getItem("state")
      if (!serializedState) return undefined
      else return JSON.parse(serializedState)
    } catch (err) {
      return undefined
    }
  }

  const saveState = (state: TableDimensions[]) => {
    try {
      const serializedState = JSON.stringify(state)
      localStorage.setItem("state", serializedState)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    const data: TableDimensions[] = loadState()
    data && editTableDimensions(data)
  }, [])

  useEffect(() => {
    const headers = document.querySelectorAll("th")
    const arr: number[] = []
    headers.forEach(function (ele: HTMLTableHeaderCellElement) {
      arr.push(ele.offsetWidth - 2)
    })

    changed &&
      editTableDimensions([{ title: "chargingStations", widths: [...arr] }])

    changed && saveState([{ title: "chargingStations", widths: [...arr] }])

    setContainerWidth(table.current?.offsetWidth)
    setChanged(false)
  }, [changed, loading])

  const totalPageCheck = totalPage !== undefined && !isNaN(totalPage)
  return (
    <>
      <TableContainer drag={drag}>
        <div
          style={{
            width: containerWidth && drag ? containerWidth + "px" : "100%",
          }}
        >
          {toolbar && (
            <TableToolbar
              text={title + ` ( ${totalSize ? totalSize : data?.length} )`}
            />
          )}
        </div>

        <TableStyled ref={table} drag={drag} padding={padding}>
          <thead>
            <TableHead>
              {selectCell && (
                <SelectColumn select={setSelectAll} selected={selectAll} />
              )}
              {layout.map((item, i) => {
                return (
                  <Column
                    tableHeight={
                      table.current ? table.current?.offsetHeight : 500
                    }
                    title={item.title}
                    filtering={item.filtering}
                    options={item.options}
                    width={
                      drag && state[0].widths[i]
                        ? state[0].widths[i]
                        : item.width
                    }
                    originalWidth={item.width}
                    type={item.type}
                    key={`header${item.title + i}`}
                    setChanged={() => childChanged()}
                  />
                )
              })}
              {!noAction && (
                <Column
                  tableHeight={
                    table.current ? table.current?.offsetHeight : 500
                  }
                  setChanged={() => childChanged()}
                  title={"Actions"}
                  originalWidth={155}
                  width={state[0].widths[state[0].widths.length - 1]}
                />
              )}
            </TableHead>
          </thead>

          <tbody>
            {data && data[0] && !loading ? (
              data.map((entry, i) => {
                return (
                  <TableRow
                    selectCell={selectCell}
                    layout={layout}
                    index={i}
                    entry={entry}
                    selectAll={selectAll}
                    key={`row${i}`}
                    colorGetter={colorGetter}
                  />
                )
              })
            ) : loading ? (
              <tr
                style={{
                  height: "200px",
                }}
              >
                {layout.map((item, i) => {
                  return i >= layout.length / 2 && <td key={i} />
                })}
                <td
                  style={{
                    width: "99%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  rowSpan={2}
                  colSpan={5}
                >
                  <Loader />
                </td>
              </tr>
            ) : data && !data[0] && !loading ? (
              <NoData>
                <td>
                  {t("No")} {title}
                </td>
              </NoData>
            ) : (
              ""
            )}
          </tbody>
        </TableStyled>
        <div
          style={{
            width: containerWidth && drag ? containerWidth + "px" : "100%",
          }}
        >
          <Box direction="row" justify={addRow ? "space-between" : "end"}>
            {addRow && (
              <AddRow
                addIcon={addIcon}
                text={addText || title}
                onClick={() => addRow(true)}
              />
            )}
            {totalPageCheck && pageSize && setPageNumber && pageNumber && (
              <Box direction="row" align="center" className="123">
                <>
                  {pageNumber - 3 >= 1 && (
                    <>
                      <ButtonPaginate
                        active={1 === pageNumber}
                        onClick={() => setPageNumber(1)}
                        text={String(1)}
                      />
                      <span
                        style={{
                          width: "2px",
                          height: "50%",
                          background: primary,
                          margin: "0 5px 0 5px",
                          borderRadius: "10px",
                        }}
                      ></span>
                    </>
                  )}
                  {renderPagination().map((item: number) => {
                    return (
                      <ButtonPaginate
                        key={item + "pagination"}
                        active={item === pageNumber - 1}
                        onClick={() => setPageNumber(item + 1)}
                        text={String(item + 1)}
                      />
                    )
                  })}
                  {pageNumber + 3 <= totalPage && (
                    <>
                      <span
                        style={{
                          width: "2px",
                          height: "50%",
                          background: primary,
                          margin: "0 5px 0 5px",
                          borderRadius: "10px",
                        }}
                      ></span>
                      <ButtonPaginate
                        active={totalPage === pageNumber}
                        onClick={() => setPageNumber(totalPage)}
                        text={String(totalPage)}
                      />
                    </>
                  )}
                  {pageSize && totalSize && totalSize > 10 && setPageSize && (
                    <Box padding="0 0 0 20px" className="1234">
                      <SelectPaginate
                        text={pageSize}
                        setPageSize={setPageSize}
                        options={[10, 25, 50, 100]}
                      />
                    </Box>
                  )}
                </>
              </Box>
            )}
          </Box>
          {legend && (
            <Box
              direction="row"
              justify="center"
              style={{
                borderTop: "1px solid #ccc",
                padding: "2 2 2 2",
                background: white,
                gap: "10px",
                justifyContent: "flex-start",
              }}
            >
              {legend.map(({ icon, description }, i) => (
                <Box
                  direction="row"
                  align="center"
                  style={{ gap: "5px", marginTop: "5px" }}
                  key={`legend-item-${i}`}
                >
                  {icon}
                  <p style={{ margin: 0, fontSize: "small" }}>{description}</p>
                </Box>
              ))}
            </Box>
          )}
        </div>
      </TableContainer>
    </>
  )
}

export default Table
