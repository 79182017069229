import Loader from "components/Loader/Loader"
import { Toasts } from "components/types/types"
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
} from "react"
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce"

interface AppContext {
  setToasts: Dispatch<React.SetStateAction<Toasts[]>>
  toasts: Toasts[]
  token: string
}

interface AppContextProps {
  setToasts: Dispatch<React.SetStateAction<Toasts[]>>
  toasts: Toasts[]
}
export const Context = createContext<AppContext>({
  setToasts: () => [],
  toasts: [],
  token: "",
})

export const AppProvider = ({
  setToasts,
  toasts,
  children,
}: PropsWithChildren<AppContextProps>) => {
  const { token, loginInProgress } = useContext<IAuthContext>(AuthContext)

  return token && !loginInProgress ? (
    <Context.Provider
      value={{
        token,
        setToasts,
        toasts,
      }}
    >
      {children}
    </Context.Provider>
  ) : (
    <Loader />
  )
}

export const useAppContext = () => useContext<AppContext>(Context)
