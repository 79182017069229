import { useTranslation } from "react-i18next"

export const SubPaths = () => {
  const { t } = useTranslation()

  return [
    {
      name: t("Load Management"),
      value: "",
    },
    {
      name: t("Powermeter"),
      value: "powermeters",
    },
    {
      name: t("General"),
      value: "general",
    },
  ]
}
