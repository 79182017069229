import { StyledCheckboxField } from "components/InputField/InputField.styled"
import React, { Dispatch, SetStateAction } from "react"

import { StyledSelectColumn } from "./Table.styled"

interface Props {
  select: Dispatch<SetStateAction<boolean>>
  selected: boolean
}

const SelectColumn = ({ select, selected }: Props) => {
  const clicked = () => {
    select(!selected)
  }

  return (
    <StyledSelectColumn>
      <StyledCheckboxField
        onChange={clicked}
        type="checkbox"
        checked={selected}
      />
    </StyledSelectColumn>
  )
}

export default SelectColumn
