import React, { Dispatch, SetStateAction } from "react"

import { StyledSelectPaginate } from "./InputField.styled"

interface Props {
  text: number
  setPageSize: Dispatch<SetStateAction<number>>
  options: number[]
}

const SelectPaginate = ({ text, setPageSize, options }: Props) => {
  const onChange = (number: number) => {
    setPageSize(number)
  }

  return (
    <StyledSelectPaginate
      onChange={(event) => onChange(Number(event.target.value))}
      value={text}
    >
      {options.map((option: number, i: number) => (
        <option key={option + i}>{option}</option>
      ))}
    </StyledSelectPaginate>
  )
}

export default SelectPaginate
