import { Dispatch } from "redux"

import { ActionType } from "../action-types"
import { Action } from "../actions/index"
import { TableDimensions } from "../models"

export const editTableDimensions = (dimensions: Array<TableDimensions>) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.EDIT,
      payload: dimensions,
    })
  }
}
