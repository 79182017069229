import axios from "axios"
import { Filter } from "components/Icons/Filter"
import CheckboxField from "components/InputField/CheckboxField"
import { FlexRow } from "components/Shared/FlexRow"
import { BackendPayload } from "components/types/types"
import { useAppContext } from "helpers/AppProvider"
import { useTableContext } from "helpers/TableProvider"
import React, { useEffect, useRef, useState } from "react"
import Draggable from "react-draggable"
import { useTranslation } from "react-i18next"
import { primary } from "styles/colors"

import { ColumnFilters, StyledColumn } from "./Table.styled"

interface Props {
  title: string
  width: number
  originalWidth: number
  tableHeight: number
  key?: string
  filtering?: boolean
  options?: string[]
  type?: string
  setChanged: () => void
}

const Column = ({
  width,
  originalWidth,
  tableHeight,
  title,
  filtering,
  options,
  type,
  setChanged,
}: Props) => {
  const [show, setShow] = useState(false)
  const { t } = useTranslation()
  const columnLocation = useRef<HTMLTableHeaderCellElement>(null)
  const [backendArr, setBackendArr] = useState<string[]>([])
  const [gwIds, setGwIds] = useState<string[]>([])
  const [startWidth, setStartWidth] = useState(0)
  const [startPosition, setStarPosition] = useState(0)
  const [movedWidth, setMovedWidth] = useState(1)
  const [showLine, setShowLine] = useState(false)
  const dragger = useRef<HTMLButtonElement | null>(null)
  const handleMouseDownEvent = (e: any) => {
    const result = dragger.current
      ? dragger.current?.getBoundingClientRect().x - startPosition
      : 0

    const newWidth = Math.ceil(startWidth + result)

    if (newWidth > originalWidth) {
      setMovedWidth(result)
      setStartWidth(newWidth)
    } else {
      setMovedWidth(0)
      setStartWidth(originalWidth)
    }

    e.preventDefault()
  }

  const { setFilters, filters, drag } = useTableContext()

  const { token } = useAppContext()

  const ref = useRef<HTMLDivElement>(null)

  const backends = () => {
    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/backends`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const arr: string[] = []
        res.data.map((item: BackendPayload) => {
          arr.push(item.name)
        })
        setBackendArr(arr)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const gwids = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_ADDRESS}/gateways/userGatewayIds
      `,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        const arr: string[] = []
        res.data.map((item: string) => {
          arr.push(item)
        })
        setGwIds(arr)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    backends()
    gwids()
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShow(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  /* eslint-disable indent */
  const isFoundCheck = (value: string) => {
    const isFound = filters
      ? filters.some((element) => {
          if (element.value === value && element.type === type) {
            return true
          }
          return false
        })
      : false

    return isFound
  }

  const clickHandler = (value: string) => {
    const arr = filters ? [...filters] : []

    const isFound = arr.some((element) => {
      if (element.value === value && element.type === type) {
        return true
      }
      return false
    })

    if (isFound) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].value === value && arr[i].type === type) {
          arr.splice(i, 1)
          setFilters && setFilters(arr)
        }
      }
    }

    if (!isFound) {
      arr.push({ type: type, value: value })
      setFilters && setFilters(arr)
    }
  }

  useEffect(() => {
    setStarPosition(width)
    setStartWidth(width)
  }, [width])

  return (
    <>
      <StyledColumn width={startWidth} ref={columnLocation}>
        <FlexRow style={{ justifyContent: "space-between" }}>
          <FlexRow style={{ alignItems: "center" }}>
            <p>{t(title)}</p>
            {filtering && (
              <span style={{ marginLeft: "5px", marginTop: "3px" }}>
                <Filter onClick={() => setShow(!show)} />
              </span>
            )}
          </FlexRow>

          {drag && (
            <Draggable
              axis="x"
              position={{ x: 0, y: 0 }}
              onStart={() => {
                setStarPosition(
                  dragger.current
                    ? dragger.current?.getBoundingClientRect().x
                    : 0
                )
              }}
              onStop={(e) => {
                handleMouseDownEvent(e)
                setChanged()
              }}
            >
              <button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  cursor: "col-resize",
                  background: "transparent",
                  border: "none",
                  outline: "none",
                }}
                onMouseEnter={() => setShowLine(true)}
                onMouseLeave={() => setShowLine(false)}
                ref={dragger}
              >
                <svg style={{ marginTop: "2px" }} height="20" width="3">
                  <line
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="20"
                    stroke="#349CE7"
                    strokeWidth={3}
                    opacity={showLine ? 0.2 : 1}
                  />
                </svg>
                <span
                  style={{
                    marginTop: "-2px",
                    width: "2px",
                    height: tableHeight + "px",
                    position: "absolute",
                    marginLeft: "-3px",
                    opacity: showLine ? 0.2 : 0,
                    background: primary,
                  }}
                />
              </button>
            </Draggable>
          )}
        </FlexRow>
        {filtering && show && (
          <>
            <ColumnFilters ref={ref}>
              {options?.length !== 0 &&
                options?.map((item, i) => {
                  return (
                    <FlexRow style={{ width: "100%" }} key={i}>
                      <CheckboxField
                        label={item}
                        onClick={() => clickHandler(item)}
                        checked={filters && isFoundCheck(item)}
                        width={100}
                      />
                    </FlexRow>
                  )
                })}

              {options?.length === 0 &&
                type === "CPMS" &&
                backendArr?.map((item, i) => {
                  return (
                    <FlexRow style={{ width: "100%" }} key={i}>
                      <CheckboxField
                        label={item}
                        onClick={() => clickHandler(item)}
                        checked={filters && isFoundCheck(item)}
                        width={100}
                      />
                    </FlexRow>
                  )
                })}
              {options?.length === 0 &&
                type === "gwId" &&
                gwIds?.map((item, i) => {
                  return (
                    <FlexRow style={{ width: "100%" }} key={i}>
                      <CheckboxField
                        label={item}
                        onClick={() => clickHandler(item)}
                        checked={filters && isFoundCheck(item)}
                        width={100}
                      />
                    </FlexRow>
                  )
                })}
            </ColumnFilters>
          </>
        )}
      </StyledColumn>
    </>
  )
}

export default Column
