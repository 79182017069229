import CheckboxField from "components/InputField/CheckboxField"
import { TableEntryType } from "components/types/types"
import React from "react"

import { StyledCheckRow } from "./Row.styled"

interface Props extends TableEntryType {
  checked: boolean
  disabled?: boolean
}

const CheckRow = ({ checked, disabled }: Props) => (
  <StyledCheckRow>
    <CheckboxField disabled={disabled} checked={checked} />
  </StyledCheckRow>
)
export default CheckRow
