import React from "react"
import { Props } from "./Bell"

export const Restart = ({ blinking }: Props) => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.31183 4.70548C9.31183 4.40463 9.06877 4.16156 8.76791 4.16156C8.46705 4.16156 8.22399 4.40463 8.22399 4.70548V8.51296C8.22399 8.81381 8.46705 9.05688 8.76791 9.05688C9.06877 9.05688 9.31183 8.81381 9.31183 8.51296V4.70548ZM6.85568 6.21148C7.08684 6.0194 7.11744 5.67605 6.92537 5.44488C6.73329 5.21371 6.38994 5.18312 6.15877 5.37519C5.2613 6.12309 4.68848 7.25173 4.68848 8.51296C4.68848 10.7651 6.51572 12.5924 8.76791 12.5924C11.0201 12.5924 12.8473 10.7651 12.8473 8.51296C12.8473 7.25173 12.2728 6.12309 11.3753 5.37519C11.1442 5.18312 10.8008 5.21541 10.6088 5.44488C10.4167 5.67435 10.449 6.0194 10.6784 6.21148C11.3397 6.7605 11.7578 7.58828 11.7578 8.51296C11.7578 10.1651 10.4184 11.5045 8.76621 11.5045C7.11404 11.5045 5.77463 10.1651 5.77463 8.51296C5.77463 7.58828 6.19447 6.7605 6.85398 6.21148H6.85568Z"
      fill="black"
    >
      {" "}
      {blinking && (
        <animate
          attributeType="XML"
          attributeName="fill"
          values="#800;#349CE7;#800;#800"
          dur="0.8s"
          repeatCount="indefinite"
        />
      )}{" "}
    </path>
    <path
      d="M6.44907 1.00001L3.41902 0.821382L4.77935 3.53479L6.44907 1.00001ZM2.50608 3.86475L2.71875 4.01926L2.71875 4.01926L2.50608 3.86475ZM2.50608 13.1353L2.71875 12.9807L2.71875 12.9807L2.50608 13.1353ZM6.36784 16.25C6.50591 16.2949 6.6542 16.2193 6.69907 16.0812C6.74393 15.9432 6.66837 15.7949 6.5303 15.75L6.36784 16.25ZM4.18262 1.84553C3.45963 2.35654 2.82026 2.98511 2.29342 3.71024L2.71875 4.01926C3.2116 3.34091 3.8097 2.7529 4.48606 2.27485L4.18262 1.84553ZM2.29342 3.71024C1.28192 5.10245 0.737135 6.77914 0.737135 8.5H1.26287C1.26287 6.89016 1.77251 5.32165 2.71875 4.01926L2.29342 3.71024ZM0.737135 8.5C0.737135 10.2209 1.28192 11.8976 2.29342 13.2898L2.71875 12.9807C1.77251 11.6784 1.26287 10.1098 1.26287 8.5H0.737135ZM2.29342 13.2898C3.30492 14.682 4.7312 15.7182 6.36784 16.25L6.5303 15.75C4.99925 15.2525 3.66499 14.2831 2.71875 12.9807L2.29342 13.2898Z"
      fill="black"
    >
      {" "}
      {blinking && (
        <animate
          attributeType="XML"
          attributeName="fill"
          values="#800;#349CE7;#800;#800"
          dur="0.8s"
          repeatCount="indefinite"
        />
      )}{" "}
    </path>
    <path
      d="M11.5148 16L14.5448 16.1786L13.1845 13.4652L11.5148 16ZM15.4578 13.1352L15.2451 12.9807L15.2451 12.9807L15.4578 13.1352ZM15.4578 3.86474L15.2451 4.01925L15.2451 4.01925L15.4578 3.86474ZM11.596 0.75C11.458 0.705138 11.3097 0.780699 11.2648 0.91877C11.2199 1.05684 11.2955 1.20514 11.4336 1.25L11.596 0.75ZM13.7812 15.1545C14.5042 14.6435 15.1436 14.0149 15.6704 13.2898L15.2451 12.9807C14.7523 13.6591 14.1542 14.2471 13.4778 14.7251L13.7812 15.1545ZM15.6704 13.2898C16.6819 11.8975 17.2267 10.2209 17.2267 8.5H16.701C16.701 10.1098 16.1914 11.6784 15.2451 12.9807L15.6704 13.2898ZM17.2267 8.5C17.2267 6.77913 16.6819 5.10244 15.6704 3.71024L15.2451 4.01925C16.1914 5.32164 16.701 6.89016 16.701 8.5H17.2267ZM15.6704 3.71024C14.6589 2.31803 13.2327 1.28178 11.596 0.75L11.4336 1.25C12.9646 1.74747 14.2989 2.71686 15.2451 4.01925L15.6704 3.71024Z"
      fill="black"
    >
      {" "}
      {blinking && (
        <animate
          attributeType="XML"
          attributeName="fill"
          values="#800;#349CE7;#800;#800"
          dur="0.8s"
          repeatCount="indefinite"
        />
      )}{" "}
    </path>
  </svg>
)
