import { FlexCol } from "components/Shared/FlexCol"
import { MainPaths } from "components/Shared/MainPaths"
import SubMenu from "components/SubMenu/SubMenu"
import React from "react"
import General from "Views/General/General"
import Powermeters from "Views/Powermeters/Powermeters"
import StaticLoadManagement from "Views/StaticLoadManagement/StaticLoadManagement"
import { Route } from "wouter"

interface Props {
  id: string
}
const Configuration = ({ id }: Props) => {
  return (
    <FlexCol>
      <SubMenu items={MainPaths()} id={id} main={true} />
      <hr />
      <div>
        <Route path="/infrastructure/:id/configuration/powermeters">
          {(params) => <Powermeters id={params.id} />}
        </Route>
        <Route path="/infrastructure/:id/configuration">
          {(params) => <StaticLoadManagement id={params.id} />}
        </Route>
        <Route path="/infrastructure/:id/configuration/general">
          {(params) => <General id={params.id} />}
        </Route>
      </div>
    </FlexCol>
  )
}

export default Configuration
