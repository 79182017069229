import { TableEntryType } from "components/types/types"
import moment from "moment"
import React from "react"

import { StyledDateTimeRow } from "./Row.styled"

interface Props extends TableEntryType {
  dateTime: string
}

const DateTimeRow = ({ dateTime }: Props) => {
  return (
    <StyledDateTimeRow>
      <p>{moment(dateTime).format("HH:mm:ss")}</p>
      <p>{moment(dateTime).format("DD-MM-YYYY")}</p>
    </StyledDateTimeRow>
  )
}
export default DateTimeRow
