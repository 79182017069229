import React from 'react'

interface Props {
    onClick: () => void
}

export const Filter = ({onClick}: Props) =>  (
    <svg onClick={onClick} width="17" height="13" viewBox="0 0 131 112" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M120.409 4.99632L11.5909 4.99614C7.36596 4.99613 5.04543 9.91139 7.73025 13.1735L53.3606 68.6156C54.0972 69.5106 54.5 70.6338 54.5 71.793L54.5 91.0639C54.5 92.6016 55.2075 94.0537 56.4185 95.0014L69.4185 105.175C72.7005 107.744 77.5 105.405 77.5 101.238L77.5 71.793C77.5 70.6338 77.9028 69.5106 78.6394 68.6156L124.27 13.1737C126.955 9.91158 124.634 4.99633 120.409 4.99632Z" stroke="#349CE7" strokeWidth="8" strokeLinecap="round" />
    </svg>
    
)