import axios from "axios"
import Button from "components/Buttons/Button"
import DateRangePicker from "components/DateRangePicker/DateRangePicker"
import SelectField from "components/InputField/SelectField"
import Loader from "components/Loader/Loader"
import { Box } from "components/Shared/Box.styled"
import { FlexCol } from "components/Shared/FlexCol"
import { FlexRow } from "components/Shared/FlexRow"
import { useAppContext } from "helpers/AppProvider"
import _ from "lodash"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { CSVDownload } from "react-csv"
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

function formatLocalDateTime(date: Date): string {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0") // Month is 0-based
  const day = String(date.getDate()).padStart(2, "0")
  const hours = String(date.getHours()).padStart(2, "0")
  const minutes = String(date.getMinutes()).padStart(2, "0")

  // Construct the formatted date and time string
  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`

  return formattedDateTime
}

const ChartComponent: React.FC<{
  id: string
  defaultSelectedFields: string[]
}> = ({ id, defaultSelectedFields }) => {
  const [data, setData] = useState<any[]>([])
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [selectedFields, setSelectedFields] = useState<string[]>([])
  const [availableFields, setAvailableFields] = useState<string[]>([])
  const [error, setError] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [powerMeters, setPowerMeters] = useState<string[]>([])
  const [selectedPowerMeter, setSelectedPowerMeter] = useState<string>("")
  const [downloadCSV, setDownloadCSV] = useState<boolean>(false)
  const [pmData, setPmData] = useState<any[]>([])
  const { token } = useAppContext()

  const powermeters = () => {
    setIsLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/powerMeters/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.data.length > 0) {
          setPmData(res.data)
        } else {
          setPmData([])
        }
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })
  }

  const fetchData = async (startDate: Date | null, endDate: Date | null) => {
    if (!startDate || !endDate) {
      setIsLoading(false)
      setError("Pick dates")
      return
    }
    const diff = endDate.getTime() - startDate.getTime()
    if (diff > 1000 * 60 * 60 * 24 * 62) {
      setIsLoading(false)
      setError("Maximal date range is 62 days")
      return
    }
    axios
      .get(
        `${
          process.env.REACT_APP_API_ADDRESS
        }/monitoring/${id}/powerMeterDataForRange?from=${startDate.toISOString()}&to=${endDate.toISOString()}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setError("")
        const data = res.data.map(
          ({ timestamp, ...rest }: { timestamp: string }) => {
            return {
              timestamp: formatLocalDateTime(
                moment.utc(`${timestamp.replace(/\s/, "T")}`).toDate()
              ),
              ...rest,
            }
          }
        )
        if (data.length === 0) {
          setError("No data for selected dates")
          return
        }
        setData(data)
        const fields = _.keys(data[0]).filter(
          (f) => f !== "timestamp" && f !== "powermeterId"
        )
        setAvailableFields(fields)
        setSelectedFields(_.intersection(fields, defaultSelectedFields))
        const powerMeters: string[] = _.uniq(
          data.map((d: { powermeterId: string }) => d.powermeterId)
        )
        setPowerMeters(powerMeters)
        setSelectedPowerMeter(powerMeters[0])
        setIsLoading(false)
      })
  }

  const handeDataChange = () => {
    setIsLoading(true)
    setError("")
    fetchData(startDate, endDate)
  }

  useEffect(() => {
    handeDataChange()
  }, [id, token, startDate, endDate])

  useEffect(() => {
    powermeters()
  }, [id, token])

  const toggleField = (field: string) => {
    setSelectedFields((prevSelectedFields: string[]) => {
      if (prevSelectedFields.includes(field)) {
        return prevSelectedFields.filter((f: string) => f !== field)
      } else {
        return [...prevSelectedFields, field]
      }
    })
  }

  const getRandomColor = (fieldNumber: number) => {
    const colors = [
      "#ea5545",
      "#f46a9b",
      "#ef9b20",
      "#edbf33",
      "#ede15b",
      "#bdcf32",
      "#87bc45",
      "#27aeef",
      "#b33dc6",
      "#e60049",
      "#0bb4ff",
      "#50e991",
      "#e6d800",
      "#9b19f5",
      "#ffa300",
      "#dc0ab4",
      "#b3d4ff",
      "#00bfa0",
    ]
    return colors[fieldNumber % colors.length]
  }
  const filteredData = data.filter((d) => d.powermeterId === selectedPowerMeter)
  return (
    <FlexCol>
      <FlexRow>
        <Box justify="center" margin="0 10px 0 10px">
          <DateRangePicker
            label="Date range"
            onChange={(start, end) => {
              start && setStartDate(new Date(start))
              end && setEndDate(new Date(end))
              handeDataChange()
            }}
          />
        </Box>
        {!isLoading && data.length > 0 && pmData.length > 1 && (
          <Box justify="center" margin="0 10px 0 10px">
            <SelectField
              options={pmData.map((pm) => pm.description)}
              label="Power Meter"
              onChange={(description: string) => {
                setSelectedPowerMeter(
                  pmData.find((pm) => pm.description === description)?.id
                )
              }}
            />
          </Box>
        )}
        {error === "" && !isLoading && (
          <div
            style={{
              height: "32px",
              width: "40%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems:
                "end" /* Center vertically within the flex container */,
              margin: "10px 10px 10px 10px",
            }}
          >
            <div>
              <Button
                text="Download CSV"
                onClick={() => {
                  setDownloadCSV(true)
                  setTimeout(() => {
                    setDownloadCSV(false)
                  }, 1000)
                }}
              />
              {downloadCSV && (
                <CSVDownload
                  data={_.map(
                    filteredData,
                    _.partialRight(_.pick, ["timestamp", ...selectedFields])
                  )}
                  filename={`powermeter_${selectedPowerMeter}_from_${startDate?.toDateString()}UTC_to_${endDate?.toDateString()}UTC.csv`}
                />
              )}
            </div>
          </div>
        )}
      </FlexRow>
      {error !== "" ? (
        <Box justify="center" margin="10px 10px 10px 10px">
          <p>{error}</p>
        </Box>
      ) : isLoading ? (
        <Loader />
      ) : (
        <ResponsiveContainer width="100%" height="500px" aspect={3}>
          <LineChart
            width={500}
            height={400}
            data={filteredData}
            margin={{
              top: 20,
              right: 5,
              left: 5,
              bottom: 10,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="timestamp" />
            <YAxis />
            <Tooltip />
            <Legend onClick={(event) => toggleField(event.dataKey)} />
            {availableFields.map((field: string, index: number) => (
              <Line
                key={field}
                type="monotone"
                dataKey={field}
                stroke={getRandomColor(index)}
                hide={selectedFields.includes(field) ? false : true}
                dot={false}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      )}
    </FlexCol>
  )
}

export default ChartComponent
