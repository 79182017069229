import { Logo } from "components/Icons/Logo"
import UserMenu from "components/UserMenu/UserMenu"
import React from "react"

import { StyledMenu } from "./Menu.styled"

const Menu = () => (
  <StyledMenu>
    <Logo />
    <UserMenu />
  </StyledMenu>
)

export default Menu
