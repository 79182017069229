import axios from "axios"
import Loader from "components/Loader/Loader"
import { FlexCol } from "components/Shared/FlexCol"
import { SubPaths } from "components/Shared/SubPaths"
import SubMenu from "components/SubMenu/SubMenu"
import Tree from "components/Tree/Tree"
import { ClusterConfigsType, ClusterTableFilter } from "components/types/types"
import { useAppContext } from "helpers/AppProvider"
import { TreeProvider } from "helpers/TreeProvider"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

interface Props {
  id: string
}

const StaticLoadManagement = ({ id }: Props) => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [changed, setChanged] = useState(false)
  const [openClusters, setOpenClusters] = useState<number[]>([])
  const [clusterTableFilter, setClusterTableFilter] = useState<
    ClusterTableFilter[]
  >([])
  const { token } = useAppContext()

  const clusters = () => {
    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/clusterConfigs/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setData(res.data)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    setLoading(true)
    clusters()
    setChanged(false)
  }, [changed])

  const { t } = useTranslation()

  return (
    <FlexCol>
      <SubMenu items={SubPaths()} id={id} />
      <TreeProvider
        openClusters={openClusters}
        setOpenClusters={setOpenClusters}
        clusterTableFilter={clusterTableFilter}
        setClusterTableFilter={setClusterTableFilter}
        setChanged={() => setChanged(true)}
      >
        {!loading ? (
          <Tree id={id} payload={data as ClusterConfigsType} />
        ) : (
          <Loader />
        )}
      </TreeProvider>
    </FlexCol>
  )
}

export default StaticLoadManagement
