import { TableFilters, TableRows } from "components/types/types"
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
} from "react"

interface TableContext {
  setChanged?: Dispatch<SetStateAction<boolean>>
  noAction?: boolean
  notification?: boolean
  power?: boolean
  graphAction?: () => void
  alertAction?: () => void
  showAdjust?: boolean
  deleteAction?: (id?: string) => void
  gwId?: boolean
  openAction?: (id?: string) => void
  popUpData?: (entry: TableRows) => JSX.Element
  searchString?: Dispatch<SetStateAction<string>>
  searchValue?: string
  search?: Dispatch<SetStateAction<string>>
  selectedView?: string
  setSelectedView?: Dispatch<SetStateAction<string>>
  devices?: string[]
  usedDevices?: string[]
  setDevices?: Dispatch<SetStateAction<string[]>>
  selectCellClick?: (payload?: string[]) => void
  priority?: boolean
  setLoading?: Dispatch<SetStateAction<boolean>>
  disabledCheck?: boolean
  oldDevices?: string[]
  setOldDevices?: Dispatch<SetStateAction<string[]>>
  filters?: TableFilters[]
  setFilters?:
    | Dispatch<SetStateAction<TableFilters[]>>
    | ((filters: TableFilters[]) => void)
  gateways?: string[]
  double?: boolean
  drag?: boolean
  noTitle?: boolean
  noSearch?: boolean
  detailsAction?: boolean
  showDetails?: (id: string) => void
}
export const Context = createContext<TableContext>({})

export const TableProvider = ({
  setChanged,
  noAction,
  notification,
  power,
  graphAction,
  alertAction,
  showAdjust,
  deleteAction,
  gwId,
  openAction,
  searchString,
  searchValue,
  search,
  selectedView,
  setSelectedView,
  popUpData,
  devices,
  usedDevices,
  setDevices,
  selectCellClick,
  priority,
  setLoading,
  disabledCheck,
  oldDevices,
  setOldDevices,
  filters,
  setFilters,
  gateways,
  double,
  drag,
  noTitle,
  noSearch,
  detailsAction,
  showDetails,
  children,
}: PropsWithChildren<TableContext>) => {
  const contextProps: TableContext = {
    setChanged,
    noAction,
    notification,
    power,
    graphAction,
    alertAction,
    showAdjust,
    deleteAction,
    gwId,
    openAction,
    popUpData,
    searchString,
    searchValue,
    search,
    selectedView,
    setSelectedView,
    devices,
    usedDevices,
    setDevices,
    selectCellClick,
    priority,
    setLoading,
    disabledCheck,
    oldDevices,
    setOldDevices,
    filters,
    setFilters,
    gateways,
    double,
    drag,
    noTitle,
    noSearch,
    detailsAction,
    showDetails,
  }
  return <Context.Provider value={contextProps}>{children}</Context.Provider>
}

export const useTableContext = () => useContext<TableContext>(Context)
