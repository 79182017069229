import { FlexCol } from "components/Shared/FlexCol"
import { FlexRow } from "components/Shared/FlexRow"
import styled from "styled-components"
export const StyledContainer = styled(FlexCol)`
  flex: 1;
`

export const ContainerContent = styled(FlexRow)`
  flex: 1;
  max-height: 91vh;
`
