import ButtonIcon from "components/Buttons/ButtonIcon"
import { FeCBrainIcon } from "components/Icons/FeCBrain"
import { TableEntryType } from "components/types/types"
import React from "react"
import { primaryDarker } from "styles/colors"

import { StyledRow } from "./Row.styled"

interface Props extends TableEntryType {
  adaptiveChargingStatus: string
  advancedAdaptiveChargingStatus: string
}

const AdaptiveCharginStatusRow = ({
  adaptiveChargingStatus = "inactive",
  advancedAdaptiveChargingStatus = "inactive",
}: Props) => {
  const adaptiveChargingActivated = adaptiveChargingStatus === "active"
  const advancedAdaptiveChargingActivated =
    advancedAdaptiveChargingStatus === "active"
  const adaptiveChargingActive =
    adaptiveChargingActivated || advancedAdaptiveChargingActivated
  const hoverText = [
    `Adaptive Charging: ${
      adaptiveChargingStatus === "disabled"
        ? "inactive"
        : adaptiveChargingStatus
    }`,
    `Advanced Adaptive Charging: ${
      advancedAdaptiveChargingStatus === "disabled"
        ? "inactive"
        : advancedAdaptiveChargingStatus
    }`,
  ]
  return (
    <StyledRow>
      <ButtonIcon
        icon={
          <FeCBrainIcon
            background={adaptiveChargingActive ? "#33B905" : primaryDarker}
          />
        }
        onClick={() => null}
        hoverText={hoverText.join("\n")}
        disableOnHoverColorChange
      />
    </StyledRow>
  )
}

export default AdaptiveCharginStatusRow
