import { FlexCol } from "components/Shared/FlexCol"
import { FlexRow } from "components/Shared/FlexRow"
import styled from "styled-components"
import { primary, secondary, white } from "styles/colors"
import { regular, semibold, xsmall } from "styles/fonts"
import { radius, x2 } from "styles/sizes"

export const StyledTree = styled(FlexCol)`
  border-radius: ${radius};
  overflow: hidden;
  background: transparent;
`

export const TreeHeader = styled(FlexRow)`
  padding: ${x2};
  background: ${primary};
  color: ${white};
  justify-content: space-between;
  align-items: center;
  p {
    font-weight: ${semibold};
    padding: 0;
    margin: 0;
  }
`

export const TreeBody = styled(FlexCol)``

export const MainPoint = styled(FlexRow)`
  background: ${white};
  font-size: ${regular};
  justify-content: space-between;
  align-items: center;
  font-weight: ${semibold};
  border-bottom: 1px solid ${primary};
`

export const SubContainer = styled(FlexCol)``

export const SubPoint = styled(FlexRow)`
  font-size: ${xsmall};
  background: ${white};
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${primary};
`

export const SubFirst = styled.p`
  padding-left: 20px;
  font-size: ${xsmall};
`

export const TreeFooter = styled(FlexRow)`
  padding: ${x2};
  background: ${secondary};
`

export const TreeSubDropdown = styled(FlexRow)`
  align-items: center;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
`
