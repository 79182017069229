import { StyledCardContainer } from "components/Card/Card.styled"
import { Box } from "components/Shared/Box.styled"
import { MainPaths } from "components/Shared/MainPaths"
import SubMenu from "components/SubMenu/SubMenu"
import React from "react"

import ChartComponent from "./ChartComponent"

const MonitoringChart: React.FC<{ id: string }> = ({ id }) => (
  <>
    <SubMenu items={MainPaths()} id={id} main={true} />
    <StyledCardContainer>
      <Box justify="center" width={100}>
        <div style={{ width: "100%", textAlign: "center" }}>
          <ChartComponent
            id={id}
            defaultSelectedFields={[
              "apparentCurrentL1",
              "apparentCurrentL2",
              "apparentCurrentL3",
            ]}
          />
        </div>
      </Box>
    </StyledCardContainer>
  </>
)

export default MonitoringChart
