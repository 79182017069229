import styled, { css } from "styled-components"
import { disabled, primary } from "styles/colors"
import { bold, small } from "styles/fonts"
interface Props {
  active?: boolean
}

export const SubMenuItem = styled.button<Props>`
  padding: 0 20px 20px 0;
  font-weight: ${bold};
  font-size ${small};
  border: none;
  outline: none;
  background: transparent;
  ${(props) =>
    props.active
      ? css`
          color: ${primary};
        `
      : css`
          color: ${disabled};
        `};

  &:hover{
    color: ${primary};
    cursor: pointer;
  }
`
