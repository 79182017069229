import axios from "axios"
import { StyledPowermeterCardContainer } from "components/Card/Card.styled"
import PowermetersCard from "components/Card/PowermeterCard"
import { FlexCol } from "components/Shared/FlexCol"
import { MainPaths } from "components/Shared/MainPaths"
import SubMenu from "components/SubMenu/SubMenu"
import { PowerMetersType } from "components/types/types"
import { useAppContext } from "helpers/AppProvider"
import React, { useEffect, useState } from "react"

interface Props {
  id: string
}

const PowermetersMain = ({ id }: Props) => {
  const [data, setData] = useState<PowerMetersType[]>([])
  const [loading, setLoading] = useState(true)

  const { token } = useAppContext()
  const powermeters = () => {
    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/powerMeters/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setData(res.data)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }
  useEffect(() => {
    powermeters()
    const interval = setInterval(() => {
      powermeters()
    }, 5000)
    return () => clearInterval(interval)
  }, [id, token])

  return (
    <FlexCol>
      <SubMenu items={MainPaths()} id={id} main={true} />
      <StyledPowermeterCardContainer>
        {!loading &&
          data.map((item, index) => (
            <PowermetersCard id={item.id} gwId={id} key={index} />
          ))}
      </StyledPowermeterCardContainer>
    </FlexCol>
  )
}

export default PowermetersMain
