import axios from "axios"
import SelectField from "components/InputField/SelectField"
import { GatewayPayload, TableEntryType } from "components/types/types"
import { useAppContext } from "helpers/AppProvider"
import {
  priorityToNumber,
  priorityToString,
  toastHandler,
} from "helpers/helpers"
import React, { useEffect, useState } from "react"

import { StyledSelectRow } from "./Row.styled"

interface Props extends TableEntryType {
  priority: number
  entry: GatewayPayload
  disabled: boolean
}

const SelectRow = ({ priority, entry, disabled }: Props) => {
  const [selected, setSelected] = useState("")
  const { toasts, setToasts, token } = useAppContext()

  const onChange = (priority: string) => {
    axios
      .put(
        `${process.env.REACT_APP_API_ADDRESS}/chargingPoints/${entry.gwId}/connectors/${entry.id}`,
        {
          config: {
            priority: [priorityToNumber(priority)],
          },
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        toastHandler(toasts, setToasts, true)
      })
      .catch((err) => {
        console.log(err)
        toastHandler(toasts, setToasts, false)
      })
  }

  useEffect(() => {
    setSelected(priorityToString(priority))
  }, [])

  return (
    <StyledSelectRow>
      <SelectField
        disabled={disabled}
        onChange={onChange}
        selected={selected}
        options={["Normal", "Medium", "High"]}
        width={60}
      />
    </StyledSelectRow>
  )
}
export default SelectRow
