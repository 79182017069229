import axios from "axios"
import ButtonIcon from "components/Buttons/ButtonIcon"
import { Adjust } from "components/Icons/Adjust"
import { ArrowDown } from "components/Icons/ArrowDown"
import AddChargingStationPopUp from "components/PopUp/AddChargingStationPopUp"
import PopUp from "components/PopUp/PopUp"
import { Label } from "components/Shared/Label.styled"
import Table from "components/Table/Table"
import { Chargepoint, TableRows } from "components/types/types"
import { useAppContext } from "helpers/AppProvider"
import { pagninationString } from "helpers/helpers"
import { TableProvider } from "helpers/TableProvider"
import { TreeTable } from "helpers/TableStructures"
import { useUserContext } from "helpers/UserContextProvider"
import { updateConnectionStatusBasedOnDuration } from "helpers/utils"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { TreeSubDropdown } from "./Tree.styled"

interface Props {
  gwId: string
  clusterId?: string
}

export const TreeDropdownChargepoints = ({ gwId, clusterId }: Props) => {
  const [show, setShow] = useState(true)
  const [showConfig, setShowConfig] = useState(false)
  const [searchString, setSearchString] = useState("")
  const [search, setSearch] = useState("")
  const [devices, setDevices] = useState<string[]>([])
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<Chargepoint[]>([])

  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalSize, setTotalSize] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const { t } = useTranslation()
  const { token } = useAppContext()

  const getDevices = () => {
    const url = clusterId
      ? `${process.env.REACT_APP_API_ADDRESS}/clusterConfigs/${gwId}/${clusterId}`
      : `${process.env.REACT_APP_API_ADDRESS}/clusterConfigs/${gwId}`

    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setDevices(res.data.devices)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const { userInfo } = useUserContext()
  const isAdmin = userInfo.role === "admin"

  const gateways = () => {
    const formattedArray: string[] = []

    devices.map((item) => {
      formattedArray.push(`"${item}"`)
    })

    const ids = `"ids": [${formattedArray}]`
    const filterById = `"id":"${searchString}"`
    const filterstring = `?filter={${searchString !== "" ? filterById : ""}${
      searchString !== "" ? "," : ""
    }${ids}}`

    axios
      .get(
        `${
          process.env.REACT_APP_API_ADDRESS
        }/chargingPoints/${gwId}/connectors${filterstring}${pagninationString(
          filterstring !== "",
          pageSize,
          pageNumber
        )}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setTotalSize(Number(res.headers["pagination-total"]))
        setTotalPage(Number(res.headers["pagination-totalpages"]))
        const newData: Chargepoint[] = []
        res.data.map((item: Chargepoint) => {
          item.config.physicalLimit.map((limit: number, i: number) => {
            newData.push({
              ...item,
              limit: limit,
              name: item.id + "-" + i,
              index: i,
              priority: item.config.priority[i]
                ? Number(item.config.priority[i])
                : 0,
            })
          })
        })

        setData(
          newData.map(
            isAdmin ? (i) => i : updateConnectionStatusBasedOnDuration
          )
        )
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    gateways()
  }, [search, devices, loading, pageSize, pageNumber, token])

  useEffect(() => {
    getDevices()
  }, [loading, showConfig])

  useEffect(() => {
    setPageNumber && setPageNumber(1)
  }, [pageSize])

  return (
    <>
      <TreeSubDropdown>
        <ButtonIcon
          onClick={() => setShow(!show)}
          rotate={show}
          icon={<ArrowDown small={true} />}
        />
        <Label size={"small"} style={{ marginLeft: "8px" }}>
          {t("Chargepoints") + ` (${data.length})`}
        </Label>
      </TreeSubDropdown>
      {show && (
        <div style={{ padding: "20px" }}>
          <TableProvider
            noTitle={true}
            searchString={setSearchString}
            search={setSearch}
            searchValue={searchString}
            noAction={true}
            devices={devices}
            setLoading={() => setLoading(true)}
            popUpData={() => (
              <AddChargingStationPopUp
                setLoadingParent={() => setLoading(true)}
                gwId={gwId}
              />
            )}
          >
            <Table
              toolbar={true}
              layout={TreeTable}
              title={t("Chargepoints")}
              data={data as TableRows[]}
              updatePath={`${process.env.REACT_APP_API_ADDRESS}/clusterConfigs/${gwId}`}
              selectCell={false}
              addText={"Add/Remove"}
              addIcon={<Adjust />}
              addRow={() => setShowConfig(true)}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              totalSize={totalSize}
              totalPage={totalPage}
              loading={loading}
            />
          </TableProvider>
        </div>
      )}
      {showConfig && (
        <PopUp
          double={true}
          title={t("Charging Stations")}
          setShow={setShowConfig}
        >
          <AddChargingStationPopUp
            setLoadingParent={() => setLoading(true)}
            gwId={gwId}
            clusterId={clusterId}
          />
        </PopUp>
      )}
    </>
  )
}
