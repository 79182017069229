import axios from "axios"
import Button from "components/Buttons/Button"
import ButtonIcon from "components/Buttons/ButtonIcon"
import { ArrowDown } from "components/Icons/ArrowDown"
import { Cross } from "components/Icons/Cross"
import InfoIcon from "components/Icons/InfoMark"
import { Plus } from "components/Icons/Plus"
import CheckboxField from "components/InputField/CheckboxField"
import InputField from "components/InputField/InputField"
import Loader from "components/Loader/Loader"
import PopUp from "components/PopUp/PopUp"
import { Box } from "components/Shared/Box.styled"
import { Label } from "components/Shared/Label.styled"
import { Warning } from "components/Shared/Warning"
import { useAppContext } from "helpers/AppProvider"
import { toastHandler } from "helpers/helpers"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import styled, { css } from "styled-components"
import { black, disabled, inputShadow, primary, white } from "styles/colors"
import { xsmall } from "styles/fonts"
import { radius, x1, x2 } from "styles/sizes"

import { TreeSubDropdown } from "./Tree.styled"

export declare type LimitSchedulePeriod = {
  start: {
    hour: number
    minute: number
  }
  end: {
    hour: number
    minute: number
  }
  limit: number
}

export const DateInput = styled.input<{ disabledStyle?: boolean }>`
  height: 22px;
  background: ${white};
  border: 1px solid ${primary};
  color: ${black};
  padding: ${x1} ${x2};
  border-radius: ${radius};
  font-size: ${xsmall};
  box-shadow: ${inputShadow};

  &:focus {
    outline: 0;
  }

  &:hover {
    cursor: text;
  }

  ${(props) =>
    props.disabledStyle &&
    css`
      pointer-events: none;
      color: ${disabled};
      border: 1px solid ${disabled};
    `};
`

const marginSize = "5px"

const Table = styled.table`
  width: auto;
  border-collapse: collapse;
`

const TableHeader = styled.th`
  padding: ${marginSize};
  text-align: left;
  width: 50px;
`

const TableRow = styled.tr`
  &:not(:last-child) {
    border-bottom: 1px solid ${primary};
  }
`

const TableCell = styled.td`
  padding: ${marginSize};
`

type DaysOfWeek =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday"

// Type for rule which includes the days it applies to and the periods within
type Rule = {
  days: DaysOfWeek[]
  periods: LimitSchedulePeriod[]
}

const daysOfWeek: DaysOfWeek[] = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
]

export type LimitSchedule = {
  [day in DaysOfWeek]: LimitSchedulePeriod[]
}

type LimitRulePeriodComponentProps = {
  period: LimitSchedulePeriod
  updatePeriod: (period: LimitSchedulePeriod) => void
  addNewPeriod: () => void
  removePeriod: () => void
  showAddNewPeriod?: boolean
}

const LimitRulePeriodComponent: React.FC<LimitRulePeriodComponentProps> = ({
  period,
  updatePeriod,
  addNewPeriod,
  removePeriod,
  showAddNewPeriod,
}) => {
  // Helper function to format time as string
  const formatTime = (hour: number, minute: number) => {
    return `${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")}`
  }

  const limitRef = useRef<HTMLInputElement>(null)

  return (
    <TableRow>
      <TableCell>
        <DateInput
          type="time"
          value={formatTime(period.start.hour, period.start.minute)}
          onChange={(e) => {
            const [hour, minute] = e.target.value.split(":")
            updatePeriod({
              ...period,
              start: { hour: Number(hour), minute: Number(minute) },
            })
          }}
        />
      </TableCell>
      <TableCell>
        <DateInput
          type="time"
          value={formatTime(period.end.hour, period.end.minute)}
          onChange={(e) => {
            const [hour, minute] = e.target.value.split(":")
            updatePeriod({
              ...period,
              end: { hour: Number(hour), minute: Number(minute) },
            })
          }}
        />
      </TableCell>
      <TableCell>
        <InputField
          width={50}
          type="number"
          value={period.limit}
          innerRef={limitRef}
          onChange={() => {
            updatePeriod({ ...period, limit: Number(limitRef.current?.value) })
          }}
        />
      </TableCell>
      <TableCell>
        <Box direction="row">
          <ButtonIcon onClick={() => removePeriod()} icon={<Cross />} />
          {showAddNewPeriod && (
            <ButtonIcon onClick={() => addNewPeriod()} icon={<Plus />} />
          )}
        </Box>
      </TableCell>
    </TableRow>
  )
}

type DaysPickerProps = {
  days: string[] // The list of currently picked days
  availableDays: string[] // The list of days that can be selected
  onChange: (newDays: string[]) => void
}

const DaysPicker: React.FC<DaysPickerProps> = ({
  days,
  availableDays,
  onChange,
}) => {
  // Handler for checkbox change
  const handleCheckboxChange = (day: string) => {
    if (days.includes(day)) {
      onChange(days.filter((d) => d !== day)) // Remove day from list
    } else {
      onChange([...days, day]) // Add day to list
    }
  }

  const locallyAvailable = [...days, ...availableDays]

  return (
    <Box direction="row" style={{ flexWrap: "wrap", width: "100%" }}>
      {[
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ].map((day) => (
        <CheckboxField
          key={day}
          label={day.charAt(0).toUpperCase() + day.slice(1)}
          checked={days.includes(day)}
          onClick={() => handleCheckboxChange(day)}
          disabled={!locallyAvailable.includes(day)}
          marginRight={5}
          marginLeft={1}
          size={12}
        />
      ))}
    </Box>
  )
}

type SchedulerRuleComponentProps = {
  rule: Rule
  availableDays: string[]
  ruleNo: number
  changeRule: (rule: Rule) => void
  addPeriod: () => void
  deletePeriod: (periodIndex: number) => void
}

const SchedulerRuleComponent: React.FC<SchedulerRuleComponentProps> = ({
  rule,
  availableDays,
  ruleNo,
  changeRule,
  addPeriod,
  deletePeriod,
}) => {
  return (
    <Box
      direction="column"
      style={{ padding: "15px", marginBottom: "10px", background: "white" }}
    >
      <label style={{ fontSize: 16 }}>Rule {ruleNo}</label>
      <hr />
      <Box>
        <DaysPicker
          days={rule.days}
          availableDays={availableDays}
          onChange={(newDays) =>
            changeRule({ days: newDays as DaysOfWeek[], periods: rule.periods })
          }
        />
      </Box>
      <hr />
      <Box>
        <Table>
          <thead>
            <tr>
              {/* <TableHeader>No.</TableHeader> */}
              <TableHeader>Start Time</TableHeader>
              <TableHeader>End Time</TableHeader>
              <TableHeader>Limit (A)</TableHeader>
              <TableHeader></TableHeader>
            </tr>
          </thead>
          <tbody>
            {rule.periods.map((period, i) => (
              <LimitRulePeriodComponent
                key={`rule-${ruleNo}-period-${i}`}
                period={period}
                updatePeriod={(period) =>
                  changeRule({
                    ...rule,
                    periods: rule.periods.map((p, periodIndex) =>
                      periodIndex === i ? period : p
                    ),
                  })
                }
                addNewPeriod={() => addPeriod()}
                removePeriod={() => deletePeriod(i)}
                showAddNewPeriod={i === rule.periods.length - 1}
              />
            ))}
            {rule.periods.length === 0 && (
              <ButtonIcon onClick={() => addPeriod()} icon={<Plus />} />
            )}
          </tbody>
        </Table>
      </Box>
      <hr />
    </Box>
  )
}

type LimitScheduleConfigProps = {
  rules: Rule[]
  setRules: (updateRules: (rules: Rule[]) => Rule[]) => void
}
const LimitScheduleConfig: React.FC<LimitScheduleConfigProps> = ({
  rules,
  setRules,
}) => {
  const usedDays = rules.flatMap((r) => r.days)
  const availableDays = daysOfWeek.filter((d) => !usedDays.includes(d))
  return (
    <Box direction="column">
      {rules.map((rule, i) => (
        <SchedulerRuleComponent
          key={`rule-${i}`}
          ruleNo={i}
          rule={rule}
          availableDays={availableDays}
          changeRule={(rule: Rule) => {
            setRules((rules) =>
              rules.map((r, index) => (index === i ? rule : r))
            )
          }}
          addPeriod={() =>
            setRules((rules) => {
              const lastPeriod =
                rule.periods.length === 0
                  ? { end: { hour: 0, minute: 0 }, limit: 0 }
                  : rule.periods[rule.periods.length - 1]
              return rules.map((r, index) => {
                if (index === i) {
                  return {
                    days: r.days,
                    periods: [
                      ...r.periods,
                      {
                        start: lastPeriod.end,
                        end: lastPeriod.end,
                        limit: lastPeriod.limit,
                      },
                    ],
                  }
                } else {
                  return r
                }
              })
            })
          }
          deletePeriod={(periodIndex: number) =>
            setRules((rules) =>
              rules.map((r, index) => {
                if (i === index) {
                  return {
                    days: r.days,
                    periods: r.periods.filter((_, j) => j !== periodIndex),
                  }
                }
                return r
              })
            )
          }
        />
      ))}
    </Box>
  )
}

interface ErrorPopupProps {
  setError: (error: string | null) => void // null to close
  error?: string
}

const ErrorPopup: React.FC<ErrorPopupProps> = ({ setError, error }) => {
  return (
    <PopUp title="Error" noConfig setShow={() => setError(null)}>
      <Box direction="column" justify="center" padding="0 0 10px 0">
        <p>{error !== "" ? error : "An error has occured"}</p>
      </Box>
      <Box direction="column" justify="center" padding="0 0 20px 0">
        <Button text="Dismiss" onClick={() => setError(null)} />
      </Box>
    </PopUp>
  )
}

type ValidationResult =
  | {
      valid: true
    }
  | {
      valid: false
      errorReason: string
    }

function validateRules(rules: Rule[]): ValidationResult {
  const usedDays = new Set<DaysOfWeek>()

  for (const rule of rules) {
    // Check for unique days
    for (const day of rule.days) {
      if (usedDays.has(day)) {
        return {
          valid: false,
          errorReason: `Day ${day} is used more than once`,
        }
      }
      usedDays.add(day)
    }

    // Check periods for each rule
    let lastEndTime = { hour: 0, minute: 0 }
    for (let i = 0; i < rule.periods.length; i++) {
      const period = rule.periods[i]
      const isLastElement = i === rule.periods.length - 1
      // Check limit is positive
      if (period.limit && period.limit < 0) {
        return { valid: false, errorReason: "Limit must be a positive number" }
      }

      // Check period order and overlap
      const startTime = period.start.hour * 60 + period.start.minute
      const endTime = period.end.hour * 60 + period.end.minute
      const lastEndTimeInMinutes = lastEndTime.hour * 60 + lastEndTime.minute
      if (isLastElement && endTime === 0) continue
      if (startTime < lastEndTimeInMinutes || endTime < startTime) {
        return { valid: false, errorReason: "Invalid period times" }
      }

      lastEndTime = period.end
    }
  }

  return { valid: true }
}

type Props = {
  gwId: string
  schedule?: LimitSchedule
  clusterId?: string
  timezone?: string
}

const TimezoneInfo = ({ timezone }: { timezone: string }) => {
  const tooltipText = `The current timezone is ${timezone}.\nTo change it, please go to the "General" tab in the "Configuration" menu.`

  return (
    <div style={{ display: "flex", alignItems: "center", margin: "15px" }}>
      <span>
        Timezone: <strong>{timezone}</strong>
      </span>
      <ButtonIcon
        onClick={() => {
          return true
        }}
        disabled
        icon={<InfoIcon />}
        hoverText={tooltipText}
        hoverRight={true}
      />
    </div>
  )
}

export const TreeDropdownStaticSchedule = ({
  gwId,
  clusterId,
  schedule,
  timezone,
}: Props) => {
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const convertScheduleToRules = (schedule?: LimitSchedule): Rule[] => {
    const rules: Rule[] = []

    if (!schedule) return rules

    // Go through each day and create a rule for each period
    Object.keys(schedule).forEach((day) => {
      const daySchedule = schedule[day]
      const dayScheduleRules = daySchedule.length
      if (dayScheduleRules === 0) return
      let rule = rules.find((r) => {
        if (r.periods.length !== dayScheduleRules) return false
        return r.periods.reduce(
          (acc, val, i) =>
            acc &&
            val.start.hour === daySchedule[i].start.hour &&
            val.start.minute === daySchedule[i].start.minute &&
            val.end.hour === daySchedule[i].end.hour &&
            val.end.minute === daySchedule[i].end.minute &&
            val.limit === daySchedule[i].limit,
          true
        )
      })

      // If rule does not exist, create a new one
      if (!rule) {
        rule = { days: [], periods: daySchedule }
        rules.push(rule)
      }
      // Add the day to the rule's days if it's not already there
      if (!rule.days.includes(day as DaysOfWeek)) {
        rule.days.push(day as DaysOfWeek)
      }
    })

    return rules
  }

  const convertRulesToSchedule = (rules?: Rule[]): LimitSchedule => {
    const schedule: LimitSchedule = {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    }
    if (!rules) return schedule

    rules.forEach((rule) => {
      rule.days.forEach((day) => {
        schedule[day] = rule.periods
      })
    })

    // Ensure the periods within each day are sorted by start time
    Object.keys(schedule).forEach((day) => {
      schedule[day].sort((a: LimitSchedulePeriod, b: LimitSchedulePeriod) => {
        return a.start.hour !== b.start.hour
          ? a.start.hour - b.start.hour
          : a.start.minute - b.start.minute
      })
    })

    return schedule
  }
  const [rules, setRules] = useState<Rule[]>(convertScheduleToRules(schedule))
  const [previousRules, setPreviousRules] = useState<Rule[]>([])
  const [changed, setChanged] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const { token, toasts, setToasts } = useAppContext()
  const { t } = useTranslation()

  const getClusterConfigURL = (gwId: string, clusterId?: string) => {
    return clusterId
      ? `${process.env.REACT_APP_API_ADDRESS}/clusterConfigs/${gwId}/${clusterId}`
      : `${process.env.REACT_APP_API_ADDRESS}/clusterConfigs/${gwId}`
  }

  useEffect(() => {
    const validationResult = validateRules(rules)
    if (validationResult.valid) {
      setPreviousRules(rules)
    }
  }, [rules])

  const updateSchedule = () => {
    setLoading(true)
    const validationResult = validateRules(rules)
    if (!validationResult.valid) {
      setError(validationResult.errorReason)
      setRules(previousRules)
      setLoading(false)
      return
    } else {
      setPreviousRules(rules)
    }
    const newSchedule = convertRulesToSchedule(rules)
    axios
      .put(
        getClusterConfigURL(gwId, clusterId),
        {
          staticSchedule: {
            schedule: newSchedule,
            ...(clusterId ? {} : { timezone: timezone || "UTC" }),
          },
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        toastHandler(toasts, setToasts, true)
        setChanged(false)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        toastHandler(toasts, setToasts, false)
        setLoading(false)
      })
  }

  return (
    <>
      <TreeSubDropdown
        style={{
          marginBottom: "20px",
        }}
      >
        <ButtonIcon
          onClick={() => setShow(!show)}
          rotate={show}
          icon={<ArrowDown small={true} />}
        />
        <Label size={"small"} style={{ marginLeft: "8px" }}>
          {t("Static Limit Schedule")}
        </Label>
      </TreeSubDropdown>

      {show && (
        <>
          {!loading ? (
            <div
              style={{
                margin: "0 20px 20px 20px",
              }}
            >
              <Box direction="column" width={100} justify="space-between">
                <Box
                  width={50}
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  <TimezoneInfo timezone={timezone || "UTC"} />
                </Box>

                <LimitScheduleConfig
                  rules={rules}
                  setRules={(update) => {
                    setRules(update)
                    setChanged(true)
                  }}
                />
                <ButtonIcon
                  onClick={() => {
                    const newRules = (r: Rule[]) => [
                      ...r,
                      { days: [], periods: [] },
                    ]
                    setPreviousRules(newRules)
                    setRules(newRules)
                    setChanged(true)
                  }}
                  icon={<Plus />}
                  text={<p>{t("Add Rule")}</p>}
                />
                {changed && (
                  <Box
                    align="center"
                    padding="20px"
                    margin="5px"
                    style={{
                      background: "white",
                      height: "auto",
                      justifyContent: "space-between",
                    }}
                  >
                    <Warning style={{ left: "20px" }}>
                      {t("Unsaved changes.")}
                    </Warning>
                    <div style={{ width: "60%" }}></div>{" "}
                    <Button
                      onClick={() => updateSchedule()}
                      text={t("Save Changes")}
                      margin="0 20px 0 0"
                    />
                  </Box>
                )}
              </Box>
            </div>
          ) : (
            <Loader />
          )}
        </>
      )}
      {error !== null && (
        <ErrorPopup
          setError={(error: string | null) => setError(error)}
          error={error}
        />
      )}
    </>
  )
}
