import axios from "axios"
import Button from "components/Buttons/Button"
import InputField from "components/InputField/InputField"
import Loader from "components/Loader/Loader"
import { Box } from "components/Shared/Box.styled"
import { SubClusterType } from "components/types/types"
import { useAppContext } from "helpers/AppProvider"
import { toastHandler } from "helpers/helpers"
import { usePopUpContext } from "helpers/PopupProvider"
import { useTreeContext } from "helpers/TreeProvider"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"

interface Props {
  entry?: SubClusterType
  id: string
  post?: boolean
  mainClusterId?: string
}

const SubLevelConfigurationPopup = ({
  entry,
  id,
  post,
  mainClusterId,
}: Props) => {
  const [loading, setLoading] = useState(false)
  const { setShow } = usePopUpContext()
  const { t } = useTranslation()
  const maximumCurrent = useRef<HTMLInputElement | null>(null)
  const subLevelName = useRef<HTMLInputElement | null>(null)
  const { setChanged } = useTreeContext()
  const { toasts, setToasts, token } = useAppContext()

  const updateSubLevel = () => {
    setLoading(true)
    axios
      .put(
        `${process.env.REACT_APP_API_ADDRESS}/clusterConfigs/${id}/${
          entry && entry.id
        }`,
        {
          maximumCurrent: maximumCurrent.current?.value,
          name: subLevelName.current?.value,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        setShow(false)
        setChanged(true)
        setLoading(false)
        toastHandler(toasts, setToasts, true)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        toastHandler(toasts, setToasts, false)
      })
  }

  const createSubLevel = () => {
    setLoading(true)
    axios
      .post(
        `${process.env.REACT_APP_API_ADDRESS}/clusterConfigs/${id}/${mainClusterId}`,
        {
          devices: [],
          maximumCurrent: maximumCurrent.current?.value,
          name: subLevelName.current?.value,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        setShow(false)
        setChanged(true)
        setLoading(false)
        toastHandler(toasts, setToasts, true)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        toastHandler(toasts, setToasts, true)
      })
  }

  return (
    <>
      {!loading ? (
        <>
          <Box
            padding="14px 0 0 0"
            direction="row"
            align="self-end"
            justify="space-between"
          >
            <InputField
              label={t("Name")}
              type="string"
              placeholder={t("Main Supply Level")}
              width={100}
              innerRef={subLevelName}
              value={entry && entry.name}
            />
          </Box>
          <Box direction="row" justify="space-between" padding="14px 0 14px 0">
            <InputField
              type="number"
              label={t("Maximum Current Limit (A): L1, L2, L3")}
              placeholder="100"
              innerRef={maximumCurrent}
              value={entry && entry.maximumCurrent}
              width={100}
            />
          </Box>
          <hr />
          <Box justify="flex-end">
            <Button
              secondary={true}
              onClick={() => setShow(false)}
              text={t("Cancel")}
            />
            <Button
              margin="0 0 0 20px"
              onClick={() => (post ? createSubLevel() : updateSubLevel())}
              text={t("Save")}
            />
          </Box>
        </>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default SubLevelConfigurationPopup
