import axios from "axios"
import Button from "components/Buttons/Button"
import InputField from "components/InputField/InputField"
import Loader from "components/Loader/Loader"
import { Box } from "components/Shared/Box.styled"
import { ClusterConfigsType } from "components/types/types"
import { useAppContext } from "helpers/AppProvider"
import { toastHandler } from "helpers/helpers"
import { usePopUpContext } from "helpers/PopupProvider"
import { useTreeContext } from "helpers/TreeProvider"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"

interface Props {
  entry: ClusterConfigsType
  id: string
}

const MainLevelConfigurationPopup = ({ entry, id }: Props) => {
  //const [showMore, setShowMore] = useState(false)
  const [loading, setLoading] = useState(false)
  const { setShow } = usePopUpContext()
  const { t } = useTranslation()
  const maximumCurrent = useRef<HTMLInputElement | null>(null)
  const mainLevelName = useRef<HTMLInputElement | null>(null)
  const distributionScheme = useRef<HTMLSelectElement | null>(null)
  const { toasts, setToasts, token } = useAppContext()

  const { setChanged } = useTreeContext()
  const updateMainLevel = () => {
    setLoading(true)
    axios
      .put(
        `${process.env.REACT_APP_API_ADDRESS}/clusterConfigs/${id}`,
        {
          maximumCurrent: maximumCurrent.current?.value,
          name: mainLevelName.current?.value,
          distributionScheme: distributionScheme.current?.value,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        setShow(false)
        setChanged(true)
        setLoading(false)
        toastHandler(toasts, setToasts, true)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        toastHandler(toasts, setToasts, false)
      })
  }

  return (
    <>
      {!loading ? (
        <>
          <Box
            padding="14px 0 0 0"
            direction="row"
            align="self-end"
            justify="space-between"
          >
            <InputField
              label={t("Name")}
              type="text"
              placeholder={t("Main Supply Level")}
              value={entry.name}
              innerRef={mainLevelName}
              width={100}
            />
          </Box>
          {/* <Box
            padding="14px 0 0 0"
            direction="row"
            align="self-end"
            justify="space-between"
          >
            <SelectField
              label={t("Distribution Scheme")}
              placeholder={t("Select Backend System")}
              options={["Equal-Share"]}
              selected={entry.distributionScheme}
              innerRef={distributionScheme}
              width={100}
            />
          </Box> */}
          <Box direction="row" justify="space-between" padding="14px 0 14px 0">
            <InputField
              type="number"
              label={t("Maximum Current Limit (A): L1, L2, L3")}
              placeholder="100"
              value={entry.maximumCurrent}
              innerRef={maximumCurrent}
              width={100}
            />
          </Box>
          <hr />
          <Box justify="flex-end">
            <Button
              secondary={true}
              onClick={() => setShow(false)}
              text={t("Cancel")}
            />
            <Button
              margin="0 0 0 20px"
              onClick={() => updateMainLevel()}
              text={t("Save")}
            />
          </Box>
        </>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default MainLevelConfigurationPopup
