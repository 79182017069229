import { TableEntryType } from "components/types/types"
import { currentDecimals } from "helpers/helpers"
import React from "react"

import { StyledRow } from "./Row.styled"

interface Props extends TableEntryType {
  text: string | string[]
  measure?: string
}

const Row = ({ text, measure }: Props) => {
  return (
    <StyledRow>
      <p>
        {measure ? currentDecimals(Number(text)) : text} {measure}
      </p>
    </StyledRow>
  )
}

export default Row
