import styled, { css } from "styled-components"
import {
  black,
  disabled,
  inputShadow,
  primary,
  secondary,
  white,
} from "styles/colors"
import { xsmall } from "styles/fonts"
import { radius, x1, x2 } from "styles/sizes"
import { border } from "styles/styles"

interface Props {
  padding?: number
  disabledStyle?: boolean
}

export const StyledInputField = styled.input<Props>`
  height: 22px;
  background: ${white};
  border: 1px solid ${primary};
  color: ${black};
  padding: ${x1} ${x2};
  border-radius: ${radius};
  font-size: ${xsmall};
  box-shadow: ${inputShadow}

  &:focus {
    outline: 0;
  }
  &:hover {
    cursor: text;
  }

  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `};

  ${(props) =>
    props.disabledStyle &&
    css`
      pointer-events: none;
      color: ${disabled};
      border: 1px solid ${disabled};
    `};
`

export const StyledSelectField = styled.select<Props>`
  width: 100%;
  height: 32px;
  box-shadow: ${inputShadow};
  background: ${white};
  border: 1px solid ${primary};
  color: ${black};
  padding: ${x1} ${x2};
  border-radius: ${radius};
  font-size: ${xsmall};

  &:focus {
    outline: 0;
  }
  &:hover {
    cursor: pointer;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.2;
      box-shadow: none;
      border: 1px solid ${disabled};
      pointer-events: none;
    `};
`

export const StyledSearchField = styled.input`
  height: 32px;
  background: ${white};
  box-shadow: ${inputShadow};
  border: 1px solid ${primary};
  color: ${black};
  padding: ${x1} ${x2};
  border-radius: ${radius};
  font-size: ${xsmall};

  &:focus {
    outline: 0;
  }
  &:hover {
    cursor: text;
  }
`

export const StyledCheckboxField = styled.input<Props>`
  background: ${(props) => (props.checked ? white : secondary)};
  border: 1px solid ${primary};
  color: ${black};
  padding: ${x1} ${x2};
  border-radius: ${radius};
  font-size: ${xsmall};
  margin-top: 7px;
  width: 16px;
  height: 16px;

  &:focus {
    outline: 0;
  }

  &:hover {
    background: ${secondary};
    cursor: pointer;
  }

  ${(props) =>
    props.disabledStyle &&
    css`
      color: black;
      background: black;
      pointer-events: none;
    `};
`
export const StyledSelectPaginate = styled.select`
  background: ${primary};
  border: ${border};

  color: ${white};
  height: 30px;
  margin: ${x1} 2px;
  border-radius: ${radius};
  font-size: ${xsmall};
  border: 1px solid ${primary};
  background: ${white};
  color: ${primary};
  &:hover {
    background: ${primary};
    color: ${white};
    cursor: pointer;
  }
`

export const StyledGatewaySearchField = styled.input`
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid ${primary};
  width: 100%;
  &:focus {
    outline: none;
  }
`
