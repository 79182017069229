import { States, Status } from "components/types/types"
import styled, { css } from "styled-components"
import { green, red } from "styles/colors"
import { bold, xsmall } from "styles/fonts"
interface Props {
  state?: States
  status?: Status
  cpms?: boolean
  width?: number
}

export const StyledRow = styled.td<Props>`
  p {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: ${xsmall};
  }
`

export const StyledStatusRow = styled(StyledRow)<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  div {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
    margin-left: 10px;
  }

  p {
    margin-left: 10px;
  }

  ${(props) =>
    props.status === "connected" &&
    css`
      div {
        background: ${green};
      }
    `};
  ${(props) =>
    props.status === "disconnected" &&
    css`
      div {
        background: ${red};
      }
    `};
  ${(props) =>
    props.status === undefined &&
    css`
      div {
        background: ${red};
      }
    `};
`

export const StyledCpmsRow = styled(StyledRow)<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  div {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
    margin-left: 10px;
  }

  p {
    margin-left: 10px;
  }

  ${(props) =>
    props.cpms &&
    css`
      div {
        background: ${green};
      }
    `};
  ${(props) =>
    !props.cpms &&
    css`
      div {
        background: ${red};
      }
    `};
`

export const StyledBoldRow = styled(StyledRow)`
  p {
    font-weight: ${bold};
  }
`

export const StyledDateTimeRow = styled(StyledRow)`
  p {
    margin-left: 10px;
  }
`

export const StyledCheckRow = styled(StyledRow)``

export const StyledSelectRow = styled(StyledRow)`
  padding: 0 10px 0 10px;
`
