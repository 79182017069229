import { Box } from "components/Shared/Box.styled"
import React from "react"
import styled from "styled-components"
import { darkGray } from "styles/colors"

const StyledField = styled.span`
  color: ${darkGray};
  display: flex;
  flex-direction: row;
  align-items: center;

  select {
    border: none;
    margin-right: 7px;
  }
  p {
    padding: 0;
    margin: 0 9px 0 0;
  }
`

const TextField = ({
  label,
  data,
  renderCondition,
}: {
  label: string
  data?: string
  renderCondition?: (data: string) => boolean
}) => {
  const renderConditionValue =
    renderCondition && data ? renderCondition(data) : true
  const render = data && data != "" && renderConditionValue
  return render ? (
    <Box direction="row" justify="space-between">
      <StyledField>
        <p>{label}</p>
      </StyledField>
      <StyledField>
        <p>{data} </p>
      </StyledField>
    </Box>
  ) : (
    <></>
  )
}

export default TextField
