import React from "react"

import { ContainerContent, StyledContainer } from "./Container.styled"
type Props = {
  children: React.ReactNode
}
const Container = ({ children }: Props) => (
  <StyledContainer>
    <ContainerContent> {children}</ContainerContent>
  </StyledContainer>
)

export default Container
