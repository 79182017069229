import styled, { css } from "styled-components"
import { disabled, primary, primaryDarker, white } from "styles/colors"
import { semibold, small, xsmall } from "styles/fonts"
import { radius, x1, x2 } from "styles/sizes"
import { border } from "styles/styles"

interface Props {
  width?: string
  margin?: string
  rotate?: number
  secondary?: boolean
  small?: boolean
  active?: boolean
  disableOnHoverColorChange?: boolean
}

export const StyledButton = styled.input<Props>`
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `};

  ${(props) =>
    css`
      background: ${props.color ? props.color : primary};
    `};

  border: ${border};

  color: ${white};
  padding: ${x1} ${x2};
  border-radius: ${radius};
  font-size: ${xsmall};
  &:focus {
    outline: 0;
  }
  &:hover {
    background: ${primaryDarker};
    cursor: pointer;
  }

  ${(props) =>
    props.secondary &&
    css`
      border: 1px solid ${primary};
      background: ${white};
      color: ${primary};
      &:hover {
        background: ${primary};
        color: ${white};
        cursor: pointer;
      }
    `};

  ${(props) =>
    props.active &&
    css`
      background: ${primary};
      color: ${white};
      cursor: pointer;
    `};
`

export const ButtonStyle = styled.button`
  padding: ${x1} ${x2};

  &:focus {
    outline: 0;
  }
  &:hover {
    cursor: pointer;
  }
`

export const StyledButtonIcon = styled(ButtonStyle)<Props>`
  background: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    margin-left: 10px;
    font-size: ${xsmall};
  }
  ${(props) =>
    !props.disableOnHoverColorChange
      ? css`
          &:hover svg path {
            fill: ${primary};
          }
        `
      : css`
          pointer-events: none;
          cursor: none;
        `};

  ${(props) =>
    props.small &&
    css`
      p {
        font-size: ${small};
        font-weight: ${semibold};
      }
      padding: 0;
    `};

  ${(props) =>
    props.rotate === 1 &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `};

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      cursor: none;
      svg path {
        fill: ${disabled};
      }
    `};

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};
`

export const StyledButtonDropdown = styled(StyledButtonIcon)`
  display: flex;
  align-items: center;

  p {
    padding-right: 10px;
    font-size: ${xsmall};
  }
`

export const StyledButtonPaginate = styled.input<Props>`
  background: ${primary};
  border: ${border};

  color: ${white};
  height: 30px;
  width: 30px;
  margin: ${x1} 2px;
  border-radius: ${radius};
  font-size: ${xsmall};
  border: 1px solid ${primary};
  background: ${white};
  color: ${primary};
  &:hover {
    background: ${primary};
    color: ${white};
    cursor: pointer;
  }

  ${(props) =>
    props.active &&
    css`
      height: 35px;
      background: ${primary};
      color: ${white};
      cursor: pointer;
      font-size: ${xsmall};
    `};
`
