import axios from "axios"
import Button from "components/Buttons/Button"
import InputField from "components/InputField/InputField"
import { Box } from "components/Shared/Box.styled"
import { Label } from "components/Shared/Label.styled"
import { useAppContext } from "helpers/AppProvider"
import { toastHandler } from "helpers/helpers"
import { usePopUpContext } from "helpers/PopupProvider"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"

const AddInfrastructurePopUp = () => {
  const [step, setStep] = useState<number>(0)
  const [error, setError] = useState<string>("")
  const serialNumberRef = useRef<HTMLInputElement>(null)
  const infrastructureNameRef = useRef<HTMLInputElement>(null)

  const cloudNameRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()
  const { toasts, setToasts, token } = useAppContext()
  const { setShow } = usePopUpContext()

  const registerSCG = () => {
    axios
      .put(
        `${process.env.REACT_APP_API_ADDRESS}/registerSCG`,
        {
          serialNumber: serialNumberRef?.current?.value || "",
          name: infrastructureNameRef?.current?.value || "",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        setError("")
        toastHandler(toasts, setToasts, true)
        setShow(false)
      })
      .catch((err) => {
        setError(err.response.data)
        toastHandler(toasts, setToasts, false)
      })
  }

  const registerCloud = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_ADDRESS}/createCloudSCG`,
        {
          name: cloudNameRef?.current?.value || "",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        toastHandler(toasts, setToasts, true)
      })
      .catch((err) => {
        console.log(err)
        toastHandler(toasts, setToasts, false)
      })
  }

  const buttonAction = (step: number) => {
    if (step === 1) {
      registerSCG()
    } else if (step === 2) {
      registerCloud()
      setStep(3)
    } else if (step === 3) {
      setShow(false)
    }
  }

  return (
    <>
      {step === 0 && (
        <Box direction="column" padding="20px">
          <Button
            text={t("Register a Gateway")}
            onClick={() => setStep(1)}
            margin="0 0 20px 0"
          />
          <Button
            text={t("Creating a cloud-based infrastructure")}
            onClick={() => setStep(2)}
            margin="0 0 20px 0"
          />
          <Button secondary text={t("Cancel")} onClick={() => setShow(false)} />
        </Box>
      )}
      {step === 1 && (
        <Box direction="column">
          <Label>{t("Register a Gateway")}</Label>
          <p>
            {t(
              "Please note, that you will be invoiced for the infrastructure from the time when the Gateway CONNECT will be registered"
            )}
          </p>
          <InputField
            type="text"
            label={t("Enter Gateway Connect Serial Number")}
            innerRef={serialNumberRef}
            placeholder="GWxxxxxx"
          />
          <InputField
            type="text"
            label={t("Enter Name")}
            innerRef={infrastructureNameRef}
            placeholder={t("Your New Infrastructure Name")}
          />
          {error && (
            <Label error padding="20px 0 0 0">
              {/* {t("Error: Gateway not found!")} */}
              {t("Error")}: {error}!
            </Label>
          )}
        </Box>
      )}
      {step === 2 && (
        <Box direction="column">
          <Label padding="0 0 20px 0">
            {t("Create a new cloud based infrastructure")}
          </Label>
          <InputField
            type="text"
            label={t("Enter Name")}
            innerRef={cloudNameRef}
            placeholder={t("Your New Infrastructure Name")}
          />
        </Box>
      )}
      {step === 3 && (
        <Box direction="column">
          <Label padding="0 0 20px 0">{t("Information")}</Label>
          <p>
            {t(
              "FLEXeCharge support has been contacted regarding your request for a new cloud-based infrastructure. The infrastructure will be ready for you in less than 24 hours."
            )}
          </p>
        </Box>
      )}
      {step !== 0 && (
        <Box justify="flex-end" padding="20px 0 0 0">
          <>
            {step !== 3 && (
              <Button
                secondary={true}
                onClick={() => setShow(false)}
                text={t("Cancel")}
              />
            )}
            <Button
              margin="0 0 0 20px"
              onClick={() => buttonAction(step)}
              text={step !== 3 ? t("Register") : t("Finish")}
            />
          </>
        </Box>
      )}
    </>
  )
}

export default AddInfrastructurePopUp
