import SearchField from "components/InputField/SearchField"
import SelectField from "components/InputField/SelectField"
import { Box } from "components/Shared/Box.styled"
import { useTableContext } from "helpers/TableProvider"
import React, { useRef } from "react"
import { primary } from "styles/colors"
import { semibold, xsmall } from "styles/fonts"
import { radius } from "styles/sizes"

interface Props {
  text: string
}

const TableToolbar = ({ text }: Props) => {
  const { selectedView, setSelectedView, setLoading } = useTableContext()
  const selected = useRef<HTMLSelectElement | null>(null)

  const { filters, noTitle, noSearch } = useTableContext()
  const onChangeSelected = () => {
    setSelectedView &&
      setSelectedView(selected.current?.value || "Show Selected")
    setLoading && setLoading(true)
  }
  return (
    <Box
      direction="row"
      align="center"
      padding="0 10px 10px 0 "
      justify="space-between"
    >
      <p
        style={{
          fontSize: xsmall,
          fontWeight: semibold,
        }}
      >
        {!noTitle && text}{" "}
        {filters &&
          filters.map((item, i) => (
            <span
              style={{
                border: `1px solid ${primary}`,
                color: primary,
                padding: "5px",
                margin: "5px",
                borderRadius: radius,
                fontSize: xsmall,
              }}
              key={i}
            >
              {item.value}
            </span>
          ))}
      </p>
      {!noSearch && <SearchField />}
      {selectedView && (
        <SelectField
          onChange={onChangeSelected}
          selected={selectedView}
          innerRef={selected}
          options={["Show All", "Show Selected", "Show Not Selected"]}
        />
      )}
    </Box>
  )
}

export default TableToolbar
