import React from 'react';
import { black, primary } from 'styles/colors'

const InfoIcon = ({ color = black, size = '12' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size} // Accept size as a prop
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{ position: 'relative', top: '-4px' }}
  >
    <circle cx="12" cy="12" r="10" />
    <text x="12" y="12" fontFamily='serif' fontSize="15" fontWeight="n" textAnchor="middle" stroke="none" fill={color} dy=".3em">i</text>
  </svg>
);

export default InfoIcon;