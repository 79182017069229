import { FlexCol } from "components/Shared/FlexCol"
import { FlexRow } from "components/Shared/FlexRow"
import styled, { css } from "styled-components"
import { lightGray, primary, shadow, white } from "styles/colors"
import { lg, md, xlg, xmd } from "styles/viewports"

interface Props {
  offline?: boolean
  marginBottom?: number
}

export const StyledCardContainer = styled(FlexCol)<Props>`
  padding: 32px;
  border-radius: 12px;
  box-shadow: ${shadow};
  background: ${white};
  ${(props) =>
    props.offline &&
    css`
      background: ${lightGray};
      opacity: 0.5;
    `}
  margin-bottom: 30px;
`

export const StyledDashboardCard = styled.div<Props>`
  padding: 20px 20px 20px;
  border-radius: 12px;
  box-shadow: ${shadow};
  background: ${white};

  ${(props) =>
    props.offline &&
    css`
      background: ${lightGray};
      opacity: 0.5;
    `}
  ${(props) =>
    css`
      margin-bottom: ${props.marginBottom || 30}px;
    `}
`

export const StyledDashboardCardContainer = styled.div<{
  gridRowGap?: number
  gridColumnGap?: number
}>`
  display: grid;
  ${(props) => css`
    grid-row-gap: ${props.gridRowGap || 30}px;
    grid-column-gap: ${props.gridColumnGap || 30}px;
  `}
  grid-template-columns: 49% 49%;

  @media (max-width: ${lg}) {
    grid-template-columns: 49% 49%;
  }

  @media (max-width: ${xmd}) {
    grid-template-columns: 100%;
  }

  @media (min-width: ${xlg}) {
    grid-template-columns: 32% 32% 32%;
  }
`

export const StyledPowermeterCardContainer = styled.div`
  display: grid;
  grid-template-columns: 424px 424px;
  grid-gap: 30px;

  @media (max-width: ${lg}) {
    grid-template-columns: 49% 49%;
  }

  @media (max-width: ${md}) {
    grid-template-columns: 100%;
  }
`
export const CardTable = styled.span`
  overflow-x: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 1px;
    height: 1px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`

export const CardRow = styled(FlexRow)`
  justify-content: space-between;
  padding-top: 20px;
`

export const CardCol = styled(FlexCol)`
  width: 32%;
  div:first-child {
    border: 1px solid ${primary};
    background: ${primary};
    color: white;
  }

  div {
    border: 1px solid ${primary};
    border-left: 2px solid ${primary};
    border-right: 2px solid ${primary};
  }

  div:last-child {
    border-bottom: 2px solid ${primary};
  }
`
