import { Box } from "components/Shared/Box.styled"
import { Label } from "components/Shared/Label.styled"
import React, { useEffect, useState } from "react"

import { StyledSelectField } from "./InputField.styled"

interface Props {
  label?: string
  placeholder?: string
  options: string[]
  width?: number
  selected?: string
  onChange?: (priority: string) => void
  innerRef?: React.MutableRefObject<HTMLSelectElement | null>
  disabled?: boolean
  className?: string
  row?: boolean
}
const SelectField = ({
  label,
  placeholder,
  options,
  width,
  selected,
  onChange,
  innerRef,
  disabled,
  className,
  row,
}: Props) => {
  const [currentSelected, setSelected] = useState("")

  useEffect(() => {
    setSelected(String(selected))
  }, [selected])

  const onChangeEvent = (value: string) => {
    setSelected(value)
    onChange && onChange(value)
  }

  return (
    <Box
      direction={!row ? "column" : "row"}
      align={row ? "center" : "start"}
      style={{}}
      width={width}
    >
      {label && (
        <Label
          size="small"
          style={{ textAlign: "left", marginRight: row ? "10px" : 0 }}
        >{`${label}`}</Label>
      )}
      <StyledSelectField
        className={className}
        ref={innerRef}
        onChange={(event) => onChangeEvent(event.target.value)}
        placeholder={placeholder}
        value={currentSelected}
        disabled={disabled}
      >
        {options.map((option: string, i: number) => (
          <option key={option + i}>{option}</option>
        ))}
      </StyledSelectField>
    </Box>
  )
}

export default SelectField
