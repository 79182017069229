import { FlexCol } from "components/Shared/FlexCol"
import { FlexRow } from "components/Shared/FlexRow"
import styled, { css } from "styled-components"
import { black, lightGray, primary, white } from "styles/colors"
import { bold, medium, xsmall } from "styles/fonts"
import { radius } from "styles/sizes"

interface Props {
  active: boolean
}

export const StyledSideMenu = styled(FlexCol)`
  background: ${white};
  min-width: 112px;
  min-height: 100%;
`

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
`

export const ListItem = styled.li`
  opacity: 0.7;
  p {
    font-size: ${medium};
    font-weight: ${bold};
  }

  &:hover {
    cursor: pointer;
  }

  &:hover p {
    color: ${primary};
  }

  &:hover svg path {
    fill: ${primary};
  }
`

export const SideMenuListItem = styled(ListItem)<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;

  margin: 15px;
  border-radius: ${radius};
  p {
    font-size: ${xsmall};
    font-weight: ${bold};
    text-align: center;
    margin: 0;
  }

  &:hover {
    opacity: 1;
    cursor: pointer;
  }

  &:hover p {
    color: ${primary};
  }

  &:hover svg path {
    fill: ${primary};
  }

  &:hover svg circle {
    fill: ${primary};
  }

  ${(props) =>
    props.active &&
    css`
      opacity: 1;
      background: ${lightGray};
      svg path {
        fill: ${primary};
      }
      svg circle {
        fill: ${primary};
      }
      &:hover p {
        color: ${black};
      }
    `};
`

export const GatewayLink = styled(FlexRow)`
  align-items: center;
  white-space: nowrap;
  padding: 0 20px;

  :hover {
    background: ${lightGray} !important;
  }
`
