export const big = "21px"
export const regular = "18px"
export const medium = "16px"
export const small = "14px"
export const xsmall = "12px"
export const xxsmall = "8px"

export const bold = "700"
export const semibold = "500"
export const standard = "300"
