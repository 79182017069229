import React from "react"

import { StyledButtonPaginate } from "./Button.styled"

interface Props {
  text: string
  onClick: () => void
  active: boolean
}

const ButtonPaginate = ({ text, onClick, active }: Props) => (
  <StyledButtonPaginate
    type="button"
    onClick={onClick}
    value={text}
    active={active}
  />
)

export default ButtonPaginate
