import { Box } from "components/Shared/Box.styled"
import { Grid } from "components/Shared/Grid.styled"
import React from "react"
import { useTranslation } from "react-i18next"
import {
  available,
  charging,
  faulted,
  finishing,
  preparing,
  primary,
  suspendedEV,
  suspendedEVSE,
  unavailable,
} from "styles/colors"
import { small } from "styles/fonts"

interface Props {
  text: string
  value: number
}
const StatusIndicator = ({ text, value }: Props) => {
  const { t } = useTranslation()
  const stateColorFomatter = () => {
    if (text === t("Available")) {
      return available
    } else if (text === t("Preparing")) {
      return preparing
    } else if (text === t("Charging")) {
      return charging
    } else if (text === t("Finishing")) {
      return finishing
    } else if (text === t("Suspended EV")) {
      return suspendedEV
    } else if (text === t("Suspended EVSE")) {
      return suspendedEVSE
    } else if (text === t("Faulted")) {
      return faulted
    } else {
      return unavailable
    }
  }
  return (
    <Grid>
      <Box direction="row" align="center" margin="2px 0 0 0 ">
        <div
          style={{
            height: "12px",
            width: "18px",
            background: stateColorFomatter(),
            marginRight: "10px",
          }}
        ></div>
        <span style={{ fontSize: small }}>{text}</span>
      </Box>
      <span style={{ fontSize: small, color: primary }}>{value}</span>
    </Grid>
  )
}

export default StatusIndicator
