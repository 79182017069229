import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
} from "react"

interface PopUpContext {
  setShow: Dispatch<SetStateAction<boolean>>
}
export const Context = createContext<PopUpContext>({
  setShow: () => "",
})

export const PopUpProvider = ({
  setShow,
  children,
}: PropsWithChildren<PopUpContext>) => {
  const contextProps: PopUpContext = {
    setShow,
  }
  return <Context.Provider value={contextProps}>{children}</Context.Provider>
}

export const usePopUpContext = () => useContext<PopUpContext>(Context)
