import { useUserContext } from "helpers/UserContextProvider"
import React from "react"
import Infrastructures from "Views/Infrastructures/Infrastructures"
import UserManagement from "Views/UserManagement/UserManagement"
import UserSettings from "Views/UserSettings/UserSettings"
import { Route } from "wouter"

import { StyledContent } from "./Content.styled"

type RouteProps = {
  path: string
  component: React.ComponentType
}

const verifiedUserRoutes: RouteProps[] = [
  {
    path: "/infrastructure/:rest*",
    component: Infrastructures,
  },
]

const groupAdminRoutes: RouteProps[] = [
  {
    path: "/userManagement",
    component: UserManagement,
  },
]

const allUserRoutes: RouteProps[] = [
  {
    path: "/userSettings",
    component: UserSettings,
  },
]

const Content = () => {
  const { userInfo } = useUserContext()
  const routes: RouteProps[] = [
    ...(userInfo.approved ? verifiedUserRoutes : []),
    ...(userInfo.groups[0]?.role === "admin" ? groupAdminRoutes : []),
    ...allUserRoutes,
  ]
  return (
    <StyledContent>
      {routes.map((route, i) => (
        <Route key={i} path={route.path}>
          <route.component />
        </Route>
      ))}
    </StyledContent>
  )
}

export default Content
