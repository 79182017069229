import React, { useRef, useState } from "react"

import { StyledButtonIcon } from "./Button.styled"

interface Props {
  onClick: () => void
  icon: React.ReactNode
  text?: React.ReactNode
  rotate?: boolean
  reverse?: boolean
  disabled?: boolean
  hoverText?: string
  hoverRight?: boolean
  hoverBorder?: boolean
  small?: boolean
  disableOnHoverColorChange?: boolean
}

const ButtonIcon = ({
  onClick,
  icon,
  text,
  rotate,
  reverse,
  disabled,
  hoverText,
  hoverRight,
  hoverBorder,
  small,
  disableOnHoverColorChange,
}: Props) => {
  const [showHover, setShowHover] = useState(false)
  const tooltip = useRef<HTMLDivElement>(null)

  return (
    <div
      onMouseEnter={() => {
        setShowHover(true)
      }}
      onMouseLeave={() => {
        setShowHover(false)
      }}
    >
      <StyledButtonIcon
        disabled={disabled}
        type="button"
        onClick={onClick}
        rotate={rotate ? 1 : 0}
        small={small}
        disableOnHoverColorChange={disableOnHoverColorChange}
      >
        {!reverse && icon} {text && text} {reverse && icon}
      </StyledButtonIcon>
      {hoverText && showHover && (
        <div
          style={{
            position: "relative",
          }}
        >
          <div
            ref={tooltip}
            style={{
              border: hoverBorder ? "2px solid black" : "1px solid white",
              position: "absolute",
              background: "white",
              color: "black",
              padding: "4px 5px",
              marginLeft: "-50%", // This centers the tooltip based on its width
              transform: hoverRight
                ? "translateX(10px) translateY(-50%)"
                : "translateX(-80%) translateY(-40%)", // Additional centering based on parent
              bottom: "100%", // Positioning it above the button
              marginBottom: "5px", // Little spacing from the button
              borderRadius: "8px",
              zIndex: 100,
              whiteSpace: "nowrap",
            }}
          >
            {hoverText.split("\n").map((text, i) => (
              <p key={`hover-text-${i}`}>{text}</p>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default ButtonIcon
