import { TableEntryType } from "components/types/types"
import React from "react"

import { StyledScrollRow } from "../Table.styled"
import { StyledRow } from "./Row.styled"

interface Props extends TableEntryType {
  children: React.ReactNode
}

const ScrollRow = ({ children }: Props) => {
  return (
    <StyledRow>
      <StyledScrollRow>{children}</StyledScrollRow>
    </StyledRow>
  )
}

export default ScrollRow
