import { Box } from "components/Shared/Box.styled"
import { Label } from "components/Shared/Label.styled"
import React, { useEffect, useState } from "react"
import { black } from "styles/colors"

import { StyledCheckboxField } from "./InputField.styled"

interface Props {
  label?: string
  width?: number
  justify?: string
  checked?: boolean | undefined
  disabled?: boolean
  innerRef?: React.MutableRefObject<HTMLInputElement | null>
  onClick?: () => void
  marginLeft?: number
  marginRight?: number
  size?: number
}
const CheckboxField = ({
  label,
  width,
  justify,
  checked,
  disabled,
  innerRef,
  onClick,
  marginLeft,
  marginRight,
  size,
}: Props) => {
  const [check, setCheck] = useState(checked)

  useEffect(() => {
    setCheck(checked)
  }, [])
  return (
    <Box direction="row" align="center" justify={justify} width={width}>
      <StyledCheckboxField
        type="checkbox"
        ref={innerRef}
        onChange={() => setCheck(!check)}
        disabledStyle={disabled}
        checked={check}
        onClick={() => onClick && onClick()}
      />
      {label && (
        <Label
          style={{
            textAlign: "left",
            marginLeft: marginLeft ? `${marginLeft}px` : "20px",
            marginRight: marginRight ? `${marginRight}px` : "3px",
            fontWeight: "400",
            color: disabled ? "gray" : black,
            fontSize: size,
          }}
        >{`${label}`}</Label>
      )}
    </Box>
  )
}

export default CheckboxField
