import { TableEntryType } from "components/types/types"
import { currentDecimals, currentToPower } from "helpers/helpers"
import React from "react"

import { StyledRow } from "./Row.styled"

interface Props extends TableEntryType {
  current: number
}

const CurrentPowerRow = ({ current }: Props) => (
  <StyledRow>
    <p>
      {currentDecimals(current)} A | {currentToPower(current)} kW
    </p>
  </StyledRow>
)

export default CurrentPowerRow
