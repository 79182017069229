import React, { useEffect, useRef, useState } from "react"
import { primary, white } from "styles/colors"
import { radius } from "styles/sizes"

interface Props {
  children: React.ReactNode
  text: string
}

const Tooltip = ({ children, text }: Props) => {
  const [show, setShow] = useState<boolean>(false)
  const [overflowActive, setOverflowActive] = useState<boolean>(false)
  const [position, setPosition] = useState<number>(0)
  const overflowingText = useRef<HTMLSpanElement | null>(null)

  const checkOverflow = (textContainer: HTMLSpanElement | null): boolean => {
    if (textContainer)
      return (
        textContainer.offsetHeight < textContainer.scrollHeight ||
        textContainer.offsetWidth < textContainer.scrollWidth
      )
    return false
  }

  const hoverCheck = () => {
    setPosition(overflowingText.current?.getBoundingClientRect().top || 0)
  }

  useEffect(() => {
    setOverflowActive(checkOverflow(overflowingText.current))
  }, [overflowActive])

  return (
    <>
      <span
        style={{
          position: "absolute",
          background: white,
          border: "1px solid " + primary,
          padding: "5px",
          borderRadius: radius,
          marginTop: "-35px",
          display: show ? "block" : "none",
          top: position,
        }}
      >
        {text}
      </span>
      <span
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
        onMouseLeave={() => setShow(false)}
        onMouseEnter={() => {
          if (checkOverflow(overflowingText.current)) {
            hoverCheck()
            setShow(true)
          }
        }}
        ref={overflowingText}
      >
        {children}
      </span>
    </>
  )
}

export default Tooltip
