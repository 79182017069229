import axios from "axios"
import Card from "components/Card/Card"
import PhysicalLimit from "components/Card/PhysicalLimit"
import { ChartThick } from "components/Icons/ChartThick"
import { Box } from "components/Shared/Box.styled"
import { Grid } from "components/Shared/Grid.styled"
import { PowerMetersType } from "components/types/types"
import { useAppContext } from "helpers/AppProvider"
import { currentDecimals, statusColorPowermeter } from "helpers/helpers"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { primary } from "styles/colors"
import { bold, semibold, xsmall } from "styles/fonts"

interface Props {
  id: string
  gwId: string
}

const PowermetersCard = ({ gwId, id }: Props) => {
  const [data, setData] = useState<PowerMetersType>()
  const [loading, setLoading] = useState(true)

  const { t } = useTranslation()
  const { token } = useAppContext()
  const powermeter = () => {
    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/powerMeters/${gwId}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setData(res.data)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }
  useEffect(() => {
    powermeter()
    const interval = setInterval(() => {
      powermeter()
    }, 5000)
    return () => clearInterval(interval)
  }, [id, token])

  return (
    <>
      {data?.lastValues && !loading && (
        <Card
          icon={<ChartThick />}
          title={t("Powermeter - " + data.description)}
          subTitle={id}
        >
          <Grid>
            <Box direction="column" margin="10px 0 0">
              <span
                style={{
                  fontWeight: bold,
                  fontSize: "30px",
                  color: statusColorPowermeter(data.connection),
                }}
              >
                {data.connection.toUpperCase()}
              </span>
              <span style={{ fontSize: xsmall, marginTop: "-8px" }}>
                {t("Status")}
              </span>
            </Box>
            <Box direction="column" margin="10px 0 0">
              <span
                style={{
                  color: primary,
                  fontSize: xsmall,
                  fontWeight: semibold,
                }}
              >
                {moment(data.lastUpdate).format("DD.MM.YYYY")}
              </span>
              <span
                style={{
                  color: primary,
                  fontSize: xsmall,
                  fontWeight: semibold,
                }}
              >
                {moment(data.lastUpdate).format("HH:mm:ss")}
              </span>
              <span style={{ fontSize: xsmall, marginTop: "-3px" }}>
                {t("Last Update")}
              </span>
            </Box>
          </Grid>
          <Box direction="column" margin="10px 0 0">
            <Grid>
              <Box direction="column">
                <span
                  style={{
                    fontWeight: bold,
                    fontSize: "30px",
                    color: primary,
                  }}
                >
                  {Number(currentDecimals(data.lastValues.frequency))}
                  <span style={{ fontSize: "20px" }}> Hz</span>
                </span>
                <span style={{ fontSize: xsmall, marginTop: "-8px" }}>
                  {t("Frequency")}
                </span>
              </Box>
              <Box direction="column">
                <span
                  style={{
                    fontWeight: bold,
                    fontSize: "30px",
                    color: primary,
                  }}
                >
                  {currentDecimals(
                    Number(currentDecimals(data.lastValues.realPowerL1)) +
                      Number(currentDecimals(data.lastValues.realPowerL2)) +
                      Number(currentDecimals(data.lastValues.realPowerL3))
                  )}
                  <span style={{ fontSize: "20px" }}> kW</span>
                </span>
                <span style={{ fontSize: xsmall, marginTop: "-8px" }}>
                  {t("Power Sum")}
                </span>
              </Box>
            </Grid>
            <Box
              direction="column"
              margin="20px 0 0 0 "
              style={{ fontWeight: bold }}
            >
              <PhysicalLimit
                l1={currentDecimals(data.lastValues.apparentCurrentL1)}
                l2={currentDecimals(data.lastValues.apparentCurrentL2)}
                l3={currentDecimals(data.lastValues.apparentCurrentL3)}
                text={t("Current")}
              />
              <PhysicalLimit
                l1={currentDecimals(data.lastValues.realPowerL1)}
                l2={currentDecimals(data.lastValues.realPowerL2)}
                l3={currentDecimals(data.lastValues.realPowerL3)}
                measure={"kW"}
                text={t("Power")}
              />
              <PhysicalLimit
                l1={currentDecimals(data.lastValues.voltageL1)}
                l2={currentDecimals(data.lastValues.voltageL2)}
                l3={currentDecimals(data.lastValues.voltageL3)}
                measure={"V"}
                text={t("Voltage")}
              />
            </Box>
          </Box>
        </Card>
      )}
    </>
  )
}

export default PowermetersCard
