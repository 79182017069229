import { ClusterTableFilter } from "components/types/types"
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
} from "react"

interface TreeContext {
  setChanged: Dispatch<SetStateAction<boolean | null>>
  openClusters: number[]
  setOpenClusters: Dispatch<SetStateAction<number[]>>
  clusterTableFilter: ClusterTableFilter[]
  setClusterTableFilter: Dispatch<SetStateAction<ClusterTableFilter[]>>
}
export const Context = createContext<TreeContext>({
  setChanged: () => true,
  openClusters: [],
  setOpenClusters: () => [],
  clusterTableFilter: [],
  setClusterTableFilter: () => [],
})

export const TreeProvider = ({
  setChanged,
  openClusters,
  setOpenClusters,
  clusterTableFilter,
  setClusterTableFilter,
  children,
}: PropsWithChildren<TreeContext>) => {
  const contextProps: TreeContext = {
    setChanged,
    openClusters,
    setOpenClusters,
    clusterTableFilter,
    setClusterTableFilter,
  }
  return <Context.Provider value={contextProps}>{children}</Context.Provider>
}

export const useTreeContext = () => useContext<TreeContext>(Context)
