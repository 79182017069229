import { FlexCol } from "components/Shared/FlexCol"
import styled from "styled-components"
import { secondary } from "styles/colors"

export const StyledContent = styled(FlexCol)`
  border: 1px solid ${secondary};

  width: 100%;
  text-align: left;
  border-top-left-radius: 5px;
  background: ${secondary};
  overflow: scroll;
`
