import { TableEntryType } from "components/types/types"
import React from "react"

import { StyledCpmsRow } from "./Row.styled"

interface Props extends TableEntryType {
  status: boolean
  text: string
}

const CPMSRow = ({ status, text, width }: Props) => (
  <StyledCpmsRow cpms={status}>
    <div></div>
    <p style={{ maxWidth: width - 20 + "px" }}>{text}</p>
  </StyledCpmsRow>
)

export default CPMSRow
