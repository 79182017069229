import { Status, TableEntryType } from "components/types/types"
import React from "react"

import { StyledStatusRow } from "./Row.styled"

interface Props extends TableEntryType {
  status: Status
  text: string
}

const StatusRow = ({ status, text }: Props) => {
  return (
    <StyledStatusRow status={status}>
      <div></div>
      <p>{text}</p>
    </StyledStatusRow>
  )
}

export default StatusRow
