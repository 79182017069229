import ButtonIcon from "components/Buttons/ButtonIcon"
import { Clear } from "components/Icons/Clear"
import { Search } from "components/Icons/Search"
import { Box } from "components/Shared/Box.styled"
import { FlexRow } from "components/Shared/FlexRow"
import { useTableContext } from "helpers/TableProvider"
import React, { useState } from "react"

import { StyledSearchField } from "./InputField.styled"

interface Props {
  innerRef?: React.MutableRefObject<HTMLInputElement | null>
}
const SearchField = ({ innerRef }: Props) => {
  const { searchString, search, searchValue } = useTableContext()
  const [inputValue, setInputValue] = useState(searchValue || "")

  const onChangeEvent = (value: string) => {
    searchString && searchString(value)
    setInputValue(value)
  }

  const onClear = () => {
    setInputValue("")
    searchString && searchString("")
    search && search("")
  }
  return (
    <Box direction="row" align="center">
      <StyledSearchField
        onChange={(event) => onChangeEvent(event.target.value)}
        value={inputValue}
        ref={innerRef}
        type="search"
      />
      <FlexRow>
        <ButtonIcon
          onClick={() => search && search(inputValue)}
          icon={<Search />}
        />
        {searchValue && (
          <ButtonIcon onClick={() => onClear()} icon={<Clear />} />
        )}
      </FlexRow>
    </Box>
  )
}

export default SearchField
