import React from "react"

export const Success = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6281 24 24 18.6281 24 12C24 5.37188 18.6281 0 12 0C5.37188 0 0 5.37188 0 12C0 18.6281 5.37188 24 12 24ZM12 6C12.6234 6 13.125 6.50156 13.125 7.125V12.375C13.125 12.9984 12.6234 13.5 12 13.5C11.3766 13.5 10.875 12.9984 10.875 12.375V7.125C10.875 6.50156 11.3766 6 12 6ZM13.5 16.5C13.5 17.3297 12.8297 18 12 18C11.1703 18 10.5 17.3297 10.5 16.5C10.5 15.6703 11.1703 15 12 15C12.8297 15 13.5 15.6703 13.5 16.5Z"
      fill="white"
    />
  </svg>
)
