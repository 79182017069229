import { FlexCol } from "components/Shared/FlexCol"
import { FlexRow } from "components/Shared/FlexRow"
import styled from "styled-components"
import { primary, shadow, white } from "styles/colors"
import { regular, xsmall } from "styles/fonts"
import { radius } from "styles/sizes"
export const StyledUserMenu = styled(FlexRow)`
  align-items: center;
  margin-right: 20px;

  &:hover {
    cursor: pointer;
  }

  &:hover p {
    color: ${primary};
  }

  &:hover svg path {
    fill: ${primary};
  }
`

export const UserCircle = styled.div`
  border-radius: 50%;
  background-color: ${primary};
  height: 32px;
  width: 32px;
  font-size: ${xsmall}};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${white}
`

export const UserName = styled.p`
  margin-left: 5px;
  margin-right: 5px;
  font-size: ${regular};
`
export const StyledDropDown = styled(FlexCol)`
  position: absolute;
  background: white;
  box-shadow ${shadow};
  right: 0;
  top: 70px;
  border-radius: ${radius};
  padding: 10px;
`
