import styled, { css } from "styled-components"
import { darkGray } from "styles/colors"
import { bold, regular } from "styles/fonts"
interface Props {
  blue?: boolean
  padding?: string
  margin?: string
}

export const Title = styled.label<Props>`
  font-size: ${regular};
  color: ${darkGray};
  font-weight: ${bold};
  margin-bottom: 20px;
  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `};

  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `};
`
