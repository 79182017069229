export const black = "#000000"

export const white = "#FFFFFF"

export const red = "#FF0707"

export const green = "#33B905"

export const primary = "#349CE7"

export const primaryDarker = "#177dc7"

export const secondary = "rgba(52, 156, 231, 0.07)"

export const secondaryLight = "rgba(52, 156, 231, 0.01)"

export const darkGray = "#11142D"

export const lightGray = "#f1f1f2"

export const disabled = "#92929D"

export const shadow = "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;"

export const inputShadow = "0px 4px 4px rgba(0, 0, 0, 0.25);"

export const available = "rgba(62, 215, 8, 0.15)"

export const preparing = "rgba(243, 145, 0, 0.25)"

export const charging = "rgba(255, 204, 0, 0.35)"

export const finishing = "rgba(62, 215, 8, 0.05)"

export const suspendedEV = "rgba(85, 190, 240, 0.15)"

export const suspendedEVSE = "rgba(85, 190, 240, 0.25)"

export const faulted = "rgba(227, 14, 0, 0.15)"

export const faultedFull = "rgba(227, 14, 0, 0.5)"

export const unavailable = "rgba(227, 14, 0, 0.05)"

export const error = "rgba(255, 104, 104, 0.50)"

export const failure = "rgba(255, 7, 7, 0.81)"

export const disconnected = "#fff3f2"

export const success = "#3ED708"

export const warning = "#eed202"

export const orange = "#db9881"

export const pending = "rgba(85, 190, 240, 1)"
