/* eslint-disable indent */
import { TableLayout } from "components/types/types"

export const ChargepointTable: (showAcStatus?: boolean) => TableLayout[] = (
  showAcStatus
) => [
  {
    title: "Chargepoint",
    data: "id",
    width: 200,
    type: "status",
  },
  {
    title: "Connector",
    data: "connector",
    width: 100,
    type: "",
  },
  {
    title: "Description",
    data: "config.descriptions",
    width: 100,
    type: "",
  },
  {
    title: "State",
    data: "cpState",
    width: 150,
    type: "state",
  },
  ...(showAcStatus
    ? [
        {
          title: "AC",
          data: "",
          width: 40,
          type: "adaptiveChargingStatus",
        },
      ]
    : []),
  {
    title: "Limit ( L1 | L2 | L3 | Total )",
    data: "",
    width: 200,
    type: "tripleLimit",
    measure: "A",
  },
  {
    title: "Allocated ( L1 | L2 | L3 | Total )",
    data: "",
    width: 200,
    type: "tripleAllocated",
    measure: "A",
  },
  {
    title: "Actual ( L1 | L2 | L3 | Total )",
    data: "actual",
    width: 200,
    type: "tripleActual",
    measure: "A",
  },
  {
    title: "Last Update",
    data: "lastUpdate",
    width: 100,
    type: "dateTime",
  },
]

export const GatewayTable: TableLayout[] = [
  {
    title: "Name",
    data: "id",
    width: 200,
    type: "status",
  },
  {
    title: "Description",
    data: "description",
    width: 100,
    type: "",
  },
  // {
  //   title: "State",
  //   data: "cpState",
  //   width: 150,
  //   type: "state",
  //   filtering: true,
  //   options: ["Available", "Error", "Charging", "Finishing"],
  // },
  {
    title: "Physical Limit",
    data: "",
    width: 100,
    type: "scroll",
  },
  {
    title: "Phase rotation",
    data: "config.phaseRotation",
    width: 100,
    type: "",
    options: [],
  },
  {
    title: "CPMS",
    data: "",
    width: 100,
    type: "CPMS",
    filtering: true,
    options: [],
  },
  {
    title: "Last Update",
    data: "lastUpdate",
    width: 100,
    type: "dateTime",
  },
]

export const TreeTable: TableLayout[] = [
  {
    title: "Chargepoint",
    data: "id",
    width: 150,
    type: "status",
  },
  {
    title: "Description",
    data: "config.descriptions",
    width: 100,
    type: "",
  },
  {
    title: "Limit ( L1 | L2 | L3 | Total )",
    data: "",
    width: 200,
    type: "tripleLimit",
    measure: "A",
  },
  {
    title: "Allocated ( L1 | L2 | L3 | Total )",
    data: "",
    width: 200,
    type: "tripleAllocated",
    measure: "A",
  },
  {
    title: "Priority",
    data: "priority",
    width: 200,
    type: "priority",
  },
]
export const AddChargingPopup: TableLayout[] = [
  {
    title: "Chargepoint",
    data: "id",
    width: 150,
    type: "status",
  },
  {
    title: "Description",
    data: "description",
    width: 200,
    type: "",
  },
  {
    title: "Physical Limit (kW)",
    data: "",
    width: 100,
    type: "scroll",
  },
]

export const AddPowermeterPopup: TableLayout[] = [
  {
    title: "Powermeter",
    data: "id",
    width: 150,
    type: "status",
  },
  {
    title: "IP Address",
    data: "localIp",
    width: 100,
    type: "",
  },
]

export const PowermeterCardView: TableLayout[] = [
  {
    title: "Powermeter",
    data: "id",
    width: 200,
    type: "status",
  },
  {
    title: "Description",
    data: "description",
    width: 150,
    type: "",
  },
  {
    title: "Voltage ( L1 | L2 | L3 )",
    data: "",
    width: 200,
    type: "tripleVoltage",
    measure: "V",
  },
  {
    title: "Current ( L1 | L2 | L3 )",
    data: "",
    width: 200,
    type: "tripleCurrent",
    measure: "A",
  },
  {
    title: "Power ( L1 | L2 | L3 | Total )",
    data: "",
    width: 200,
    type: "triplePower",
    measure: "kW",
  },
  {
    title: "Frequency",
    data: "lastValues.frequency",
    width: 100,
    type: "",
    measure: "Hz",
  },
  {
    title: "Energy Consumed",
    data: "lastValues.realEnergyConsumedSum",
    width: 150,
    type: "",
    measure: "kWh",
  },
  {
    title: "Energy Delivered",
    data: "lastValues.realEnergyConsumedSum",
    width: 150,
    type: "",
    measure: "kWh",
  },
  {
    title: "Last Update",
    data: "lastUpdate",
    width: 100,
    type: "dateTime",
  },
]

export const PowerMeterTable: TableLayout[] = [
  {
    title: "Model",
    data: "modelName",
    width: 100,
    type: "status",
  },
  {
    title: "ID",
    data: "id",
    width: 200,
    type: "",
  },
  {
    title: "Description",
    data: "description",
    width: 200,
    type: "",
  },
  {
    title: "IP Address",
    data: "localIp",
    width: 150,
    type: "",
  },
  {
    title: "Port",
    data: "port",
    width: 100,
    type: "",
  },
]
export const PowerMeterTableTree: TableLayout[] = [
  {
    title: "Model",
    data: "modelName",
    width: 100,
    type: "status",
  },
  {
    title: "ID",
    data: "id",
    width: 200,
    type: "",
  },
  {
    title: "State",
    data: "cpState",
    width: 200,
    type: "",
  },
  {
    title: "Description",
    data: "description",
    width: 200,
    type: "",
  },
  {
    title: "IP Address",
    data: "localIp",
    width: 150,
    type: "",
  },
  {
    title: "Port",
    data: "port",
    width: 100,
    type: "",
  },
]
