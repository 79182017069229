import moment from "moment"

const DISCONNECT_DURATION_BEFORE_VISIBLE_SECONDS = 300

export const updateConnectionStatusBasedOnDuration = (cc: any) => {
  const isArray = typeof cc.connection === "object"
  const connectionStatus = isArray ? cc.connection[0] : cc.connection
  let newConnectionStatus: "connected" | "disconnected"
  if (connectionStatus === "connected") newConnectionStatus = "connected"
  else {
    const timestampMoment = moment(cc.lastUpdate)
    const currentMoment = moment()
    const differenceInSeconds = currentMoment.diff(timestampMoment, "seconds")
    if (differenceInSeconds < DISCONNECT_DURATION_BEFORE_VISIBLE_SECONDS)
      newConnectionStatus = "connected"
    else newConnectionStatus = "disconnected"
  }
  return {
    ...cc,
    connection: isArray
      ? cc.connection.map((_: string) => newConnectionStatus)
      : newConnectionStatus,
  }
}
