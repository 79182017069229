import styled from "styled-components"

export const StyledMenu = styled.nav`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center;
  height: 7vh;
  svg:first-child {
    margin-left: 30px;
  }
`
