import Loading from "components/Icons/Loading.gif"
import React from "react"

import { StyledLoader } from "./Loader.styled"

const Loader = () => (
  <StyledLoader>
    <img src={Loading} alt="" />
  </StyledLoader>
)

export default Loader
